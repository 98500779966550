import ApplicationController from '../application_controller';

class DialogComponent extends ApplicationController {
  static targets = ['switch', 'body'];

  connect() {
    this.element.dialogComponent = this;
  }

  dialog() {
    return this.bodyTarget.dialogBody
  }

  setDialogOptions(data) {
    this.dialog().values = {
      content: data.optionsContent,
      background: data.optionsBackground ? `url(${data.optionsBackground})` : 'none',
    }
  }

  triggerDialog(event) {
    event.preventDefault();
    const data = event.currentTarget.dataset;
    this.setDialogOptions(data);
    this.dialog().open();
  }

  closeDialog() {
    this.dialog().close();
  }
}

export default DialogComponent;
