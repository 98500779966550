import ApplicationController from '../application_controller';

class DialogContainer extends ApplicationController {
  static targets = ['content', 'contentContainer', 'exitElement']

  static values = { content: String, background: String };

  connect() {
    this.element.dialogBody = this;
  }

  open() {
    this.buildDialog();
    this.element.classList.remove('inactive');
    document.body.style.overflow = 'hidden';
  }

  close() {
    this.element.classList.add('inactive');
    document.body.style.overflow = 'unset';
    this.content.classList.add('hidden');
  }

  buildDialog() {
    this.setBackground();
    this.setContent();
    this.buildExitElArray();
  }

  setBackground() {
    if (this.values) this.contentContainerTarget.style.backgroundImage = this.values.background;
  }

  contentSelector() {
    if (this.hasContentValue) { return this.contentValue }

    return this.values.content
  }

  setContent() {
    this.content = document.querySelector(`${this.contentSelector()}`);
    this.contentTarget.appendChild(this.content);
    this.content.classList.remove('hidden')
  }

  buildExitElArray() {
    const exitElements = this.content.querySelectorAll('.jsDialogClose');

    exitElements.forEach((el) => {
      const element = el;
      element.dataset.action = 'click->containers--dialog-container#close'
    });
  }
}

export default DialogContainer;
