import client from 'braintree-web/client';
import ApplePay from 'core/services/checkout/apple_pay';
import ApplePayBuyNow from 'core/services/checkout/apple_pay_buy_now';
import ApplicationController from '../application_controller';

/* Note:
 * We should also be importing data-collector from braintree-web
 * to collect device data for fraud prevention.
 * We use data-collector in other parts of the app, but not here.
*/

class Payment extends ApplicationController {
  static values = { initialize: Boolean }

  static targets = ['applePay', 'applePayBuyNow']

  get braintreeClient() {
    const value = this.getBraintree();
    Object.defineProperty(this, 'braintreeClient', { value });
    return value;
  }

  getBraintree() {
    this.cartSettings = TeePublic.Cart.Settings;

    return client.create({
      authorization: this.cartSettings.btKey,
    });
  }

  connect() {
    this.initCheckout();
  }

  initCheckout() {
    if (this.applePayBuyNowTargets.length > 0) {
      this.initApplePayBuyNow();
    }

    if (this.applePayTargets.length > 0 && this.initializeValue) {
      this.initApplePay();
    }
  }

  initApplePay() {
    if (window.ApplePaySession) {
      const applePay = new ApplePay({ braintreeClient: this.braintreeClient, targets: this.applePayTargets });
      applePay.init();
    }
  }

  initApplePayBuyNow() {
    if (window.ApplePaySession) {
      const applePayBuyNow = new ApplePayBuyNow({ braintreeClient: this.braintreeClient, targets: this.applePayBuyNowTargets });
      applePayBuyNow.init();
    }
  }
}

export default Payment;
