class PromotedFilter {
  constructor({
    promotedFilter,
    active,
    filterTerm,
  }) {
    this.promotedFilterButton = promotedFilter;
    this.active = active;
    this.filterTerm = filterTerm;
  }

  init() {
    this.promotedFilterButton.addEventListener('click', this.submitPromotedFilter.bind(this));
  }

  submitPromotedFilter() {
    const searchForm = document.getElementById('jsPromotedFilters');
    const promotedFilterField = searchForm.querySelector('.jsPromotedFiltersField');
    // If user clicks on selected PF, set the value to null to deselect
    const hiddenFieldValue = this.active === 'true' ? null : this.filterTerm;

    promotedFilterField.value = hiddenFieldValue;
    searchForm.submit();
  }
}

export default PromotedFilter;
