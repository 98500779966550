import Legacy from 'shared/legacy_js'

import Designs from './designs'
import initializeInfo from './initialize_info'
import PhotoGallery from './photo_gallery'
import Sizer from './sizer'
import Utils from './utils'

const ProductPage = {
  Designs,
  initializeInfo,
  Legacy,
  PhotoGallery,
  Sizer,
  Utils,
};

export default ProductPage;
