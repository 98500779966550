/*
 * ProductHelper
 * =============
 * This helper is used in many places to interface with product data.
 * Before exporting new methods, consider if an initializer could be used
 * to encapsulate the logic that calls the methods within here instead.
 */


import Initializers from './initializers';
import Renderers from './renderers';
import Updaters from './updaters';
import Utilities from './utilities';

const ProductHelper = {};

// TODO: Move this method because there's no need to go through ProductHelper to init a campaign.
ProductHelper.initCampaign = Initializers.initCampaign;

// Initializers
ProductHelper.initExchangePage = Initializers.initExchangePage;
ProductHelper.initPage = Initializers.initPage;

// Renderers
ProductHelper.renderProductOptions = Renderers.renderProductOptions;

// Updaters
ProductHelper.updateColorText = Updaters.updateColorText;
ProductHelper.updateGallery = Updaters.updateGallery;

// Utilities
ProductHelper.collectSelections = Utilities.collectSelections;
ProductHelper.findProductFromSelections = Utilities.findProductFromSelections;
ProductHelper.findProductById = Utilities.findProductById;
ProductHelper.optionsToRender = Utilities.optionsToRender;
ProductHelper.productPrices = Utilities.productPrices;
ProductHelper.sanitizeProducts = Utilities.sanitizeProducts;
ProductHelper.validateSelections = Utilities.validateSelections;

export default ProductHelper;
