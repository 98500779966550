import Fetchers from 'core/fetchers';
import ApplicationController from './application_controller'

export default class FavoriteController extends ApplicationController {
  static values = {
    savedListId: String,
    addIconPath: String,
    removeIconPath: String,
  }

  openSignUpModal(event) {
    event.preventDefault();
    const { designId, productId } = event.currentTarget.dataset;
    const createAccountMessage = document.querySelector('.jsFavoriteModalMessage');
    createAccountMessage.style.display = 'flex';

    $('#login-modal').modal('open');
    TeePublic.LoginModal.init($('#account-creation'));

    this.updateFavoriteHiddenFields(designId, productId);
  }

  handleTouch(event) {
    event.preventDefault();
    this.openSignUpModal(event)
  }

  updateFavoriteHiddenFields(designId, productId) {
    const loginModal = document.querySelector('#login-modal');
    const designIdHiddenFields = loginModal.querySelectorAll('#favorite_design_id');
    const productIdHiddenFields = loginModal.querySelectorAll('#favorite_product_id');

    designIdHiddenFields.forEach((field) => field.value = designId);
    productIdHiddenFields.forEach((field) => field.value = productId);
  }

  favoriteButtonURL(action) {
    return `/account/${action}_favorite_design`;
  }

  toggleFavorite(event) {
    const currentTarget = event.currentTarget;
    const url = this.favoriteButtonURL(currentTarget.dataset.buttonAction);
    const body = JSON.stringify({
      design_id: currentTarget.dataset.designId,
      product_id: currentTarget.dataset.productId,
      saved_list_id: this.savedListIdValue,
    });

    Fetchers.fetchJSON(url, 'POST', body)
      .then(res => res.json())
      .then((data) => {
        this.updateFavoriteButton(currentTarget, data)
        this.displaySuccessMessage(data.message);
      })
      .catch(error => console.log(error));
  }

  updateFavoriteButton(target, data) {
    const imgTag = target.querySelector('img');
    let newFilterOptionLabel,
        newModifierClass;

    if (data.new_action === 'add') {
      newFilterOptionLabel = 'add_to_favorites';
      newModifierClass = 'tp-favorite-button--unfilled';
      imgTag.src = this.addIconPathValue;
    } else if (data.new_action === 'remove') {
      newFilterOptionLabel = 'remove_from_favorites';
      newModifierClass = 'tp-favorite-button--filled';
      imgTag.src = this.removeIconPathValue;
    }

    target.classList.forEach(className => {
      // remove modifier class
      if (className.includes('tp-favorite-button--')) target.classList.remove(className);
    });

    target.dataset.buttonAction = data.new_action;
    target.dataset.filterOptionLabel = newFilterOptionLabel;
    target.classList.add(newModifierClass);

    target.blur();
  }

  displaySuccessMessage(message) {
    const pastMessage = document.querySelector('.jsFavoriteMessage');
    const node = new DOMParser().parseFromString(message, "text/html").body.firstElementChild;

    if (pastMessage) pastMessage.remove();

    document.querySelector('body').appendChild(node);

    setTimeout(() => {
      node.remove();
    }, 4000);
  }
}
