import ApplicationController from '../application_controller';

export default class RevealController extends ApplicationController {
  static targets = ["content"];
  static classes = ["hidden"];

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : "hidden"
  }

  toggle() {
    this.contentTargets.forEach(content => {
      content.classList.toggle(this.class)
    })
  }

  show() {
    this.contentTargets.forEach(content => {
      content.classList.remove(this.class)
    })
  }

  hide() {
    this.contentTargets.forEach(content => {
      content.classList.add(this.class)
    })
  }
}
