import ApplicationController from '../application_controller';

class RadioGroup extends ApplicationController {
  static outlets = ['inputs--radio']

  onChange() {
    this.inputsRadioOutlets.forEach(outlet => outlet.update());
  }
}

export default RadioGroup;
