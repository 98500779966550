import Glide from '@glidejs/glide';

const LandingSlider = {
  init(type) {
    const config = {
      type,
      autoplay: 5000,
      dragThreshold: 1,
      swipeThreshold: 1,
      animationTimingFunc: 'cubic-bezier(0.42,0,0.58,1)',
    }

    const sliderElements = document.querySelectorAll('.jsLandingSlider');
    sliderElements.forEach((element) => {
      this.initSlider(element, config);
    });
  },

  initSlider(element, config) {
    const slides = element.querySelectorAll('[data-glide-el="track"] .glide__slide');
    if (slides.length <= 1) return;

    const slider = new Glide(element, config);
    slider.on('mount.after', this.initControls(slider).bind(this));
    slider.on('move.after', this.updateControls(slider).bind(this));
    slider.mount();
  },

  handleControlClick(slider, index) {
    return (event) => {
      event.preventDefault();
      slider.go(`=${index}`);
    }
  },

  initControls(slider) {
    return () => {
      const sliderControlElements = document.querySelectorAll('.jsLandingSliderCtrls a');
      sliderControlElements.forEach((element, index) => {
        element.addEventListener('click', this.handleControlClick.bind(this)(slider, index));
      });
    }
  },

  updateControls(slider) {
    return () => {
      const index = slider.index;
      const sliderControlImages = document.querySelectorAll('.jsLandingSliderCtrls img');
      sliderControlImages.forEach((element, i) => {
        if (i === index) element.classList.add('on');
        else element.classList.remove('on');
      });
    }
  },
}

export default LandingSlider;
