import { DataLayer } from 'core/globals'; // This is a circular import and should be rethought

class SafeSearch {
  static configureEventTracking(safeSearchForm) {
    const {
      'gtmToggleTo': toggleTo,
      'gtmSearchTerm': searchTerm,
    } = safeSearchForm.dataset;

    safeSearchForm.addEventListener('click', () => {
      const gtmEvent = {
        event: 'safeSearchToggle',
        'safeSearchToggle': {
          toggleTo,
          searchTerm,
        },
      };

      DataLayer.push(gtmEvent);
    });
  }

  constructor({ safeSearchClass = '.gtm-safe-search' } = {}) {
    const safeSearchElements = document.querySelector(safeSearchClass);
    if (safeSearchElements) {
      SafeSearch.configureEventTracking(safeSearchElements);
    }
  }
}

export default SafeSearch;
