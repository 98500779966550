import ApplicationController from '../application_controller';

class Select extends ApplicationController {
  static targets = ['additionalInfo']

  static values = { requireInfo: Array }

  connect() {
    this.element.selectInput = this;
  }

  onSelect(event) {
    if (!this.hasAdditionalInfoTarget) { return }
    this.hideAdditionalInfo();
    if (this.requiresAdditionalInfo(event)) { this.showAdditionalInfo() }
  }

  showAdditionalInfo() {
    this.additionalInfoTarget.required = true

    this.additionalInfoTarget.closest('.form__field').classList.remove('hidden')
    this.additionalInfoTarget.classList.add('jsRequireValidation')
  }

  hideAdditionalInfo() {
    this.additionalInfoTarget.required = false

    this.additionalInfoTarget.closest('.form__field').classList.add('hidden')
    this.additionalInfoTarget.classList.remove('jsRequireValidation')
  }

  requiresAdditionalInfo(event) {
    const { value } = event.currentTarget.querySelector('select');
    return this.requireInfoValue.includes(value);
  }
}

export default Select;
