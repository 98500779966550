import ApplicationController from '../application_controller';

export default class FileFieldController extends ApplicationController {
  static targets = ["removeButton"];

  removeFile(event) {
    event.preventDefault();

    const url = event.currentTarget.dataset.fileRemoveUrl;

    if (!url) {
      console.error("No file removal URL provided.");
      return;
    }

    if (confirm("Are you sure you want to remove this file?")) {
      fetch(url, {
        method: "DELETE",
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            location.reload();
          } else {
            console.error("Failed to remove the file.");
          }
        })
        .catch((error) => {
          console.error("Error removing file:", error);
        });
    }
  }
}
