const FormsNew = {
  selectMock() {
    document.addEventListener('click', (event) => {
      if (!event.target.closest('.jsSelectMock')) {
        document.querySelectorAll('.jsSelectMock').forEach((menu) => {
          menu.classList.remove('active');
        })
      }
    })

    document.querySelectorAll('.jsSelectMockToggle').forEach((button) => {
      button.addEventListener('click', (e) => {
        var menu = e.target.closest('.jsSelectMock');
        if (!menu) { return; }

        if (menu.classList.contains('active')) {
          menu.classList.remove('active');
        } else {
          menu.classList.add('active');
        }
      })
    })
  },
}
export default FormsNew;
