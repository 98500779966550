function appendScript(src) {
  const promise = new Promise((resolve, reject) => {
    const script = document.createElement('script');
    document.body.appendChild(script);

    script.onload = resolve;
    script.onerror = reject;
    script.async = true;
    script.src = (src);
  })

  return promise;
}

export default appendScript;
