import { EMAIL_VALIDATION_REGEX } from 'shared';
import EmailSubscribeForm from './email_subscribe_form';

class EmailHelper {
  constructor() {
    this.subscribeForms = document.querySelectorAll('.jsSubscribe');
    this.emailRegex = EMAIL_VALIDATION_REGEX;
  }

  initSubscribeForms(skipHtmlValidation) {
    this.subscribeForms.forEach((form) => {
      const newEmailSubscribeForm = new EmailSubscribeForm(form);

      if (skipHtmlValidation) {
        this.initSubscribeValidation(newEmailSubscribeForm);
      } else {
        newEmailSubscribeForm.enhanceHtmlValidation();
      }
    });
  }

  initSubscribeValidation(subscribeForm) {
    const button = subscribeForm.form.querySelector('input[type="submit"]');
    button.addEventListener('click', (e) => {
      this.validateEmail(e, subscribeForm);
    });
  }

  validateEmail(e, subscribeForm) {
    const email = subscribeForm.emailField.value;

    if (this.isValidEmail(email)) {
      subscribeForm.hideErrors();
    } else {
      subscribeForm.showError(true, 'Email is invalid.');
      e.preventDefault();
      return false;
    }
    return true;
  }

  isValidEmail(email) {
    return this.emailRegex.test(email);
  }
}

function initEmailSubscribes(skipHtmlValidation = false) {
  const newEmailHelper = new EmailHelper();
  newEmailHelper.initSubscribeForms(skipHtmlValidation);
}

function isValidEMail(email) {
  const newEmailHelper = new EmailHelper();
  newEmailHelper.isValidEmail(email);
}

const EmailHelpers = { initEmailSubscribes, isValidEMail };

export default EmailHelpers;
