const EMAIL_VALIDATION_REGEX = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

const EVENT_TYPES = Object.freeze({
  MOUSEDOWN: 'mousedown',
  SUBMIT: 'submit',
});

const KEYS = Object.freeze({
  ENTER: 'Enter',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
});


export { EMAIL_VALIDATION_REGEX, EVENT_TYPES, KEYS };
