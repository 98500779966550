/* eslint-disable class-methods-use-this */

class ExploreNav {
  constructor(navElement) {
    this.navElement = navElement;
  }

  desktopView() {
    return window.innerWidth >= 1280;
  }

  toggleDisplayTargets() {
    const targets = this.navElement.querySelectorAll('[data-utilities--tab-target="content"]');

    if (this.desktopView()) {
      targets.forEach((target) => {
        target.setAttribute("style", "display:block;");
      });
    } else {
      const tabs = this.navElement.querySelectorAll('[data-utilities--tab-target="tab"]');
      const activeTab = this.navElement.querySelector('.m-tab-nav__item--active');
      const activeTabIndex = parseInt(activeTab.firstElementChild.dataset.tabNavIndex);

      tabs.forEach((tab, i) => {
        tab.parentElement.classList.toggle('m-tab-nav__item--active', i === activeTabIndex);
        if (i === activeTabIndex) {
          targets[i].removeAttribute('style');
        } else {
          targets[i].setAttribute("style", "display:none;");
        }
      });
    }
  }

  handleResize() {
    window.addEventListener('resize', () => {
      this.toggleDisplayTargets();
    });
  }

  init() {
    this.toggleDisplayTargets();
    this.handleResize();
  }
}

function initExploreNav() {
  document.addEventListener('DOMContentLoaded', () => {
    const navElement = document.querySelector('.jsExploreNav');
    if (!navElement) return;
    const exploreNav = new ExploreNav(navElement);
    exploreNav.init();
  });
}

export default initExploreNav;
