function secretDesignId() {
  document.addEventListener('keydown', (keydown) => {
    const delayEvent = 3000;
    const designIdObjClassList = document.querySelector('.jsDesignSecretId').classList;
    let showIdEvent;

    if (keydown.key === 'Escape' && designIdObjClassList.contains('hidden')) {
      showIdEvent = setTimeout(() => { designIdObjClassList.remove('hidden') }, delayEvent);

      document.addEventListener('keyup', () => {
        clearTimeout(showIdEvent);
        designIdObjClassList.add('hidden');
      });
    }
  });
}

export default secretDesignId;
