import Fetchers from 'core/fetchers';
import ApplePay from './apple_pay';

class ApplePayBuyNow extends ApplePay {
  constructor({
    braintreeClient,
    targets,
  }) {
    super(braintreeClient, targets);
    this.braintreeClient = braintreeClient;
    this.targets = targets;

    this.routes = {
      cart: '/cart/applepay-buy-now',
      checkout: '/checkout/applepay-buy-now',
    }

    this.initialized = false;
    this.target = this.targets[0];
    this.designId = this.target.dataset.designid;
    this.storeId = this.target.dataset.storeid;
    this.campaignId = TeePublic.campaign.id;
    this.productOptions = TeePublic.ProductOptions;
    this.hierarchy = TeePublic.ProductOptions.CanvasOptions.hierarchy;
    this.sanitizedProducts = TeePublic.ProductHelper.sanitizeProducts(TeePublic.ProductOptions);
    this.formOptions = document.querySelector('form.jsConfigOptions');
  }

  validateSelections(failSilently = false) {
    const valid = TeePublic.ProductHelper.validateSelections('form.jsConfigOptions', this.hierarchy, failSilently);

    return valid;
  }

  listenForSizeSelection() {
    this.formOptions.addEventListener('change', (e) => {
      if (this.validateSelections(true)) {
        this.setUpApplePayBuyNow()
      }
    })
  }

  setUpClickEvents() {
    this.targets.forEach((ele) => {
      if (ele.classList.contains('jsApplePayCheckout')) {
        ele.addEventListener('click', (event) => {
          event.preventDefault();

          if (this.validateSelections() && this.initialized) {
            this.initApplePaySession(this.productBody());
          }
        })
      }
    })
  }

  setUpApplePayBuyNow() {
    Fetchers.fetchJSON(this.routes.cart, 'POST', JSON.stringify(this.productBody()))
      .then(Fetchers.parseResponse)
      .then((res) => {
        const { data } = res
        const requestData = {
          total: {
            label: 'TeePublic.com',
            amount: data.total,
          },
          lineItems: data.line_items,
          shippingMethods: data.shipping_methods,
          'requiredShippingContactFields': [
            'postalAddress', 'name', 'phone', 'email',
          ],
          currencyCode: data.currencyCode,
        }

        this.paymentRequest = this.applePayInstance.createPaymentRequest(requestData);
        this.initialized = true;
      });
  }

  fetchPaymentData() {
    const promise = window.ApplePaySession.canMakePaymentsWithActiveCard(this.applePayInstance.merchantIdentifier);
    promise.then((canMakePaymentsWithActiveCard) => {
      this.canApplePay = canMakePaymentsWithActiveCard
      if (!this.canApplePay) { return }

      this.showApplePay();

      if (this.validateSelections(true)) {
        this.setUpApplePayBuyNow();
      }

      this.listenForSizeSelection();
      this.setUpClickEvents();
    })
  }

  productBody() {
    const selections = TeePublic.ProductHelper.collectSelections('form.jsConfigOptions', this.productOptions, false);
    const product = TeePublic.ProductHelper.findProductFromSelections(this.sanitizedProducts, selections, this.productOptions);

    return {
      product_id: product.id,
      design_id: this.designId,
      quantity: (document.querySelector('#jsProductPageQuantity')?.value || 1),
      store_id: this.storeId,
      campaign_id: this.campaignId,
    }
  }
}

export default ApplePayBuyNow;
