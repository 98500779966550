class FadedEdges {
  constructor(scrollableEl, scrollableWrapper) {
    this.scrollableEl = scrollableEl;
    this.scrollableWrapper = scrollableWrapper;
  }

  init() {
    ['load', 'resize'].forEach((event) => {
      window.addEventListener(event, () => { this.toggle(); });
    });
    this.scrollableEl.addEventListener('scroll', () => { this.toggle(); });
  }

  toggle() {
    if (this.scrollableEl.scrollLeft === 0) {
      this.scrollableWrapper.style.setProperty('--before-display', 'none');
    } else {
      this.scrollableWrapper.style.setProperty('--before-display', 'block');
    }

    if (this.scrollableEl.scrollLeft + this.scrollableWrapper.offsetWidth === this.scrollableEl.scrollWidth) {
      this.scrollableWrapper.style.setProperty('--after-display', 'none');
    } else {
      this.scrollableWrapper.style.setProperty('--after-display', 'block');
    }
  }
}

export default FadedEdges;
