class CreateCampaigns {
  createCampaign() {
    if (document.querySelector('.campaign_group tr:not(.hidden)') === null) { return; }
    this.recalculateShading();
    this.calculateEstimatedRevenue();
    this.addEventListeners();
  }

  recalculateShading() {
    document.querySelectorAll('.campaign_group tr:not(.hidden)').forEach( (row, index) => {
      if (index % 2 === 0) { // toggle background color for even & odd rows
        row.style.backgroundColor ='#dcdcdc';
      } else {
        row.style.backgroundColor ='#ededed';
      }
    });
  }

  calculateEstimatedRevenue() {
    var quantity = parseFloat(document.getElementById('campaign_goal').value);

    if (quantity > 0) {
      var sum = 0;
      var selects = document.querySelectorAll(`.campaign_group 
      table:not([style*="display:none"]):not([style*="display: none"])
      tr:not(.hidden) select`);
      selects.forEach(function(row) {
        sum = sum + parseFloat(row.options[row.selectedIndex].value);
      });
      var estimate = (sum/selects.length)*quantity;
      var jsEstimateAmount = document.querySelector('.jsEstimateAmount');
      jsEstimateAmount.textContent = `$${+estimate.toFixed(2)}`;
      jsEstimateAmount.style.display = 'block';
      document.querySelector('.jsEstimateContainer').style.display = 'block';
    } else {
      document.querySelector('.jsEstimateContainer').style.display = 'none';
    }
  }

  addEventListeners() {
    this.campaignTypeCheck();
    this.commissionCalculator();
    this.expandDescriptionBox();
    this.selectCanvases();
  }

  campaignTypeCheck() {
    document.querySelector('.jsAlwaysDtgPrint').addEventListener('change', (checkbox) => {
      if (checkbox.target.checked) {
        this.makeAlwaysDtg();
      } else {
        this.makeScreenPrint();
      }
      this.calculateEstimatedRevenue();
    });

    document.querySelector('.jsAlwaysDtgPrint').dispatchEvent(new Event('change'));
  }

  makeAlwaysDtg() {
    document.querySelector('.jsScreenPrintTable').style.display = 'none';
    document.querySelector('.jsDtgTable').style.display = 'block';
    document.querySelector('.jsScreePrintProducts').style.display = 'none';
    document.querySelector('.jsDtgProducts').style.display = 'block';
  }

  makeScreenPrint() {
    document.querySelector('.jsDtgTable').style.display = 'none';
    document.querySelector('.jsScreenPrintTable').style.display = 'block';
    document.querySelector('.jsDtgProducts').style.display = 'none';
    document.querySelector('.jsScreePrintProducts').style.display = 'block';
  }

  commissionCalculator() {
    document.querySelectorAll('.campaign_group tr').forEach( (row) => {
      row.addEventListener('change', () => {
        var commission = row.querySelector('select').value;
        row.querySelector('.profit').textContent = `$${parseFloat(commission).toFixed(2)}`;
        this.calculateEstimatedRevenue();
      })
    });
  }

  expandDescriptionBox() {
    document.querySelector('#campaign_description').addEventListener('keyup', (box) => {
      var descBoxStyle = getComputedStyle(box.target); // prevent multiple computations in the while loop
      box.target.style.height = `${box.target.offsetHeight}px`;
      while(box.target.offsetHeight < box.target.scrollHeight + parseInt(descBoxStyle.borderTopWidth) + parseInt(descBoxStyle.borderBottomWidth)) {
        box.target.style.height = `${box.target.offsetHeight + 1}px`;
      };
    });
  }

  selectCanvases() {
    document.querySelectorAll('.campaign_canvases input').forEach( (checkboxes) => {
      checkboxes.addEventListener('change', (checkbox) => {
        var row = document.querySelector(`.row_${checkbox.target.getAttribute('data-id')}`);
        if(checkbox.target.checked) {
          row.classList.remove('hidden');
        } else {
          row.classList.add('hidden');
        }
        this.recalculateShading();
        this.calculateEstimatedRevenue();
      })
    });
  }
}

export default CreateCampaigns;
