class InputChangeMonitor {
  constructor(
    fieldSelector,
    warningSelector = null,
  ) {
    this.field = document.querySelector(`.${fieldSelector}`);
    this.field.setAttribute('originalValue', this.field.value);

    if (warningSelector) {
      this.warningEl = document.querySelector(`.${warningSelector}`);
    }
  }

  init() {
    if (this.warningEl) {
      this.field.addEventListener('change', () => this.toggleWarningMessage());
      this.field.addEventListener('keyup', () => this.toggleWarningMessage());
    }
  }

  toggleWarningMessage() {
    if (this.showWarningMessage()) {
      this.warningEl.classList.remove('hidden');
    } else {
      this.warningEl.classList.add('hidden');
    }
  }

  showWarningMessage() {
    return this.changed() && !this.initiallyBlank();
  }

  initiallyBlank() {
    return this.field.attributes.originalValue.value === ''
  }

  changed() {
    if (this.field.value === this.field.attributes.originalValue.value) {
      return false;
    }
    return true;
  }
}

export default InputChangeMonitor;
