import ApplicationController from '../application_controller'

export default class TrayTriggerController extends ApplicationController {
  static outlets = ['containers--drawer-component']

  static targets = ['menu', 'menuContent']

  openTray(event) {
    this.dataset = event.currentTarget.dataset;
    const matchingClass = `m-tray-${this.dataset.targetedTray.toLowerCase()}`;

    if (this.isTileMenuTrigger()) this.beforeOpenTileDrawer();

    this.containersDrawerComponentOutlets.forEach((outlet) => {
      if (outlet.element.classList.contains(matchingClass)) {
        outlet.open();
      } else {
        outlet.closeDrawer();
      }
    })
  }

  closeAllOutletTrays() {
    this.containersDrawerComponentOutlets.forEach((outlet) => {
      outlet.close();
    })

    if (this.isTileMenuTrigger()) this.afterCloseTileMenu();
  }

  // * Start tile menu */
  toggleTileDropdown() {
    const menuDropdown = this.menuTarget;
    menuDropdown.classList.toggle('active');
    const isActive = menuDropdown.classList.contains('active');
    if (isActive) this.attachDropdownMenuCloseEvent();
  }

  isTileMenuTrigger() {
    return this.dataset.targetedTray === 'tile-menu';
  }

  beforeOpenTileDrawer() {
    this.buildTileDrawerContent();
  }

  afterCloseTileMenu() {
    this.clearTileDrawer();
  }

  buildTileDrawerContent() {
    const menuDropdownContent = this.menuContentTarget;
    const drawerContent = document.querySelector('.jsTileMenuContent .jsDrawerContentContainer');
    drawerContent.innerHTML = menuDropdownContent.innerHTML;
  }

  clearTileDrawer() {
    const drawerContent = document.querySelector('.jsTileMenuContent .jsDrawerContentContainer');
    drawerContent.innerHTML = '';
  }

  attachDropdownMenuCloseEvent() {
    const eventHandler = (event) => {
      const isInsideMenu = this.menuTarget.contains(event.target);
      if (isInsideMenu) return;

      this.menuTarget.classList.remove('active');
      document.removeEventListener('click', eventHandler);
    };

    window.setTimeout(() => {
      document.addEventListener('click', eventHandler);
    }, 0);
  }
}
