import Tips from './tips';
import Utilities from './utilities';

const Renderers = {};

/*
 * --------------------------------------------------
 *  Begin private methods
 */

function buildSwatchOptions(selectorOptions, colors, lineItemKey, previousColor) {
  return selectorOptions.reduce((acc, dropdownOption) => {
    const color = colors[dropdownOption.id];
    const inputId = `color_${lineItemKey}_${dropdownOption.id}`;
    const checkedAttribute = String(previousColor) === String(dropdownOption.id) ? 'checked' : '';
    const isActiveOption = checkedAttribute.length > 0;
    const containerKlass = `m-cart-config__color-wrap2 jsCartConfigColor color_swatch_${color.slug}${isActiveOption ? ' active' : ''}`;
    let colorStyle = `background-color:${color.hex};`;
    if (color.image) colorStyle += ` background-image:url('${color.image}')`;

    const optionHtml = `
      <div class="${containerKlass}">
        <label for="${inputId}" class="${color.classes || ''}" style="${colorStyle}" title="${color.display_name}" alt="${color.display_name}">
          <input
            id="${inputId}"
            ${checkedAttribute}
            value="${dropdownOption.id}"
            name="oli[${lineItemKey}][color_id]"
            type="radio">
        </label>
      </div>`;

    return acc + optionHtml;
  }, '');
}

function buildRadioOptions(selectorOptions, lineItemKey, optionGroup, prevRadioValue) {
  return selectorOptions.reduce((acc, radioOption) => {
    const inputId = `${optionGroup.slug}_${lineItemKey}_${radioOption.id}`;
    const selectedAttribute = String(prevRadioValue) === String(radioOption.id) ? 'checked' : '';
    const parenPosition = radioOption.name.indexOf('(');
    const viewportWidth = window.innerWidth;

    let optionName = radioOption.name;
    if (viewportWidth <= 768 && parenPosition > 0) {
      optionName = [optionName.slice(0, parenPosition), '<br>', optionName.slice(parenPosition)].join('');
    }

    const optionHtml = `
      <label for="${inputId}">
        <input
          class="radio-selector__radio"
          type="radio"
          ${selectedAttribute}
          id="${inputId}"
          value="${radioOption.id}"
          name="oli[${lineItemKey}][${optionGroup.name}]">
        <span class="radio-selector__select bold">
          ${optionName}
        </span>
      </label>
    `;

    return acc + optionHtml;
  }, '');
}

function renderDropdownSelector(opts) {
  const {
    index, optionGroup, container, initialRender, activeOptions, selectorOptions,
  } = opts;
  const selectorElement = document.querySelector(`${container} .${optionGroup.slug} select`);
  if (!selectorElement) return;
  const previousValue = initialRender && optionGroup.is_preselected
    ? activeOptions[optionGroup.slug] : selectorElement.value;

  selectorElement.innerHTML = '';

  if (initialRender && !optionGroup.is_preselected) {
    const optionElement = document.createElement('option');
    optionElement.value = '';
    optionElement.textContent = `Select a ${optionGroup.name}`;
    selectorElement.appendChild(optionElement);
  }

  selectorOptions[index].forEach((dropdownOption) => {
    const optionElement = document.createElement('option');
    optionElement.value = dropdownOption.id;
    optionElement.textContent = dropdownOption.name;
    optionElement.selected = String(previousValue) === String(dropdownOption.id);
    selectorElement.appendChild(optionElement);
  });

  if (!selectorElement.value) selectorElement.value = selectorElement.querySelector('option').value;
}

function renderRadioSelector(opts) {
  const {
    index, optionGroup, container, lineItemKey, initialRender, activeOptions, selectorOptions,
  } = opts;
  const radioSelectorElement = document.querySelector(`${container} .${optionGroup.slug}.jsCartConfigRadio`);
  const prevRadioValue = initialRender && optionGroup.is_preselected
    ? activeOptions[optionGroup.slug]
    : radioSelectorElement.querySelector('input:checked')?.value;

  const radioElements = buildRadioOptions(
    selectorOptions[index],
    lineItemKey,
    optionGroup,
    prevRadioValue,
  );
  radioSelectorElement.innerHTML = radioElements;
}

function renderSwatchSelector(opts) {
  const {
    index, optionGroup, container, lineItemKey, initialRender, activeOptions, selectorOptions, colors,
  } = opts;
  const colorContainerElement = document.querySelector(`${container} .jsCartConfigColors`);
  const previousColor = initialRender
    ? activeOptions[optionGroup.slug]
    : colorContainerElement.querySelector('input:checked')?.value;

  const swatchElements = buildSwatchOptions(
    selectorOptions[index],
    colors,
    lineItemKey,
    previousColor,
  );
  colorContainerElement.innerHTML = swatchElements;

  if (!colorContainerElement.querySelector('input:checked')) {
    const firstColorElement = colorContainerElement.querySelector('.jsCartConfigColor');
    firstColorElement.classList.add('active');
    firstColorElement.querySelector('input').checked = true;
  }

  TeePublic.HorizontalScroll.toInput(colorContainerElement.querySelector('input:checked'), 4);
}

function renderOptionsType(opts, inputStyle) {
  switch (inputStyle) {
    case 'radio':
      renderRadioSelector(opts);
      break;
    case 'swatch':
      renderSwatchSelector(opts);
      break;
    default:
      renderDropdownSelector(opts);
  }
}

/*
  * --------------------------------------------------
  *  Begin public methods
  */

Renderers.renderProductOptions = (opts) => {
  const {
    hierarchy, products, colors, oosProducts, selectedOptions, container, lineItemKey,
  } = opts;
  const { initialProductId, initialRender, renderOptions } = opts;
  const initialProductOptions = Utilities.getOptionsFromId(initialProductId, products);
  const activeOptions = initialProductOptions || selectedOptions;
  const availableProducts = Utilities.removeOosProducts(products, oosProducts);
  const selectorOptions = Utilities.collectSelectorOptions(
    availableProducts,
    hierarchy,
    activeOptions,
  );
  const skipRenderingOptions = renderOptions || Utilities.optionsToRender(null, hierarchy);

  hierarchy.forEach((optionGroup, index) => {
    if (skipRenderingOptions.get(optionGroup.slug)) return;

    const inputStyle = optionGroup.product_page_input_style;
    const settings = {
      index, optionGroup, container, lineItemKey, initialRender, selectorOptions, activeOptions,
    };
    if (inputStyle === 'swatch') settings.colors = colors;
    renderOptionsType(settings, inputStyle);
  });
};

Renderers.renderOptionsOnChange = (event) => {
  const { oos, ProductOptions } = TeePublic;
  const { colors, hierarchy } = ProductOptions.CanvasOptions;

  const sanitizedProducts = Utilities.sanitizeProducts(ProductOptions);
  const renderOptions = Utilities.optionsToRender(event, hierarchy);
  const formattedData = Utilities.collectSelections(
    'form.jsConfigOptions',
    ProductOptions,
    true,
  );

  const settings = {
    hierarchy,
    products: sanitizedProducts,
    colors,
    oosProducts: oos,
    selectedOptions: formattedData,
    container: '.jsConfigOptions',
    lineItemKey: 'new',
    initialRender: false,
    renderOptions,
  }
  Renderers.renderProductOptions(settings);
}

Renderers.renderOptionsOnInit = (products) => {
  const { initialSelectedProduct, ProductOptions, oos } = TeePublic;
  const { CanvasOptions } = ProductOptions;
  const { colors, hierarchy } = CanvasOptions;

  const fragmentProduct = Utilities.getFragmentIdProduct(products);
  const fragmentId = fragmentProduct ? fragmentProduct.id : null;
  const initialProductId = fragmentId || Number(initialSelectedProduct) || true;
  const renderOptions = Utilities.optionsToRender(null, hierarchy);
  const selections = Utilities.collectSelections('form.jsConfigOptions', ProductOptions, true);

  const settings = {
    hierarchy,
    products,
    colors,
    oosProducts: oos,
    selectedOptions: selections,
    container: '.jsConfigOptions',
    lineItemKey: 'new',
    initialProductId,
    initialRender: true,
    renderOptions,
  };
  Renderers.renderProductOptions(settings);
}

Renderers.renderExchangeOptionsOnChange = (event, oliId) => {
  const productOptions = TeePublic.Exchange.Data[oliId];
  const { hierarchy } = productOptions.CanvasOptions;
  const sanitizedProducts = Utilities.sanitizeProducts(productOptions);
  const renderOptions = Utilities.optionsToRender(event, hierarchy);
  const formattedData = Utilities.collectSelections(
    `.jsExchangeOli${oliId}Options`,
    productOptions,
    true,
  );

  const settings = {
    hierarchy,
    products: sanitizedProducts,
    colors: productOptions.CanvasOptions.colors,
    oosProducts: TeePublic.oos,
    selectedOptions: formattedData,
    container: `.jsExchangeOli${oliId}Options`,
    lineItemKey: oliId,
    initialRender: false,
    renderOptions,
  }
  Renderers.renderProductOptions(settings);
}

Renderers.renderExchangeOptionsOnInit = (oliId) => {
  const options = TeePublic.Exchange.Data[oliId];
  const { hierarchy } = options.CanvasOptions;
  const sanitizedProducts = Utilities.sanitizeProducts(options);
  const renderOptions = Utilities.optionsToRender(null, hierarchy);

  const settings = {
    hierarchy,
    products: sanitizedProducts,
    colors: options.CanvasOptions.colors,
    oosProducts: TeePublic.oos,
    selectedOptions: {},
    container: `.jsExchangeOli${oliId}Options`,
    lineItemKey: oliId,
    initialProductId: TeePublic.Exchange.OliProducts[oliId],
    initialRender: true,
    renderOptions,
  }
  Renderers.renderProductOptions(settings);
}

Renderers.renderSeasonalColorTipOnInit = (products) => {
  const { ProductOptions } = TeePublic;

  const selections = Utilities.collectSelections('form.jsConfigOptions', ProductOptions, true);
  const product = Utilities.findProductFromSelections(products, selections, ProductOptions);
  Tips.generateSeasonalColorTip(product, ProductOptions, products, selections);
}

export default Renderers;
