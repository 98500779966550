import Clipboard from 'clipboard'

class Copier {
  constructor(
    trigger,
    options,
    ) {
    this.trigger = trigger;
    this.options = options;
  }

  init() {
    let clipboard = new Clipboard(this.trigger, this.options);
    this.acknowledgeCopy(clipboard)
  }

  acknowledgeCopy(clipboard) {
    clipboard.on('success', function(e) {
      e.clearSelection();
      let wrapper = e.trigger
      if (wrapper.dataset.acknowledgecopy == 'true') {
        const timeoutTime = 2000;
        let inputEl = wrapper.querySelector(wrapper.dataset.inputel) || wrapper
        let copiedEl = wrapper.querySelector(wrapper.dataset.copiedel)
        inputEl.classList.add('copied', 'success', 'animate');
        copiedEl.classList.add('animate', 'success')

        setTimeout(() => {
          inputEl.classList.remove('copied', 'success');
          copiedEl.classList.remove('success')
        }, timeoutTime);
      }
    });
  }
}

export default Copier;
