import ModifyCart from 'core/services/modify_cart';
import Tray from './tray';

function initializeCart(cart) {
  const modifyCart = new ModifyCart({ cartContent: cart });
  modifyCart.mount();
}

function handleListener(controller, tray) {
  const events = ['touchstart', 'click']
  const trayObj = tray;

  const cartTray = tray.container.querySelector('.jsCartComponentContent')
  const { flipper } = cartTray.dataset;
  if (tray.content === '.jsMobileCartContent' && flipper === 'control') { initializeCart(cartTray) }

  events.forEach(event => controller.addEventListener(event, (e) => {
    e.preventDefault();

    trayObj.event = event;
    trayObj.activate();
  }))
}

function initTrays(controllers) {
  controllers.forEach((controller) => {
    const { dataset: { slidefrom, content } } = controller;
    const tray = new Tray({ slidefrom, content });
    handleListener(controller, tray)
  });
}

function TrayController() {
  const controllers = document.querySelectorAll('[role=tray-control]');
  initTrays(controllers);
}

export default TrayController;
