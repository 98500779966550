import ApplicationController from '../application_controller';

export default class TabController extends ApplicationController {
  static targets = ['tab', 'content'];
  static classes = ['active']
  static values = {
    initialTab: {
      type: Number,
      default: 0
    }
  }

  connect() {
    this.class = this.hasActiveClass ? this.activeClass : 'm-tab-nav__item--active';
    this.showTab(this.initialTabValue);
    this.element.classList.remove('m-tab-nav--init');
  }

  changeTab(event) {
    event.preventDefault();
    let tabIndex = parseInt(event.currentTarget.dataset.tabNavIndex);
    this.showTab(tabIndex);
  }

  showTab(index) {
    this.tabTargets.forEach((tab, i) => {
      tab.parentElement.classList.toggle(this.class, i === index);
      if (i === index) {
        this.contentTargets[i].removeAttribute('style');
      } else {
        this.contentTargets[i].setAttribute('style', 'display:none;');
      }
    });
  }
}
