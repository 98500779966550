import { DataLayer } from 'core/globals'; // This is a circular import and should be rethought

class RelatedSearch {
  static configureEventTracking(relatedSearchElements) {
    const [
      { dataset: { gtmWidgetImpressionId: widgetImpressionId } = {} } = {}
    ] = relatedSearchElements || [];
    const widgetOptions = [];

    for (let i = 0; i < relatedSearchElements.length; i += 1) {
      const relatedSearchElement = relatedSearchElements[i];

      // Build widgetOptions
      const {
        gtmResult: relatedSearchTerm,
        gtmScore: score,
        gtmPosition: position,
        gtmLocation: location,
        gtmLinkableToTagPage,
        gtmBucket: bucket,
      } = relatedSearchElement.dataset;

      const linkableToTagPage = gtmLinkableToTagPage === 'true';

      widgetOptions.push({
        location,
        position: parseInt(position, 10),
        relatedSearchTerm,
        linkableToTagPage,
        bucket,
        score: score ? parseFloat(score) : null
      });

      // Add listener to push submission event for all related search elements,
      // either 'click' of link for tag links, or 'submit' of parent for non tag links
      let listenerElement = relatedSearchElement;
      let event = 'click';

      if (!linkableToTagPage) {
        listenerElement = relatedSearchElement.parentNode;
        event = 'submit';
      }

      listenerElement.addEventListener(event, () => {
        DataLayer.push({
          event: 'relatedSearchSubmission',
          relatedSearchSubmission: {
            widgetImpressionId,
            widgetInteraction: {
              relatedSearchTerm,
              score: score ? parseFloat(score) : null,
              position: parseInt(position, 10),
              linkableToTagPage,
              location,
              bucket,
            }
          }
        });
      });
    }

    // Push impression event
    DataLayer.push({
      event: 'relatedSearchImpression',
      relatedSearchImpression: {
        widgetImpressionId,
        widgetOptions
      }
    });
  }

  constructor({ relatedSearchClass = 'gtmSearchRelated' } = {}) {
    const relatedSearchElements = document.getElementsByClassName(relatedSearchClass);
    if (relatedSearchElements.length) {
      RelatedSearch.configureEventTracking(relatedSearchElements);
    }
  }
}

export default RelatedSearch;
