/* eslint-disable class-methods-use-this */

class DesignCopier {
  populateForm(id, artwork) {
    var parent = document.querySelector('#jsDesignCopierModal'),
      idField = parent.querySelector('.jsDesignCopierId'),
      imageElement = parent.querySelector('.jsDesignCopierImage');

    idField.value = id;
    imageElement.setAttribute('src', artwork);
  }

  loadModalSettings(target) {
    let parent = target.closest('.jsDesignContainer');

    if (!parent) {
      const { designId } = target.dataset;
      parent = document.querySelector(`.jsDesignContainer[data-design-id="${designId}"]`);
    }

    const artwork = parent.querySelector('img').src,
      { id } = parent.dataset;
    this.populateForm(id, artwork);
  }

  init() {
    document.addEventListener('click', (event) => {
      const matchingElement = event.target.closest('.jsCopyDesign');
      if (matchingElement) {
        this.loadModalSettings(matchingElement);
        TeePublic.Utility.modalReveal($('#jsDesignCopierModal'));
      }
    });
  }
}

export default DesignCopier;
