import ApplicationController from '../application_controller'

export default class InactiveItemController extends ApplicationController {
  static targets = ['image']

  static values = { originalMockUp: String }

  updateMockup(event) {
    this.imageTarget.src = event.currentTarget.dataset.mock
  }

  resetMockup() {
    this.imageTarget.src = this.originalMockUpValue
  }
}
