import { EVENT_TYPES } from 'shared';

/* Format feedback.results and feedback.selection value here
  to avoid sending unused data and exceeding cookie size limit */
const formattedResults = (results) => {
  if (results.length === 0) return results

  return results.map(result => {
    return {
      suggestion: result?.value?.suggestion,
      type: result?.value?.type,
      position: result?.index + 1,
    }
  })
}

const formattedSelection = (selection) => {
  return {
    value: selection?.value?.suggestion,
    type: selection?.value?.type,
    position: selection?.index + 1,
  }
}

const SelectionHandler = (searchElement) => ((feedback) => {
  const eventTriggeredBy = feedback.event.eventTriggeredBy || 'keydown';
  feedback.eventTriggeredBy = eventTriggeredBy;
  feedback.matches = feedback.matches.length;
  feedback.results = formattedResults(feedback.results)
  feedback.selection = formattedSelection(feedback.selection)
  searchElement.dataset.feedback = JSON.stringify(feedback);
  searchElement.dataset.submitted = true;

  var evt = new CustomEvent(EVENT_TYPES.SUBMIT, { bubbles: true, cancelable: true });
  searchElement.closest('form').dispatchEvent(evt);
})

export default SelectionHandler;
