import ApplicationController from '../application_controller';

class DrawerComponent extends ApplicationController {
  static targets = ['content', 'primaryDrawerCloseButton']

  static outlets = ['containers--drawer-component']

  initialize() {
    this.secondaryDrawerOpened = false;
  }

  connect() {
    this.element.drawerComponent = this
    this.originalBodyPaddingRight = null;
    this.scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
  }

  open() {
    this.originalBodyPaddingRight = document.body.style.paddingRight;
    this.openDrawer();
    document.body.style.paddingRight = `${this.scrollbarWidth}px`;
    document.body.style.overflow = 'hidden';
  }

  close() {
    this.closeDrawer();
    document.body.style.paddingRight = this.originalBodyPaddingRight;
    document.body.style.overflow = '';

    if (this.hasPrimaryDrawerCloseButtonTarget) {
      this.secondaryDrawerOpened = false;
      this.togglePrimaryDrawerCloseButton();
    }
  }

  openDrawer() {
    this.contentTarget.classList.add('active')
  }

  closeDrawer() {
    this.contentTarget.classList.remove('active')
  }

  closePrimaryDrawer() {
    this.close();

    if (this.hasContainersDrawerComponentOutlet) {
      this.containersDrawerComponentOutlet.close();
      this.containersDrawerComponentOutlet.handlePrimaryDrawerCloseButton();
    }
  }

  toggleSecondaryDrawerOpenedState() {
    if (this.element.classList.contains('active')) {
      this.secondaryDrawerOpened = true;
    } else {
      this.secondaryDrawerOpened = false;
    }
  }

  togglePrimaryDrawerCloseButton() {
    if (!this.hasPrimaryDrawerCloseButtonTarget) return;

    if (this.secondaryDrawerOpened) {
      this.primaryDrawerCloseButtonTarget.classList.add('hide');
    } else {
      this.primaryDrawerCloseButtonTarget.classList.remove('hide');
    }
  }

  handlePrimaryDrawerCloseButton() {
    this.toggleSecondaryDrawerOpenedState();
    this.togglePrimaryDrawerCloseButton();
  }

  handleBackToMenu() {
    this.containersDrawerComponentOutlet.secondaryDrawerOpened = false
    this.containersDrawerComponentOutlet.togglePrimaryDrawerCloseButton();
  }
}

export default DrawerComponent;
