import Copier from './copier'
import Flash from './flash'
import Tooltip from './tooltip'
import InstantForm from './instant_form'
import InputChangeMonitor from './input_change_monitor'
import DatePicker from './date_picker'
import Loader from './loader'
import FadedEdges from './faded_edges'

function flash(message, status) {
  const constructFlash = new Flash(message, status);
  constructFlash.init();
}

function copier(trigger, options) {
  const constructCopier = new Copier(trigger, options);
  constructCopier.init();
}

function tooltip(trigger, text) {
  const triggers = document.querySelectorAll(trigger);
  triggers.forEach((e) => {
    const constructTooltip = new Tooltip(e, text);
    constructTooltip.init()
  })
}

function instantForm(url, params) {
  const constructInstantForm = new InstantForm(url, params);
  constructInstantForm.send();
}

function datePicker(selector, dateFormat) {
  const constructDatePicker = new DatePicker(selector, dateFormat);
  constructDatePicker.init();
}

function loader(el) {
  const loaderEl = document.querySelector(`${el}`);
  return new Loader(loaderEl);
}

function fadedEdges(el, elWrapper) {
  const fadedEdgesEl = document.getElementById(el);
  const fadedEdgesWrapperEl = document.getElementById(elWrapper);
  const constructFadedEdges = new FadedEdges(fadedEdgesEl, fadedEdgesWrapperEl);
  constructFadedEdges.init();
}

const Utilities = {
  copier, flash, tooltip, instantForm, datePicker, InputChangeMonitor, loader, fadedEdges,
}

export default Utilities;
