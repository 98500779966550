import ApplicationController from '../application_controller';

export default class ReadMoreController extends ApplicationController {
  static targets = ['content', 'showMoreBtn'];

  static values = {
    lineClampNum: { type: String, default: '2' },
  };

  connect() {
    this.showLess();
    this.displayShowMoreBtn();
  }

  displayShowMoreBtn() {
    if (this.contentTarget.scrollHeight > this.contentTarget.clientHeight) {
      this.showMoreBtnTarget.style.removeProperty('display', 'none');
    }
  }

  showMore(event) {
    event.currentTarget.style.setProperty('display', 'none');
    this.contentTarget.style.removeProperty('-webkit-line-clamp');
  }

  showLess() {
    this.contentTarget.style.setProperty('-webkit-line-clamp', this.lineClampNumValue);
    this.contentTarget.style.setProperty('-webkit-box-orient', 'vertical');
  }
}
