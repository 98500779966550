import ApplicationController from '../application_controller';
import client from 'braintree-web/client';
import applePay from 'braintree-web/apple-pay';
import Fetchers from 'core/fetchers';


export default class extends ApplicationController {
  static classes = [ 'hidden' ];
  static targets = [ 'container', 'button', 'nonce', 'form', 'pricing' ];
  static outlets = ['checkout--checkout-2024'];

  connect() {
    this.canApplePay = false;
    this.applePayInstance = null;
    this.session = null;
    this.routes = {
      cart: '/cart/applepay',
    }

    if (window.ApplePaySession) {
      this.initApplePay();
    }
  }

  get braintreeClient() {
    const value = this.getBraintree();
    Object.defineProperty(this, 'braintreeClient', { value });
    return value;
  }

  getBraintree() {
    this.cartSettings = TeePublic.Cart.Settings;
    return client.create({
      authorization: this.cartSettings.btKey,
    });
  }

  initApplePay() {
    this.braintreeClient.then((clientInstance) => {
      applePay.create({
        client: clientInstance,
      }, (applePayErr, applePayInstance) => {
        if (applePayErr) {
          console.error('Error creating applePayInstance:', applePayErr);
          return;
        }

        this.setupApplePay(applePayInstance)
      })
    })
  }

  setupApplePay(_applePayInstance) {
    this.applePayInstance = _applePayInstance;
    return this.fetchPaymentData();
  }

  showApplePay() {
    this.containerTargets.forEach((ele) => {
      ele.classList.remove('hide');
    })

    this.buttonTargets.forEach((ele) => {
      ele.classList.remove('hide');
    })
  }

  openPaymentModal() {
    TeePublic.RudderstackHelpers.trackSubmissionWithController('.jsFormSubmissionApplePay');
    this.initApplePaySession();
  }

  fetchPaymentData() {
    const promise = window
      .ApplePaySession
      .canMakePaymentsWithActiveCard(this.applePayInstance.merchantIdentifier);
    promise.then((canMakePaymentsWithActiveCard) => {
      this.canApplePay = canMakePaymentsWithActiveCard
      if (this.canApplePay) this.showApplePay();
      return false
    })
  }

  buildPaymentRequest() {
    const pricingData = JSON.parse(this.pricingTarget.dataset.applePayPricingBreakdown);
    return this.applePayInstance.createPaymentRequest(pricingData);
  }

  onValidateMerchant(event) {
    this.applePayInstance.performValidation({
      validationURL: event.validationURL,
      displayName: 'TeePublic.com',
    }, (validationErr, merchantSession) => {
      if (validationErr) {
        // You should show an error to the user, e.g. 'Apple Pay failed to load.'
        console.error('Error validating merchant:', validationErr);
        this.session.abort();
        return;
      }
      this.session.completeMerchantValidation(merchantSession);
    });
  }

  onPaymentAuthorized(event) {
    this.applePayInstance.tokenize({
      token: event.payment.token,
    }, (tokenizeErr, payload) => {
      if (tokenizeErr) {
        console.error('Error tokenizing Apple Pay:', tokenizeErr);
        this.session.completePayment(ApplePaySession.STATUS_FAILURE);
      } else {
        this.nonceTarget.value = payload.nonce;
        this.formTarget.submit();
        this.session.completePayment(ApplePaySession.STATUS_SUCCESS);
      }
    });
  }

  initApplePaySession(optionalParams = {}) {
    this.session = new ApplePaySession(3, this.buildPaymentRequest());

    // Session Callbacks
    this.session.onvalidatemerchant = e => this.onValidateMerchant(e);
    this.session.onpaymentauthorized = e => this.onPaymentAuthorized(e, optionalParams);

    // Show ApplyPay interface.
    this.session.begin();
  }
}
