class Campaigns {
  constructor() {
    this.countdown = document.querySelector('.jsCountdown')
    this.endTime = new Date(this.countdown.getAttribute('data-end-time')).getTime() / 1000
  }

  init() {
    if (this.countdown === null) { return; }

    setInterval(() => {this.executeTick()}, 999);
  }

  executeTick() {
    var currentTime = new Date().getTime() / 1000;
    var difference = Math.round(this.endTime - currentTime);

    this.days = Math.floor(difference / (60 * 60 * 24));
    difference =  difference - (this.days * 60 * 60 * 24);

    this.hours = Math.floor(difference / (60 * 60));
    difference =  difference - (this.hours * 60 * 60);

    this.minutes = Math.floor(difference / 60);
    difference =  difference - (this.minutes * 60);

    this.seconds = difference;

    if(this.endTime < currentTime) {
      this.days = this.hours = this.minutes = this.seconds = 0;
    }
    
    this.updateClock();
  }

  updateClock() {
    document.querySelector('.days .time').textContent = this.days, this.countdown;
    document.querySelector('.hours .time').textContent = this.hours, this.countdown;
    document.querySelector('.minutes .time').textContent = this.minutes, this.countdown;
    document.querySelector('.seconds .time').textContent = this.seconds, this.countdown;
  }
}

export default Campaigns;
