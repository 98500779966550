import ApplicationController from '../application_controller';

class MenuComponent extends ApplicationController {
  static targets = ['list', 'icon']

  initialize() {
    const isSelected = [...this.listTarget.getElementsByTagName('a')].some(item => item.dataset.selected === 'true')
    if (isSelected) this.toggleExpand()
  }

  connect() {
    this.element.menuComponent = this
  }

  toggleExpand() {
    this.listTarget.classList.toggle('expanded')
    this.iconTarget.classList.toggle('flipped')
  }
}

export default MenuComponent
