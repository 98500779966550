import ApplicationController from '../application_controller';

class Radio extends ApplicationController {
  static targets = ['wrapper', 'additionalInfo', 'input', 'descriptions']
  static values = {
    conditionalContainer: String
  }

  connect() {
    this.update()
  }

  update() {
    this.toggleAdditionalInfo();
    this.updateWrapperClasses();
    this.toggleConditionalContainers();
  }

  updateWrapperClasses() {
    const selectedClass = 'c-radio-new--selected';
    if (this.inputTarget.checked) {
      this.wrapperTarget.classList.add(selectedClass)
    } else {
      this.wrapperTarget.classList.remove(selectedClass)
    }
  }

  toggleAdditionalInfo() {
    if (!this.hasAdditionalInfoTarget) { return; }

    if (this.inputTarget.checked) {
      this.additionalInfoTarget.classList.remove('hide')
    } else {
      this.additionalInfoTarget.classList.add('hide')
    }
  }

  setDescriptions(descriptions) {
    if (descriptions.length > 0) {
      this.descriptionsTarget.classList.remove('hide');
      this.descriptionsTarget.innerHTML = '';
      descriptions.forEach((description) => {
        this.descriptionsTarget.innerHTML += `<p>${description}</p>`;
      });
    } else {
      this.classList.add('hide')
    }
  }

  toggleConditionalContainers() {
    if (this.hasConditionalContainerValue) {
      document.querySelectorAll(this.conditionalContainerValue).forEach((container) => {
        if (this.inputTarget.checked) {
          container.classList.remove('hide')
        } else {
          container.classList.add('hide')
        }
      });
    }
  }
}

export default Radio;
