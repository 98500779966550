import { trackEvent } from 'core/services/rudderstack_helpers'

class RudderstackCheckoutTracker {
  static SHIPPING_STATE = 'shipping';

  static VERIFY_ADDRESS_STATE = 'verify_address';

  static PAYMENT_STATE = 'payment';

  static get paymentMethod() {
    return document.querySelector('.jsPaymentOptions input:checked').value
  }

  static get shippingMethod() {
    return document.querySelector('.jsShipOptions input:checked').value
  }

  constructor() {
    this.state = {
      cartId: null,
      previous: null,
      current: null,
      errors: [],
    }

    this.setCurrentStep();
  }

  setCurrentStep() {
    const usingSavedAddress = document.querySelector('.jsUseSavedAddress')?.checked;
    const savedAddress = document.querySelector('.jsShippingInfo .m-account__address-info')?.getAttribute('data-address-info')
    const isVerified = savedAddress && JSON.parse(savedAddress).verified;

    if (usingSavedAddress && isVerified) {
      this.state.previous = RudderstackCheckoutTracker.VERIFY_ADDRESS_STATE;
      this.state.current = RudderstackCheckoutTracker.PAYMENT_STATE;
    } else if (this.state.current === RudderstackCheckoutTracker.SHIPPING_STATE) {
      // we don't need to reset the state if we're already on the shipping step
      return;
    } else {
      this.state.previous = null;
      this.state.current = RudderstackCheckoutTracker.SHIPPING_STATE;
    }

    this.handleCheckoutSteps();
  }

  handleCheckoutSteps() {
    this.sendRudderstackEvent('Checkout Step Viewed', this.state.current);
    this.sendRudderstackEvent('Checkout Step Completed', this.state.previous);
  }

  checkoutErrorMessages() {
    // Removes duplicate error messages and puts them
    // in un-nested array
    const errors = this.state.errors.flat();
    const uniqueErrors = [...new Set(errors)];
    return uniqueErrors;
  }

  cartId() {
    return this.state.cartId;
  }

  sendRudderstackEvent(event, step) {
    if (!step) return;

    const properties = {
      step,
      cart_id: this.cartId(),
      shipping_method: this.shippingMethod,
      payment_method: this.paymentMethod,
    };

    if (event === 'Checkout Step Completed') {
      properties.errors = this.checkoutErrorMessages();
    }

    trackEvent(event, properties);
  }

  advanceStep() {
    switch (this.state.current) {
      case RudderstackCheckoutTracker.SHIPPING_STATE:
        this.state.previous = this.state.current
        this.state.current = RudderstackCheckoutTracker.VERIFY_ADDRESS_STATE;
        break;
      case RudderstackCheckoutTracker.VERIFY_ADDRESS_STATE:
        this.state.previous = this.state.current
        this.state.current = RudderstackCheckoutTracker.PAYMENT_STATE;
        break;
      case RudderstackCheckoutTracker.PAYMENT_STATE:
        this.state.previous = this.state.current;
        this.state.current = null;
        break;
      default:
        break;
    }

    this.handleCheckoutSteps();
  }
}

function initRudderstackCheckoutTracker(savedAddress) {
  return new RudderstackCheckoutTracker(savedAddress);
}

export default initRudderstackCheckoutTracker;
