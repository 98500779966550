import ApplicationController from '../application_controller';

class ContentLazyLoader extends ApplicationController {
  static values = { url: String, fireRequest: Boolean }

  connect() {
    this.observer = new IntersectionObserver(this.loadContent.bind(this))
    this.observer.observe(this.element)
  }

  loadContent(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting && this.fireRequestValue) {
        this.fetchContent();

        observer.unobserve(entry.target)
      }
    })
  }

  fetchContent() {
    fetch(this.urlValue)
      .then(response => response.text())
      .then((data) => {
        this.element.innerHTML = data
        // manually dispatch event
        // in stimulus 3.0 there's a helper: this.dispatch('customEventName')
        const event = new CustomEvent('lazyload')
        window.dispatchEvent(event)
      })
      .catch((error) => {
        this.handleError(error);
      })
  }
}

export default ContentLazyLoader;
