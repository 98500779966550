export const appendArtistSearchOption = function(list, query) {
  if (query === '') return;

  const artistSearchElement = list.querySelector('.autoComplete_result.artist-search');
  const sanitizedQuery = query.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  const newValue = `<span class="artist_search_label">See designs by <strong class="query-term">${sanitizedQuery}</strong></span><div class="tp-label tp-label--highlight artist-search-label">Artist</div>`;

  if (artistSearchElement) {
    artistSearchElement.innerHTML = newValue;
  } else {
    const newResultElement = document.createElement('li');
    newResultElement.setAttribute('class', 'autoComplete_result artist-search');
    const artistSearchLabelElement = document.createElement('li');
    artistSearchLabelElement.setAttribute('class', 'autoCompleteArtistSearch_label');

    newResultElement.innerHTML = newValue;
    // Add Artist Result label above artist search results
    list.appendChild(artistSearchLabelElement);
    list.appendChild(newResultElement);
  }
}
