// Class to add jQuery UI date picker to elements with '.date_pick' class.
// When we remove jQuery, we will replace with a lighter, yet responsive & accessible library (perhaps https://github.com/duetds/date-picker)
class DatePicker {
  constructor(
    selector,
    dateFormat,
  ) {
    this.selector = selector || '.date_pick';
    this.dateFormat = dateFormat || 'yy-mm-dd';
  }

  init() {
    $(this.selector).datepicker({
      dateFormat: this.dateFormat,
    })
  }
}

export default DatePicker;
