import ApplicationController from '../application_controller';
import Fetchers from 'core/fetchers';

export default class FormSubmissionController extends ApplicationController {
  static targets = ['input', 'message'];
  static values = {
    url: String,
    method: { type: String, default: 'POST' },
    parentClass: String,
    successClass: String,
    errorClass: String
  }

  connect() {
    this.submissionUrl = this.urlValue || this.element.action;
  }

  submit(event) {
    event.preventDefault();

    const body = JSON.stringify(this._serializeData());

    Fetchers.fetchJSON(this.submissionUrl, this.methodValue, body)
      .then(response => {
        if (response.ok) {
          document.querySelector(this.parentClassValue).classList.remove(this.errorClassValue);
          document.querySelector(this.parentClassValue).classList.add(this.successClassValue);
        } else {
          document.querySelector(this.parentClassValue).classList.remove(this.successClassValue);
          document.querySelector(this.parentClassValue).classList.add(this.errorClassValue);
        }
        return response.json();
      })
      .catch(_error => {
        document.querySelector(this.parentClassValue).classList.add(this.errorClassValue);
      });
  }

  _serializeData() {
    const formData = {};

    this.inputTargets.forEach(input => {
      const name = input.name;
      const matches = name.match(/^(\w+)\[(\w+)\]$/);
      if (matches) {
        const [_, outer, inner] = matches;
        formData[outer] = formData[outer] || {};
        formData[outer][inner] = input.value;
      }
    });
    return formData;
  }
}
