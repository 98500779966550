import ModifyCart from 'core/services/modify_cart';
import ApplicationController from '../application_controller';

class Callback extends ApplicationController {
  static targets = ['button', 'cartContent']

  initializeCallback() {
    if (this.hasCartContentTarget) { this.initializeCart() }

    if (this.hasButtonTarget) { this.initializePaypal() }
  }

  initializePaypal() {
    TeePublic.Cart.CheckoutPaypal.init('responsive', `#${this.buttonTarget.id}`);
  }

  initializeCart() {
    const modifyCart = new ModifyCart({ cartContent: this.cartContentTarget });
    modifyCart.mount();
  }
}

export default Callback;
