import ApplicationController from './application_controller'

export default class QuantityStepperController extends ApplicationController {
  static targets = ['numInput', 'decrementButton', 'incrementButton']

  static values = {
    min: Number,
    max: Number,
  }

  inactiveClass = 'inactive';

  connect() {
    const numInput = this.numInputTarget;
    const value = parseInt(numInput.value, 10);
    const min = parseInt(this.minValue || 0, 10);
    const max = parseInt(this.maxValue, 10);

    if (value === min) {
      this.disableElement(this.decrementButtonTarget);
    }

    if (value === max) {
      this.disableElement(this.incrementButtonTarget);
    }
  }

  decrement(event) {
    const numInput = this.numInputTarget;
    const value = parseInt(numInput.value, 10);
    const min = parseInt(this.minValue || 0, 10);
    const newValue = value - 1;

    if (newValue >= min) {
      numInput.value = newValue;
      min === newValue
        ? this.disableElement(event.target)
        : this.enableElement(this.incrementButtonTarget);
    }
  }

  increment(event) {
    const numInput = this.numInputTarget;
    const value = parseInt(numInput.value, 10);
    const max = parseInt(this.maxValue, 10);
    const newValue = value + 1;

    if (newValue <= max) {
      numInput.value = newValue;
      max === newValue
        ? this.disableElement(event.target)
        : this.enableElement(this.decrementButtonTarget);
    }
  }

  disableElement(element) {
    element.classList.add(this.inactiveClass);
    element.disabled = true;
  }

  enableElement(element) {
    element.classList.remove(this.inactiveClass);
    element.disabled = false;
  }
}
