import toggleAnimatedPlaceholder from './animated_placeholder';

export const togglePlaceholder = (field) => {
  if (field === '') {
    toggleAnimatedPlaceholder('show');
  } else {
    toggleAnimatedPlaceholder('hide');
  }
}

const isArtistSearchOption = (selectedOption) => {
  return selectedOption.classList.contains('artist-search');
}

const notAutoCompleteSuggestion = (selectedOption) => {
  return selectedOption.classList.contains('empty-results') || isArtistSearchOption(selectedOption);
}

const computeDataFeedbackKeyAndSuggestion = (selectedOption, value) => {
  let key, suggestionValue;
  if (isArtistSearchOption(selectedOption)) {
    key = 'artist-search';
    suggestionValue = `See designs by "${value}"`;
  } else {
    key = 'empty-results';
    suggestionValue = `Show results for "${value}"`
  }

  return { key, suggestionValue };
}

const updateFormFields = (inputElement, selectedOption, selectedValue) => {
  // Update artist search hidden field
  const artistSearchField = inputElement.closest('form').querySelector('.jsArtistSearchData');
  artistSearchField.value = isArtistSearchOption(selectedOption) ? true : null;

  // Update input element
  inputElement.value = selectedValue;
}

export const keyboardNavigationHandler = (event, config) => {
  const inputElement = event.currentTarget;
  const selectedOption = document.querySelector("[aria-selected=true]");
  const notSuggestedOption = notAutoCompleteSuggestion(selectedOption);
  const selectedValue = notSuggestedOption ? selectedOption.querySelector('.query-term').textContent : selectedOption.textContent;

  updateFormFields(inputElement, selectedOption, selectedValue);

  // Manually attaching onSelection event on non-autocomplete options
  inputElement.onkeydown = function(e) {
    if (notSuggestedOption && e.key === 'Enter') {
      const { key, suggestionValue } = computeDataFeedbackKeyAndSuggestion(selectedOption, selectedValue)

      const dataFeedback = {
        ...event.detail,
        selection: {
          index: event.detail.results.length,
          key,
          match: '',
          value: {
            suggestion: suggestionValue,
            type: 'no-suggestion',
            weight: '',
          }
        }
      }
      config.onSelection(dataFeedback);
    }
  }
}

export const mouseEventHandler = (event, config) => {
  const inputElement = event.target;
  const list = event.target.nextElementSibling;

  list.childNodes.forEach(function(option) {
    option.onmousedown = function() {
      const notSuggestedOption = notAutoCompleteSuggestion(option);
      const selectedValue = notSuggestedOption ? option.querySelector('.query-term').textContent : option.textContent;
      const evt = new MouseEvent("click", { bubbles: true, cancelable: true });
      evt.eventTriggeredBy = 'mousedown';
      updateFormFields(inputElement, option, selectedValue);

      // Manually attaching onSelection event on non-autocomplete options
      if (notSuggestedOption) {
        const { key, suggestionValue } = computeDataFeedbackKeyAndSuggestion(option, selectedValue);

        const dataFeedback = {
          event: evt,
          input: event.detail.input,
          matches: [],
          query: event.detail.query,
          results: [],
          selection: {
            index: 0,
            key,
            match: '',
            value: {
              suggestion: suggestionValue,
              type: 'no-suggestion',
              weight: '',
            }
          }
        }
        config.onSelection(dataFeedback);
      } else {
        option.dispatchEvent(evt);
      }
    }
  })
}
