const fetchJs = (path, method = 'GET') => {
  const data = {
    method,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/javascript',
    },
    credentials: 'same-origin',
  }

  return fetch(path, data).then((response) => {
    if (!response.ok) throw new Error('Network response was not ok');
    return response.text();
  }).then((text) => {
    const script = document.createElement('script');
    script.innerHTML = text;
    document.body.appendChild(script);
  });
};

export default fetchJs;
