class QuantityStepper {
  static inactiveClass = 'inactive';

  constructor({
    numInputClass = 'jsQuantityStepper',
    incrementClass = 'jsQuantityIncrement',
    decrementClass = 'jsQuantityDecrement'
  } = {}) {
    this.numInputClass = numInputClass;
    this.incrementClass = incrementClass;
    this.decrementClass = decrementClass;
    const numInputElements = document.getElementsByClassName(numInputClass);

    for(const numInput of numInputElements) {
      const value = parseInt(numInput.value, 10);
      const max = parseInt(numInput.max, 10);
      const min = parseInt(numInput.min, 10);
      const incrementElement = this.getSiblingFromParent(numInput, this.incrementClass)
      const decrementElement = this.getSiblingFromParent(numInput, this.decrementClass)

      if (value === max) {
        this.disableElement(incrementElement);
      }

      if (value === min) {
        this.disableElement(decrementElement);
      }

      // Add handlers to increment / decrement siblings
      incrementElement.addEventListener('click', () => {
        this.increment(incrementElement);
      });
      
      decrementElement.addEventListener('click', () => {
        this.decrement(decrementElement);
      });
    }
  }

  decrement = element => {
    const numInput = this.getSiblingFromParent(element, this.numInputClass);
    const value = parseInt(numInput.value, 10);
    const min = parseInt(numInput.min || 0, 10);
    const newValue = value - 1;

    if (newValue >= min) {
      numInput.value = newValue;
      min === newValue
        ? this.disableElement(element)
        : this.enableElement(this.getSiblingFromParent(element, this.incrementClass));
    }
  }

  increment = element => {
    const numInput = this.getSiblingFromParent(element, this.numInputClass);
    const value = parseInt(numInput.value, 10);
    const max = parseInt(numInput.max, 10);
    const newValue = value + 1;

    if (!max || newValue <= max) {
      numInput.value = newValue;
      max === newValue
        ? this.disableElement(element)
        : this.enableElement(this.getSiblingFromParent(element, this.decrementClass));
    }
  }

  getSiblingFromParent(element, className) {
    return element.parentElement.getElementsByClassName(className)[0];
  }

  disableElement(element) {
    element.classList.add(QuantityStepper.inactiveClass);
    element.disabled = true;
  }

  enableElement(element) {
    element.classList.remove(QuantityStepper.inactiveClass);
    element.disabled = false;
  }
}

export default QuantityStepper;
