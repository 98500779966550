import ApplicationController from '../application_controller';

export default class TimerController extends ApplicationController {
  static outlets = ["containers--countdown"];

  connect() {
    this.now = new Date();

    if (this.hasContainersCountdownOutlet) this.startCountdown();

    document.addEventListener('TP::countdown:connect', () => {
      if (!this.running) this.startCountdown();
    });
  }

  disconnect() {
    clearInterval(this.interval);
    document.removeEventListener('TP::countdown:connect', () => {});
  }

  tick() {
    this.containersCountdownOutlets.forEach((countdown) => {
      countdown.update(this.now);
    });
  }

  startCountdown() {
    document.removeEventListener('TP::countdown:connect', () => {});
    this.running = true;
    this.interval = setInterval(() => {
      this.now = new Date(this.now.getTime() + 1000);
      this.tick();
    }, 1000);
  }
}
