import ApplicationController from '../application_controller';

class Notification extends ApplicationController {
  static targets = ['counter']

  static values = { count: Number }

  connect() {
    this.element.notification = this;
  }

  updateCount() {
    if (this.countValue > 0) {
      this.counterTarget.innerText = this.countValue;
    } else {
      this.counterTarget.remove();
    }
  }
}

export default Notification;
