import { createPopper } from '@popperjs/core';
import ApplicationController from '../application_controller';

// Connects to data-controller="tooltip"
class Tooltip extends ApplicationController {
  static targets = ["element", "tooltip"];
  static values = { placement: String };

  connect() {
    this.popperInstance = createPopper(this.elementTarget, this.tooltipTarget, {
      placement: this.placementValue || 'top',
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ],
    });
  }

  show(event) {
    this.tooltipTarget.setAttribute("data-show", "");
    this.popperInstance.update();
  }

  hide(event) {
    this.tooltipTarget.removeAttribute("data-show");
  }

  disconnect() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
  }
}

export default Tooltip;
