import Fetchers from 'core/fetchers';

const Loaders = {};

Loaders.loadSimilarProductImgs = (similarProductsPath) => {
  Fetchers.fetchJs(similarProductsPath, 'GET').then(() => {
    TeePublic.Utility.unveil_images('.jsSimilarProductImg');
  });
}

export default Loaders;
