import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  static targets = ['uploadButton', 'updating'];
  static classes = ['updating'];

  handleFileAdded(event) {
    this.uploadButtonTarget.disabled = !event.target.value;
  }

  handleClick() {
    this.openLoadingOverlay();
  };

  openLoadingOverlay() {
    this.updatingTarget.classList.add(this.updatingClass);
  }
}
