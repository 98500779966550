import Fetchers from 'core/fetchers';
import ApplicationController from '../application_controller';

class AbTest extends ApplicationController {
  static values = { testName: String }

  searchResultsEngagementClick(_event) {
    const dlPageLoadEvt = window.dataLayer?.find(e => e.event === 'pageLoad')
    Fetchers.fetchJSON('/ab-test/finish/search-results-engagement', 'POST', JSON.stringify({
      tag: dlPageLoadEvt?.search__tag,
      query: dlPageLoadEvt?.search__query,
    }))
  }

  endTestClick() {
    const url = `/ab-test/finish/${this.testNameValue}`;
    Fetchers.fetchJSON(url, 'POST')
  }
}

export default AbTest;
