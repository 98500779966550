import ApplicationController from '../application_controller';
// TODO: abstract this as a global toggle module
class ToggleOverlay extends ApplicationController {
  static targets = ['content']

  connect() {
    this.element.toggleComponent = this
  }

  toggle() {
    this.contentTarget.classList.toggle('active')
  }
}

export default ToggleOverlay
