import PromotedFilter from './promoted_filter';
import RelatedSearch from './related_search';
import RelatedTag from './related_tag';
import SafeSearch from './safe_search';

const Search = {
  PromotedFilter,
  RelatedSearch,
  RelatedTag,
  SafeSearch,
};

export default Search;
