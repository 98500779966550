import fetchJSON from 'core/fetchers/fetch_json';

const Posters = {};

Posters.addToCart = (opts) => {
  fetchJSON('/add_to_cart', 'POST', JSON.stringify(opts))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      window.location = '/cart';
    }).catch(() => alert('This product is no longer available.'));
}

export default Posters;
