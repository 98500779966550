const getToken = () => document.getElementsByName('csrf-token')[0].getAttribute('content');

const buildHeaders = () => ({
  'X-CSRF-Token': getToken(),
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
});

const fetchJSON = (path, method = 'POST', body = '') => (fetch(path, {
  method,
  headers: buildHeaders(),
  credentials: 'same-origin',
  body,
}));

export default fetchJSON;
