import DataConfig from './data_config';
import ResultsList, { resultItem } from './results_list';
import SelectionHandler from './selection_handler';
import toggleAnimatedPlaceholder from '../animated_placeholder';

const debounce = 200;
const placeHolder = 'Search all designs';
const searchEngine = 'loose';
const threshold = 0;

const AutocompleteConfig = (searchSelector, resultSelectors) => getDefaultOpts(searchSelector, resultSelectors);

const getDefaultOpts = (searchSelector, resultSelectors) => {
  const searchElement = document.querySelector(`#${searchSelector}`);

  if (searchElement && searchElement.value) {
    toggleAnimatedPlaceholder('hide');
  }
  // Bind to input and focus for trending on initial click
  // Force return true on condition to trigger trending results on focus
  const trigger = { event: ['input', 'focus'], condition: () => true };

  return {
    data: DataConfig(searchElement),
    debounce,
    onSelection: SelectionHandler(searchElement),
    placeHolder,
    resultItem,
    resultsList: ResultsList(searchElement, resultSelectors),
    searchEngine,
    selector: `#${searchSelector}`,
    threshold,
    trigger,
  };
};

export default AutocompleteConfig;
