import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  static classes = [ 'hidden' ];
  static targets = [ 'collapsed', 'expanded' ];

  toggle() {
    const targetElements = [this.collapsedTarget, this.expandedTarget];
    const [elementToHide, elementToShow] =
      this.collapsed() ? targetElements : targetElements.reverse();
    elementToHide.classList.add(this.hiddenClass);
    elementToShow.classList.remove(this.hiddenClass);
  }

  collapsed() {
    return this.expandedTarget.classList.contains(this.hiddenClass);
  }
}
