import { trackEvent } from 'core/services/rudderstack_helpers'

class RudderstackProductDataBuilder {
  constructor(link) {
    this.link = link;
    this.parent = this.link.closest('.jsDesignContainer');
    this.data = this.parent.dataset;
  }

  buildData() {
    return {
      artist_name: this.data.gtmDesignerName,
      canvas_id: this.data.canvasId,
      currency_code: this.data.currencyCode,
      design_id: this.data.designId,
      designer_id: this.data.designerId,
      from_search_results: this.data.fromSearchResults,
      image_url: this.data.imageUrl,
      location: this.data.location,
      name: this.data.gtmDesignTitle,
      on_sale: this.data.gtmOnSale,
      position: this.data.gtmTilePosition,
      price: this.data.gtmPrice,
      price: this.data.price,
      price_usd: this.data.priceUsd,
      product_id: this.data.productId,
      product_search_tag: this.data.productSearchTag,
      search_id: this.data.searchId,
      search_page_number: this.data.searchPageNumber,
      search_query: this.data.searchQuery,
      marketing_sku: this.data.gtmMarketingSku,
      url: this.data.url,
      variant: this.data.variant,
    }
  }
}

function initProductClicks() {
  var links = document.querySelectorAll('.jsCartImage');
  links.forEach((link) => {
    link.addEventListener('click', () => {
      const properties = new RudderstackProductDataBuilder(link).buildData();
      trackEvent('Product Clicked', properties);
    });
  })
}

export default initProductClicks;
