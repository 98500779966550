const mtrlModal = function () {
  var _stack = 0,
    _lastID = 0,
    _generateID = function () {
      _lastID += 1;
      return `materialize-modal-overlay-${_lastID}`;
    };

  var methods = {
    init(options) {
      var defaults = {
        opacity: 0.5,
        inDuration: 350,
        outDuration: 250,
        ready: undefined,
        complete: undefined,
        dismissible: true,
        startingTop: '4%',
        endingTop: '10%',
      };

      // Override defaults
      options = $.extend(defaults, options);

      return this.each(function () {
        var $modal = $(this);
        var modalId = $(this).attr('id') || `#${$(this).data('target')}`;

        var closeModal = function () {
          var overlayID = $modal.data('overlay-id');
          var $overlay = $(`#${overlayID}`);
          $modal.removeClass('open');

          // Enable scrolling
          $('body').css({
            overflow: '',
            width: '',
          });

          $modal.find('.mtrl-modal-close').off('click.close');
          $(document).off(`keyup.modal${overlayID}`);
          $modal.find('.jsFavoriteModalMessage').css({ display: 'none' });
          document.querySelectorAll('#favorite_design_id').forEach((field) => field.value = '');
          document.querySelectorAll('#favorite_product_id').forEach((field) => field.value = '');

          $overlay.animate({ opacity: 0 }, { duration: options.outDuration, queue: false, easing: 'easeOutQuart' });


          // Define Bottom Sheet animation
          var exitVelocityOptions = {
            duration: options.outDuration,
            queue: false,
            easing: 'swing',
            // Handle modal ready callback
            complete() {
              $(this).css({ display: 'none' });

              // Call complete callback
              if (typeof (options.complete) === 'function') {
                options.complete.call(this, $modal);
              }
              $overlay.remove();
              _stack -= 1;
            },
          };
          if ($modal.hasClass('bottom-sheet')) {
            $modal.animate({ bottom: '-100%', opacity: 0 }, exitVelocityOptions);
          } else if ($modal.hasClass('full-screen') || $modal.hasClass('top-sheet')) {
            $modal.animate({ top: '-100%', opacity: 0 }, exitVelocityOptions);
          } else if ($modal.hasClass('right-sheet')) {
            $modal.animate({ right: '-100%', opacity: 0 }, exitVelocityOptions);
          } else if ($modal.hasClass('left-sheet')) {
            $modal.animate({ left: '-100%', opacity: 0 }, exitVelocityOptions);
          } else {
            $modal.animate(
              { top: options.startingTop, opacity: 0 },
              exitVelocityOptions,
            );
          }
        };

        var openModal = function ($trigger) {
          var lStack;
          var $body = $('body');
          var oldWidth = $body.innerWidth();
          $body.css('overflow', 'hidden');
          $body.width(oldWidth);

          if ($modal.hasClass('open')) {
            return;
          }

          var overlayID = _generateID();
          var $overlay = $('<div class="mtrl-modal-overlay"></div>');
          _stack += 1;
          lStack = _stack;

          // Store a reference of the overlay
          $overlay.attr('id', overlayID).css('z-index', 1000 + lStack * 2);
          $modal.data('overlay-id', overlayID).css('z-index', 1000 + lStack * 2 + 1);
          $modal.addClass('open');

          $('body').append($overlay);

          if (options.dismissible) {
            $overlay.click(() => {
              closeModal();
            });
            // Return on ESC
            $(document).on(`keyup.modal${overlayID}`, (e) => {
              if (e.keyCode === 27) { // ESC key
                closeModal();
              }
            });
          }

          $modal.find('.mtrl-modal-close').on('click.close', () => {
            closeModal();
          });

          $overlay.css({ display: 'block', opacity: 0 });

          $modal.css({
            display: 'block',
            opacity: 0,
          });

          $overlay.animate({ opacity: options.opacity }, { duration: options.inDuration, queue: false, easing: 'swing' });
          $modal.data('associated-overlay', $overlay[0]);

          // Define Bottom Sheet animation
          var enterVelocityOptions = {
            duration: options.inDuration,
            easing: 'swing',
          };

          if ($modal.hasClass('bottom-sheet')) {
            $modal.animate({ bottom: '0', opacity: 1 }, enterVelocityOptions);
          } else if ($modal.hasClass('full-screen') || $modal.hasClass('top-sheet')) {
            $modal.animate({ top: '0', opacity: 1 }, enterVelocityOptions);
          } else if ($modal.hasClass('right-sheet')) {
            $modal.animate({ right: '0', opacity: 1 }, enterVelocityOptions);
          } else if ($modal.hasClass('left-sheet')) {
            $modal.animate({ left: '0', opacity: 1 }, enterVelocityOptions);
          } else {
            $modal.animate({ top: options.endingTop, opacity: 1 }, enterVelocityOptions);
          }
        };

        // Reset handlers
        $(document).off('click.modalTrigger', `a[href="#${modalId}"], [data-target="${modalId}"]`);
        $(this).off('openModal');
        $(this).off('closeModal');

        // Close Handlers
        $(document).on('click.modalTrigger', `a[href="#${modalId}"], [data-target="${modalId}"]`, function (e) {
          options.startingTop = ($(this).offset().top - $(window).scrollTop()) / 1.15;
          openModal($(this));
          e.preventDefault();
        }); // done set on click

        $(this).on('openModal', function () {
          modalId = $(this).attr('href') || `#${$(this).data('target')}`;
          openModal();
        });

        $(this).on('closeModal', () => {
          closeModal();
        });
      }); // done return
    },
    open() {
      $(this).trigger('openModal');
    },
    close() {
      $(this).trigger('closeModal');
    },
  };

  $.fn.modal = function (methodOrOptions) {
    if (methods[methodOrOptions]) {
      return methods[methodOrOptions].apply(this, Array.prototype.slice.call(arguments, 1));
    } if (typeof methodOrOptions === 'object' || !methodOrOptions) {
      // Default to "init"
      return methods.init.apply(this, arguments);
    }
    $.error(`Method ${methodOrOptions} does not exist on jQuery.modal`);
  };

  $(document).ready(() => {
    $('.mtrl-modal').modal();
    $( "div.modal-backdrop.in" ).remove(); // remove modal backdrop added by bootstrap
  });
};


const mtrlCollapsible = function () {
  $.fn.collapsible = function (options, methodParam) {
    var defaults = {
      accordion: undefined,
      onOpen: undefined,
      onClose: undefined,
    };

    var methodName = options;
    options = $.extend(defaults, options);


    return this.each(function () {
      var $this = $(this);

      var $panelHeaders = $(this).find('> li > .mtrl-collapsible-header');

      var collapsibleType = $this.data('collapsible');

      /** **************
      Helper Functions
      *************** */

      // Handle callbacks
      function execCallbacks(object) {
        if (object.hasClass('active')) {
          if (typeof (options.onOpen) === 'function') {
            options.onOpen.call(this, object.parent());
          }
        } else if (typeof (options.onClose) === 'function') {
          options.onClose.call(this, object.parent());
        }
      }


      // Expandable Open
      function expandableOpen(object) {
        if (object.hasClass('active')) {
          object.parent().addClass('active');
        } else {
          object.parent().removeClass('active');
        }
        const slideArgs = {
          duration: 300,
          easing: 'easeOutQuart',
          queue: false,
          complete() {
            $(this).css('height', '');
          },
        };
        const sibs = object.siblings('.mtrl-collapsible-body');
        sibs.stop(true, false);
        if (object.parent().hasClass('active')) {
          sibs.slideDown(slideArgs);
        } else {
          sibs.slideUp(slideArgs);
        }
      }

      // Accordion Open
      function accordionOpen(object) {
        $panelHeaders = $this.find('> li > .mtrl-collapsible-header');
        expandableOpen(object);

        $panelHeaders.not(object).removeClass('active').parent().removeClass('active');

        // Close previously open accordion elements.
        $panelHeaders.not(object).parent().children('.mtrl-collapsible-body').stop(true, false)
          .each(function () {
            if ($(this).is(':visible')) {
              $(this).slideUp({
                duration: 350,
                easing: 'easeOutQuart',
                queue: false,
                complete() {
                  $(this).css('height', '');
                  execCallbacks($(this).siblings('.mtrl-collapsible-header'));
                },
              });
            }
          });
      }

      // Open collapsible. object: .collapsible-header
      function collapsibleOpen(object, noToggle) {
        if (!noToggle) {
          object.toggleClass('active');
        }

        if (options.accordion || collapsibleType === 'accordion' || collapsibleType === undefined) { // Handle Accordion
          accordionOpen(object);
        } else { // Handle Expandables
          expandableOpen(object);
        }

        execCallbacks(object);
      }

      //  Get panel header from a children element
      //  @param  {Object} object Jquery object
      //  @return {Object} panel header object

      function getPanelHeader(object) {
        return object.closest('li > .mtrl-collapsible-header');
      }

      // Check if object is a child of panel header
      // @param  {Object}  object Jquery object
      // @return {Boolean} true if it is a child

      function isChildOfPanelHeader(object) {
        var panelHeader = getPanelHeader(object);

        return panelHeader.length > 0;
      }

      // Turn off any existing event handlers
      function removeEventHandlers() {
        $this.off('click.collapse', '> li > .mtrl-collapsible-header');
      }

      /** ***  End Helper Functions  *** **/


      // Methods
      if (methodName === 'destroy') {
        removeEventHandlers();
        return;
      } if (methodParam >= 0
          && methodParam < $panelHeaders.length) {
        var $currHeader = $panelHeaders.eq(methodParam);
        if ($currHeader.length
            && (methodName === 'open'
            || (methodName === 'close'
            && $currHeader.hasClass('active')))) {
          collapsibleOpen($currHeader);
        }
        return;
      }


      removeEventHandlers();


      // Add click handler to only direct collapsible header children
      $this.on('click.collapse', '> li > .mtrl-collapsible-header', (e) => {
        var element = $(e.target);

        if (isChildOfPanelHeader(element)) {
          element = getPanelHeader(element);
        }

        collapsibleOpen(element);
      });


      // Open first active
      if (options.accordion || collapsibleType === 'accordion' || collapsibleType === undefined) { // Handle Accordion
        collapsibleOpen($panelHeaders.filter('.active').first(), true);
      } else { // Handle Expandables
        $panelHeaders.filter('.active').each(function () {
          collapsibleOpen($(this), true);
        });
      }
    });
  };

  $(document).ready(() => {
    $('.mtrl-collapsible').collapsible();
    $( "div.modal-backdrop.in" ).remove(); // remove modal backdrop added by bootstrap
  });
};

const mtrlModals = { mtrlModal, mtrlCollapsible };

export default mtrlModals;
