import Glide from '@glidejs/glide'

function imagesMatchGallery(thumbImages, newImages) {
  return Array.from(thumbImages).every((thumbImg, idx) => {
    const thumbSrc = thumbImg.src.replace(/%20/g, ' ');
    return thumbSrc === newImages[idx].url;
  });
}

const PhotoGallery = {
  config: {
    thumbsClass: '.jsProductPreviewThumbs',
    sizerClass: '.m-sizer',
    sliderClass: '.jsProductImgGlide',
  },

  init(defaultImgIndex) {
    this.setSizer();
    const glider = this.initGlider(defaultImgIndex);

    return {
      swap_images: this.swap_images(glider),
    };
  },

  initGlider(index) {
    const { sliderClass } = this.config;
    const gliderElement = document.querySelector(sliderClass);
    if (!gliderElement) return null;

    const glideSettings = {
      type: 'carousel',
      autoplay: false,
      startAt: index,
      swipeThreshold: 1,
      dragThreshold: false,
      animationDuration: 200,
      animationTimingFunc: 'ease-in-out',
    }
    const glider = new Glide(sliderClass, glideSettings)
    glider.on('mount.after', this.bindCustomEvents(glider));
    glider.on('move.after', this.afterTransition(glider));
    glider.mount();
    return glider;
  },

  afterTransition(glider) {
    return () => {
      const { index } = glider;
      this.updateImageLabel(index);
      this.updateIndicatorDots(index);
    }
  },

  bindCustomEvents(glider) {
    return () => {
      this.enableArtistsImageDragAndDrop();
      this.bindSliderOnClickThumb(glider)();
      this.bindSliderOnClickControl(glider)();
    }
  },

  // Prevents GlideJs from hijacking the drag event
  enableArtistsImageDragAndDrop() {
    const imageElements = document.querySelectorAll('.jsProductImgGlide img');
    imageElements.forEach((element) => {
      element.addEventListener('dragstart', (event) => {
        event.stopPropagation();
      });
    });
  },

  bindSliderOnClickThumb(glider) {
    return () => {
      document.querySelectorAll('.jsProductImgGlideCtrls .jsCtrl').forEach((element) => {
        element.addEventListener('click', () => {
          const move = Array.from(element.parentNode.children).indexOf(element);
          glider.go(`=${move}`);
        });
      });
    }
  },

  // Arrow events are custom to avoid a bug where the events are triggered twice on mobile
  // They were triggered once on touching the button and a second time on releasing it.
  bindSliderOnClickControl(glider) {
    this.prevClickHandler = () => glider.go('<');
    this.nextClickHandler = () => glider.go('>');

    return () => {
      const prevCtrl = document.querySelector('.jsGalleryGlideArrowPrev');
      const nextCtrl = document.querySelector('.jsGalleryGlideArrowNext');

      prevCtrl.addEventListener('click', this.prevClickHandler);
      nextCtrl.addEventListener('click', this.nextClickHandler);
    }
  },

  unbindSliderOnClickControl() {
    const prevCtrl = document.querySelector('.jsGalleryGlideArrowPrev');
    const nextCtrl = document.querySelector('.jsGalleryGlideArrowNext');

    prevCtrl.removeEventListener('click', this.prevClickHandler);
    nextCtrl.removeEventListener('click', this.nextClickHandler);
  },

  createGlideSlideHtml(img, idx) {
    return `
      <li class="glide__slide m-product-preview__glider-slide" data-label="${img.label}" data-id="${idx}">
        <picture class="m-product-preview__glider-img">
          <img src="${img.url}">
        <picture>
      </li>
    `;
  },

  createThumbHtml(img, idx) {
    return `
      <a href="javascript:void(0);" class="jsProductPreviewThumb m-product-preview__thumb jsCtrl" data-id="${idx}">
        <img src="${img.url}" class="${img.type}"/>
      </a>
    `;
  },

  setSizer() {
    const sizerElement = document.querySelector(this.config.sizerClass);
    if (!sizerElement) return;

    sizerElement.classList.add('hidden');

    document.addEventListener('click', (event) => {
      if (event.target.closest('.jsShowSliderSizechart')) {
        sizerElement.classList.remove('hidden');
        TeePublic.ProductPage.Sizer.init();
      }
    });
  },

  swap_images(glider) {
    if (!glider) return null;

    let gliderAPI = glider;

    return (imgHash) => {
      const newImages = imgHash.images;
      const galleryElement = document.querySelector('.jsProductImgGlide ul');
      const thumbsElement = document.querySelector(this.config.thumbsClass);
      const currentSlide = gliderAPI.index;

      const thumbImages = thumbsElement.querySelectorAll('img');
      if (imagesMatchGallery(thumbImages, newImages)) return;

      gliderAPI.destroy();
      this.unbindSliderOnClickControl();
      galleryElement.innerHTML = '';
      thumbsElement.innerHTML = '';

      newImages.forEach((img, idx) => {
        const glideSlide = this.createGlideSlideHtml(img, idx);
        const thumbsHtml = this.createThumbHtml(img, idx);
        galleryElement.insertAdjacentHTML('beforeend', glideSlide);
        thumbsElement.insertAdjacentHTML('beforeend', thumbsHtml);
      });

      gliderAPI = this.initGlider(currentSlide);
    }
  },

  updateImageLabel(index) {
    const glideSlideElement = document.querySelector(`.glide__slide[data-id='${index}']`);
    const imgLabel = glideSlideElement ? glideSlideElement.getAttribute('data-label') : null;

    if (imgLabel) document.querySelector('.jsProductPreviewImgLabel').textContent = imgLabel;
  },

  updateIndicatorDots(index) {
    const controlElements = document.querySelectorAll('.jsProductImgGlideCtrls .jsCtrl');
    const activeControlElement = document.querySelector(`.jsCtrl[data-id='${index}']`);

    controlElements.forEach(elem => elem.classList.remove('on'));
    if (activeControlElement) activeControlElement.classList.add('on');
  },
};

export default PhotoGallery;
