const AutocompleteSelectors = [
  {
    searchSelector: 'jsAutoCompleteHeader',
    resultSelectors: {
      resultsList: 'jsAutoCompleteHeader_list',
      result: 'autoComplete_result',
      highlight: 'autoComplete_highlighted',
      selectedResult: 'autoComplete_selected'
    }
  },
  {
    searchSelector: 'jsAutoCompleteHome',
    resultSelectors: {
      resultsList: 'jsAutoCompleteHome_list',
      result: 'autoComplete_result',
      highlight: 'autoComplete_highlighted',
      selectedResult: 'autoComplete_selected'
    }
  },
];

export default AutocompleteSelectors;
