import CreateCampaigns from './create_campaigns'
import StoreSlugFieldManager from './store_slug_field_manager'

var createCampaigns = new CreateCampaigns();
const CreateCampaign = createCampaigns.createCampaign();

const ArtistTools = {
  CreateCampaign,
  StoreSlugFieldManager,
};

export default ArtistTools;
