import applePay from 'braintree-web/apple-pay';
import Fetchers from 'core/fetchers';

class ApplePay {
  constructor({
    braintreeClient,
    targets,
  }) {
    this.braintreeClient = braintreeClient;
    this.targets = targets;
    this.canApplePay = false;
    this.applePayInstance = null;
    this.session = null;
    this.selectedAddress = null;
    this.selectedShippingMethod = null;

    this.routes = {
      cart: '/cart/applepay',
      checkout: '/checkout/applepay',
    }
  }

  init() {
    this.braintreeClient.then((clientInstance) => {
      applePay.create({
        client: clientInstance,
      }, (applePayErr, applePayInstance) => {
        if (applePayErr) {
          console.error('Error creating applePayInstance:', applePayErr);
          return;
        }

        this.setupApplePay(applePayInstance)
      })
    })
  }

  setupApplePay(_applePayInstance) {
    this.applePayInstance = _applePayInstance;
    return this.fetchPaymentData();
  }

  showApplePay() {
    this.targets.forEach((ele) => {
      ele.classList.remove('hidden');
    })
  }

  setUpClickEvents() {
    this.targets.forEach((ele) => {
      if (ele.classList.contains('jsApplePayCheckout')) {
        ele.addEventListener('click', (event) => {
          event.preventDefault();

          this.initApplePaySession();
        })
      }
    })
  }

  fetchPaymentData() {
    const promise = window.ApplePaySession.canMakePaymentsWithActiveCard(this.applePayInstance.merchantIdentifier);
    promise.then((canMakePaymentsWithActiveCard) => {
      this.canApplePay = canMakePaymentsWithActiveCard
      if (this.canApplePay) {
        this.showApplePay();
        return Fetchers.fetchJSON(this.routes.cart)
          .then(Fetchers.parseResponse)
          .then(res => this.buildRequest(res).bind(this));
      }
      return false
    })
  }

  buildRequest(res) {
    const { data } = res
    const requestData = {
      total: {
        label: 'TeePublic.com',
        amount: data.total,
      },
      lineItems: data.line_items,
      shippingMethods: data.shipping_methods,
      'requiredShippingContactFields': [
        'postalAddress', 'name', 'phone', 'email',
      ],
      currencyCode: data.currencyCode,
    }
    this.paymentRequest = this.applePayInstance.createPaymentRequest(requestData);
    this.setUpClickEvents();
  }

  onValidateMerchant(event) {
    this.applePayInstance.performValidation({
      validationURL: event.validationURL,
      displayName: 'TeePublic.com',
    }, (validationErr, merchantSession) => {
      if (validationErr) {
        // You should show an error to the user, e.g. 'Apple Pay failed to load.'
        console.error('Error validating merchant:', validationErr);
        this.session.abort();
        return;
      }
      this.session.completeMerchantValidation(merchantSession);
    });
  }

  onShippingContactSelected(event, optionalParams) {
    this.selectedAddress = event.shippingContact
    const body = { applepay_address: this.selectedAddress, ...optionalParams }

    Fetchers.fetchJSON(this.routes.cart, 'POST', JSON.stringify(body))
      .then(Fetchers.parseResponse)
      .then((res) => {
        const { data } = res
        const changes = { type: 'final', label: 'Total', amount: data.total };
        const methods = data.shipping_methods;
        const newLineItems = data.line_items;
        if (methods.length > 0) {
          this.selectedShippingMethod = methods[0]
        }

        this.session.completeShippingContactSelection(ApplePaySession.STATUS_SUCCESS, methods, changes, newLineItems)
      })
  }

  onShippingMethodSelected(event, optionalParams) {
    this.selectedShippingMethod = event.shippingMethod;
    const body = {
      applepay_address: this.selectedAddress,
      applepay_shipping_method: this.selectedShippingMethod,
      ...optionalParams,
    }

    Fetchers.fetchJSON(this.routes.cart, 'POST', JSON.stringify(body))
      .then(Fetchers.parseResponse)
      .then((res) => {
        const { data } = res
        const changes = { type: 'final', label: 'Total', amount: data.total };
        const newLineItems = data.line_items;
        this.session.completeShippingMethodSelection(ApplePaySession.STATUS_SUCCESS, changes, newLineItems);
      })
  }

  onPaymentAuthorized(event, optionalParams) {
    this.applePayInstance.tokenize({
      token: event.payment.token,
    }, (tokenizeErr, payload) => {
      if (tokenizeErr) {
        console.error('Error tokenizing Apple Pay:', tokenizeErr);
        this.session.completePayment(ApplePaySession.STATUS_FAILURE);
      } else {
        const checkoutData = {
          'applepay_nonce': payload.nonce,
          'applepay_address': JSON.stringify(event.payment.shippingContact),
          'applepay_shipping': JSON.stringify(this.selectedShippingMethod),
          'checkout[payment_option]': 'ApplePay',
          ...optionalParams
        }
        TeePublic.Components.Utilities.instantForm(this.routes.checkout, checkoutData)
        this.session.completePayment(ApplePaySession.STATUS_SUCCESS);
      }
    });
  }


  initApplePaySession(optionalParams = {}) {
    this.session = new ApplePaySession(3, this.paymentRequest);

    // Session Callbacks
    this.session.onvalidatemerchant = e => this.onValidateMerchant(e);
    this.session.onshippingcontactselected = e => this.onShippingContactSelected(e, optionalParams);
    this.session.onshippingmethodselected = e => this.onShippingMethodSelected(e, optionalParams);
    this.session.onpaymentauthorized = e => this.onPaymentAuthorized(e, optionalParams);

    // Show ApplyPay interface.
    this.session.begin()
  }
}

export default ApplePay;
