import ScrollHelper from 'shared/helpers/scroll_helper'
import ApplicationController from './application_controller'

export default class HideOnScrollController extends ApplicationController {
  static values = { elementPosition: Number, hidingClass: String }

  init() {
    this.elementPosition = this.elementPositionValue;
    this.hidingClass = this.hidingClassValue;
    this.scrollUpStartPoint = 0;
    this.pixelsToTriggerUnhide = 150;

    this.scrollHelper = new ScrollHelper();
    this.scrollHelper.setCallback(this.handleScroll.bind(this));
  }

  elementPositionValueChanged() {
    this.init();
  }

  hidingClassValueChanged() {
    this.init();
  }

  handleScroll() {
    this.scrollData = this.scrollHelper.scrollData();
    if (this.scrollData.direction === 'down') {
      this.handleScrollDown();
    } else if (this.scrollData.direction === 'up') {
      this.handleScrollUp();
    }
  }

  handleScrollDown() {
    if (this.element.getBoundingClientRect().top <= this.elementPosition
      && this.scrollData.currentScroll > 0) {
      this.toggleElement('hide');
    }
  }

  handleScrollUp() {
    if (this.scrollData.prevDirection === 'down') {
      this.scrollUpStartPoint = this.scrollData.prevScroll;
    }
    const threshold = this.scrollUpStartPoint - this.pixelsToTriggerUnhide;
    if (this.scrollData.currentScroll < threshold || threshold < 0) this.toggleElement('show');
  }

  toggleElement(action) {
    if (action === 'hide') {
      this.element.classList.add(this.hidingClass);
    } else if (action === 'show') {
      this.element.classList.remove(this.hidingClass);
    }

    this.dispatchElementEvent(action);
  }

  dispatchElementEvent(action) {
    this.dispatch(`${action}Element`, { detail: { content: this.element } })
  }
}
