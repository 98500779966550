import EmailHelpers from 'shared/helpers/email_helpers';

function sizechartDataInit(json, showSizer) {
  if (showSizer) {
    TeePublic.ProductPage.Sizer.modelData = JSON.parse(json);
    $('.jsSizerSliderCtrl').removeClass('hidden');
  }
}

function sizechartInit() {
  TeePublic.ProductInfo.Garment.init_modal();
  TeePublic.ProductInfo.Sizechart.init_modal();
  TeePublic.ProductInfo.CanvasSizeChart.init_modal();
}

function initializeInfo(json, showSizer) {
  sizechartDataInit(json, showSizer);

  sizechartInit();

  TeePublic.Scroller.onInView($('.jsProductRegularPrice'));
  EmailHelpers.initEmailSubscribes();
}

export default initializeInfo;
