import ApplicationController from '../application_controller';

export default class CountdownController extends ApplicationController {
  static targets = ['days', 'hours', 'minutes', 'seconds'];

  static values = { endTime: String };

  connect() {
    this.endDate = new Date(this.endTimeValue);
    this.counts = {
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
    }
    this.difference = null;

    document.dispatchEvent(new CustomEvent('TP::countdown:connect'));
  }

  update(now) {
    this.difference = this.updateDifference(new Date(now));
    this.updateCounts();
    if (this.hasDaysTarget) {
      this.updateDaysLabel();
    }
  }

  updateDifference(timerDate) {
    const timeRemaining = this.endDate - timerDate;
    return timeRemaining <= 0 ? 0 : Math.round((timeRemaining) / 1000);
  }

  updateCounts() {
    this.counts.days = this.daysRemaining();
    this.counts.hours = this.hoursRemaining();
    this.counts.minutes = this.minutesRemaining();
    this.counts.seconds = this.secondsRemaining();

    if (this.hasDaysTarget) this.daysTarget.textContent = this.counts.days.toString();
    this.hoursTarget.textContent = this.counts.hours.toString().padStart(2, '0');
    this.minutesTarget.textContent = this.counts.minutes.toString().padStart(2, '0');
    this.secondsTarget.textContent = this.counts.seconds.toString().padStart(2, '0');
  }

  daysRemaining() {
    return Math.floor(this.difference / (60 * 60 * 24));
  }

  hoursRemaining() {
    if (this.hasDaysTarget) {
      return Math.floor((this.difference / (60 * 60)) % 24);
    } else {
      return Math.floor(this.difference / (60 * 60));
    }
  }

  minutesRemaining() {
    return Math.floor((this.difference / 60) % 60);
  }

  secondsRemaining() {
    return Math.floor(this.difference % 60);
  }

  updateDaysLabel() {
    const daysParent = this.daysTarget.parentElement;
    const daysLabel = this.daysTarget.nextElementSibling;
    if (!daysParent || !daysLabel) return;

    switch (this.counts.days) {
      case 1:
        daysLabel.textContent = 'day';
        break;
      case 0:
        daysParent.classList.contains('vc-count--design-tile-days') ? daysParent.remove() : daysLabel.textContent = 'days';
        break;
      default:
        daysLabel.textContent = 'days';
    }
  }
}
