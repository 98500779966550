import RudderstackController from './rudderstack_controller'

export default class CheckoutClickedController extends RudderstackController {
  static values = {
    cartId: String,
    location: String,
    products: Array,
    requestAction: String,
    requestController: String,
    state: String,
    currencyCode: String,
    discountUsd: Number,
    onSaleSavingsUsd: Number,
    productRevenueUsd: Number,
  }

  initialize() {
    this.checkoutType = null;
  }

  cartId() {
    let cartId;

    try {
      cartId = JSON.parse(this.cartIdValue).public_id;
    } catch {
      cartId = this.cartIdValue;
    }

    return cartId;
  }

  properties() {
    return {
      cart_id: this.cartId(),
      checkout_type: this.checkoutType,
      location: this.locationValue,
      products: this.productsValue,
      request_action: this.requestActionValue,
      request_controller: this.requestControllerValue,
      state: this.stateValue,
      currency_code: this.currencyCodeValue,
      discount_usd: this.discountUsdValue,
      on_sale_savings_usd: this.onSaleSavingsUsdValue,
      product_revenue_usd: this.productRevenueUsdValue,
    };
  }

  track(event) {
    try {
      this.checkoutType = event.currentTarget.dataset.rudderstackCheckoutType;
      const properties = this.properties();

      RudderstackController.track('Checkout Clicked', properties);
    } catch (error) {
      console.error('RS Checkout Error', error);
    }
  }
}
