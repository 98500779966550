class Flash {
  constructor(
    message,
    status = 'success'
    ) {
    this.status = status;
    this.flashText = document.createTextNode(message);
    this.flashEl = document.createElement('div');
  }

  init() {
    this.flashEl.appendChild(this.flashText);
    this.flashEl.classList.add(this.status, 'notice');
    document.querySelector('.flash').append(this.flashEl);

    setTimeout(() => {
      this.slideUp(this.flashEl, 'slow');
    }, 5000);
  }

  slideUp(element, duration) {
    const speed = duration === 'slow' ? 600 : 200;

    // NOTE: Style should be organized in CSS sustainability - AM 11/20
    const styleOverrides = {
      transitionProperty: 'height, margin, padding',
      transitionDuration: `${speed}ms`,
      boxSizing: 'border-box',
      height: `${element.offsetHeight}px`,

      height: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      overflow: 'hidden'
    }

    Object.assign(element.style, styleOverrides);
  }
}

export default Flash;
