import ApplicationController from './application_controller'

export default class AlsoAvailableProductsController extends ApplicationController {
  static targets = ['tilesContainer', 'loader']

  static values = {
    designId: String,
    canvasId: String,
  }

  initialize() {
    this.currentTab = null;
    this.currentCategoryName = null;
    this.currentTilesContainer = null;
    this.loader = null;
  }

  connect() {
    this.currentTab = this.element.querySelector('.m-tab-nav__item--active');
    this.loadTiles();
  }

  loadTiles() {
    this.showLoader();
    this.currentCategoryName = this.currentTab.dataset.categoryName;
    this.currentTilesContainer = this.findTilesContainer();

    const url = 'also-available-products';
    const category = this.currentCategoryName;
    const urlWithParams = `/designs/${this.designIdValue}/canvas/${this.canvasIdValue}/${url}/${category}`;
    const opts = { method: 'GET' }

    fetch(urlWithParams, opts)
      .then(response => response.text())
      .then((data) => {
        this.hideLoader();
        this.currentTilesContainer.innerHTML = data;
        this.updateTabContentLoadedState();
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  updateTabContentLoadedState() {
    this.currentTab.dataset.tilesLoaded = true;
  }

  hideLoader() {
    this.loaderTarget.classList.add('hidden');
  }

  showLoader() {
    this.loaderTarget.classList.remove('hidden');
  }

  findTilesContainer() {
    return this.tilesContainerTargets.find((tilesContainer) => {
      const match = tilesContainer.dataset.categoryContent === this.currentCategoryName;
      return match;
    });
  }

  changeCategory(event) {
    this.currentTab = event.currentTarget;
    if (this.currentTab.dataset.tilesLoaded === 'true') return;

    this.loadTiles();
  }
}
