class FormElementValidator {
  constructor({
    formElement,
  } = {}) {
    this.formElement = formElement;
  }


  validValue() {
    return this.formElement.checkValidity();
  }

  reportMessage() {
    this.formElement.setCustomValidity(this.validationMessage());
    this.formElement.reportValidity();

    this.onChange();
  }

  onChange() {
    this.formElement.addEventListener('input', (e) => {
      e.preventDefault()
      this.formElement.setCustomValidity('');
      this.formElement.reportValidity();
    })
  }

  validationMessage() {
    return `Please enter the ${this.customMessage().replace(/_|-/g, ' ')}.`
  }

  customMessage() {
    const msg = this.formElement.dataset.validityMsg;
    if (msg) { return msg }
    return this.formElement.id;
  }
}

export default FormElementValidator;
