function toggleAnimatedPlaceholder(state) {
  const animateWrapper = document.querySelector('.m-header__search-field-placeholder-wrapper');
  if (!animateWrapper) return;

  const clearSearchButton = document.querySelector('.jsClearHeaderSearch');
  switch (state) {
    case 'hide':
      animateWrapper.classList.add('hide');
      // when wrapper is hidden, show clear button
      clearSearchButton.classList.remove('hide');
      break;
    case 'show':
      animateWrapper.classList.remove('hide');
      // when wrapper is active, hide clear button
      clearSearchButton.classList.add('hide');
      break;
    default:
    // do nothing
  }
}

export default toggleAnimatedPlaceholder;
