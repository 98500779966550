import ApplicationController from './application_controller'

export default class ToggleDatasetController extends ApplicationController {
  static targets = ['button', 'cell']

  toggleDataset(dataset) {
    this.cellTargets.forEach((cell) => {
      const updatedValue = cell.dataset[`toggleDataset${dataset}Value`]

      cell.innerText = updatedValue
    })
  }

  toggleButtonActiveClass() {
    this.buttonTargets.forEach((button) => {
      const currentTarget = event.currentTarget
      const selectedClass = currentTarget.dataset.toggleDatasetSelectedClass
      const currentTargetDataset = currentTarget.dataset.toggleDatasetSetValue

      if (button === currentTarget && !button.classList.contains(selectedClass)) {
        button.classList.add(selectedClass)
        this.toggleDataset(currentTargetDataset)
      } else if (button !== currentTarget && button.classList.contains(selectedClass)) {
        button.classList.remove(selectedClass)
      }
    })
  }
}
