import Binders from './binders';
import Loaders from './loaders';
import Renderers from './renderers';
import Updaters from './updaters';
import Utilities from './utilities';

const Initializers = {};

Initializers.initCampaign = () => {
  const designCampaign = new TeePublic.ProductPage.Designs.Campaigns();
  designCampaign.init();
};

Initializers.initExchangePage = () => {
  Object.keys(TeePublic.Exchange.Data).forEach((oliId) => {
    Binders.bindExchangeEvents(oliId);
    Renderers.renderExchangeOptionsOnInit(oliId);
  });
};

Initializers.initPage = (opts, defaultImgIndex) => {
  const { ProductOptions } = TeePublic;
  const {
    paths,
    similar_products_enabled,
    design_id,
    store_id,
  } = opts;
  const { similar_products_path } = paths;

  const products = Utilities.sanitizeProducts(ProductOptions, true);
  const fragmentProduct = Utilities.getFragmentIdProduct(products);
  const gallery = TeePublic.ProductPage.PhotoGallery.init(defaultImgIndex);
  Renderers.renderOptionsOnInit(products);
  Renderers.renderSeasonalColorTipOnInit(products);

  if (fragmentProduct) Updaters.updatePdpProductData(gallery, fragmentProduct.id);
  Binders.bindProductPageEvents(defaultImgIndex, store_id, design_id, gallery);

  if (similar_products_enabled) Loaders.loadSimilarProductImgs(similar_products_path);
};

export default Initializers;
