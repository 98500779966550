import { DataLayer } from 'core/globals';
import ApplicationController from '../application_controller';

class Impressions extends ApplicationController {
  static targets = ['impression'];

  getElementDataset(element) {
    const {
      gtmName: name,
      gtmResult: tag,
      gtmPosition: position,
      gtmCanvas: productCanvas,
      gtmLinkable: linkable,
      gtmWidgetImpressionId: widgetImpressionId,
    } = element.dataset;
    return {
      gtmName: name,
      gtmResult: tag,
      gtmPosition: parseInt(position, 10),
      gtmCanvas: productCanvas,
      ...linkable && { gtmLinkable: linkable },
      ...widgetImpressionId && { gtmWidgetImpressionId: widgetImpressionId },
    };
  }

  getImpression(target) {
    const elementValues = this.getElementDataset(target);
    const name = `${elementValues.gtmName}Term`;
    const option = {
      [name]: elementValues.gtmResult,
      position: elementValues.gtmPosition,
      productCanvas: elementValues.gtmCanvas,
      ...elementValues.gtmLinkable && { linkable: elementValues.gtmLinkable },
    };
    return option;
  }

  getEvent(eventType, elementValues, widgetEvent) {
    const widgetType = `widget${eventType}`;
    const eventName = `${elementValues.gtmName}${eventType}`;
    const eventInformation = {
      event: eventName,
      [eventName]: {
        ...elementValues.gtmWidgetImpressionId && { widgetImpressionId: elementValues.gtmWidgetImpressionId },
        [widgetType]: widgetEvent,
      },
    };
    return eventInformation;
  }

  pushInteraction(event) {
    const widgetInteraction = this.getImpression(event.target);
    const elementValues = this.getElementDataset(event.target);
    const gtmInteractionEvent = this.getEvent('Interaction', elementValues, widgetInteraction);
    DataLayer.push(gtmInteractionEvent);
  }

  pushImpression(target, widgetOptions) {
    const elementValues = this.getElementDataset(target);
    const gtmImpressionEvent = this.getEvent('Impression', elementValues, widgetOptions);
    DataLayer.push(gtmImpressionEvent);
  }

  connect() {
    const widgetOptions = [];
    if (this.hasImpressionTargets) {
      this.impressionTargets.forEach((target) => {
        const option = this.getImpression(target);
        widgetOptions.push(option);
      })
      this.pushImpression(this.impressionTarget, widgetOptions);
    }
  }
}

export default Impressions;
