import FormElementValidator from './form_element_validator';
import CsrfToken from './csrfToken';

function setValidation(formElement) {
  const formElementValidator = new FormElementValidator({ formElement })
  return formElementValidator;
}

function invalidRequiredFields(requiredFields) {
  const arr = [];
  requiredFields.forEach((formElement) => {
    const field = setValidation(formElement);
    if (!field.validValue()) { arr.push(field) }
  })
  return arr;
}

function handleListener(validator, callback) {
  validator.addEventListener('click', (e) => {
    const requiredFields = document.querySelectorAll('.jsRequireValidation');
    const invalidFields = invalidRequiredFields(requiredFields);

    if (invalidFields.length === 0) {
      callback(e);
    } else {
      invalidFields.forEach(field => field.reportMessage())
    }
  })
}

function initializeValidator(callback) {
  const validator = document.querySelector('.jsFormElementValidator')
  handleListener(validator, callback);
}

function initCsrfToken() {
  const csrfToken = new CsrfToken();
  csrfToken.init();
}

const Validators = {
  initializeValidator,
  initCsrfToken,
}

export default Validators;
