import Fetchers from 'core/fetchers';
import Posters from 'shared/helpers/ProductHelper/posters';
import ApplicationController from './application_controller';

export default class CrossSellController extends ApplicationController {
  static targets = ['itemsContainer'];

  static values = {
    targetedItemClass: String,
    canvas: String,
  }

  connect() {
    this.fetchCrossSell();
  }

  fetchCrossSell() {
    const url = '/cross-sell-products';
    const body = {
      items: this.getItemsDesignAndCanvas(),
      canvas: this.canvasValue,
    }

    Fetchers.fetchJSON(url, 'POST', JSON.stringify(body))
      .then(response => response.json())
      .then((data) => {
        if (data.status === 'ok') {
          this.renderCrossSell(data);
        }
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  getItemsDesignAndCanvas() {
    const designData = [];

    document.querySelectorAll(this.targetedItemClassValue).forEach((element) => {
      designData.push({ design_id: element.dataset.designId, canvas: element.dataset.canvas });
    });

    return designData;
  }

  renderCrossSell(data) {
    if (!data.designs) return;
    this.element.innerHTML = data.core;
    this.itemsContainerTarget.innerHTML = data.designs;
    this.bindQuickAddToCartEvent();
  }

  addToCartOptions(event) {
    return {
      product_id: event.currentTarget.dataset.productId,
      design_id: event.currentTarget.dataset.design,
      quantity: 1,
      store_id: null,
      campaign_id: null,
    };
  }

  bindQuickAddToCartEvent() {
    document.querySelectorAll('.jsQuickAddToCart').forEach((button) => {
      button.addEventListener('click', (event) => {
        const postOptions = this.addToCartOptions(event);
        Posters.addToCart(postOptions);
      });
    });
  }
}
