import DesignCopier from './design_copier';
import FollowButton from './follow_button';

const designCopier = function () {
  const newDesignCopier = new DesignCopier();
  newDesignCopier.init();
}

function initFollowButtons() {
  const containers = document.querySelectorAll('.jsFollowContainer');
  containers.forEach((container) => {
    const { followPath } = container.dataset;
    new FollowButton(container, followPath).initButton();
  });
}


const Stores = { designCopier, initFollowButtons };

export default Stores;
