class InstantForm {
  // THIS CLASS WILL CREATE A FORM WITH INPUTS FOR EACH PARAM, AND THEN SUMBIT
  // THE FORM. THIS IS NOT A REPLACEMENT FOR FORMS OR FOR AJAX POST REQUESTS
  // THIS SHOULD BE USED FOR REDIRECTING TO ANOTHER PAGE WITH POST.

  constructor(
    url,
    params = {},
  ) {
    this.url = url
    this.params = params;
    this.form = document.createElement('form');
  }

  send() {
    this.buildForm();
    this.form.submit();
  }

  buildForm() {
    this.form.action = this.url;
    this.form.method = 'POST';
    this.buildFormInputs();
    document.body.appendChild(this.form);
  }

  buildFormInputs() {
    Object.keys(this.params).forEach((key) => {
      const i = document.createElement('input');
      i.type = 'hidden';
      i.name = key;
      i.value = this.params[key];
      this.form.appendChild(i);
    });
  }
}

export default InstantForm;
