import Fetchers from 'core/fetchers';
import ModifyItem from './modify_item';

class ModifyCart {
  constructor({
    cartContent,
  } = {}) {
    this.cartContent = cartContent;
    this.cartItemElements = cartContent.querySelectorAll('.jsHeadCartItem');
  }

  mount() {
    this.cartItemElements.forEach((item) => {
      this.modifyItem = new ModifyItem({ item, onRemove: this.fetch.bind(this) });
      this.modifyItem.mount();
    });
  }

  fetch(path) {
    return Fetchers.fetchJSON(path)
      .then(Fetchers.parseResponse)
      .then(this.handleResponse.bind(this))
  }

  initializePaypal() {
    TeePublic.Cart.CheckoutPaypal.init('responsive', `#${this.cartContent.querySelector('.jsPaypalBtn').id}`);
  }

  updateBadgeNotification() {
    const contentBlocks = this.cartContent.querySelector('.tray-content__blocks');
    const cartItemCount = contentBlocks ? contentBlocks.dataset.cartQuantityTotal : 0;
    const badges = document.querySelectorAll('.jsNotificationBadge');

    badges.forEach((badge) => {
      const badgeNotification = badge.notification;
      badgeNotification.countValue = cartItemCount;
      badgeNotification.updateCount();
    })
  }

  handleResponse(res) {
    if (window.location.pathname === '/cart') {
      window.location.reload();
    } else {
      this.cartContent.innerHTML = res.data.html
      this.updateBadgeNotification();
      this.cartItemElements = this.cartContent.querySelectorAll('.jsHeadCartItem');
      this.mount();
      if (this.cartItemElements.length > 0) { this.initializePaypal() }
    }
  }
}

export default ModifyCart;
