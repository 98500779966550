class ModifyItem {
  constructor({
    item,
    onRemove,
  } = {}) {
    this.item = item;
    this.remove = item.querySelector('.jsRemoveItemFromCart');
    this.onRemove = onRemove;
    Object.assign(this, this.remove.dataset);
  }

  mount() {
    this.remove.addEventListener(('click'), (e) => {
      e.stopPropagation()
      this.removeItem()
    },
    { once: true, capture: false });
  }

  removeItem() {
    this.item.remove();
    this.onRemove(this.path);
  }
}

export default ModifyItem;
