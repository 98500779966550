import Fetchers from 'core/fetchers';
import ApplicationController from '../application_controller'

export default class OrderSummaryController extends ApplicationController {
  static targets = ['pricing', 'freeShippingBanner']

  static values = {
    url: String,
  }

  updateOrderSummaryComponent() {
    Fetchers.fetchJSON(this.urlValue, 'GET', null)
      .then(res => res.json())
      .then((data) => {
        const { pricing, free_shipping_banner } = data;

        if (this.hasFreeShippingBannerTarget) {
          this.freeShippingBannerTarget.outerHTML = free_shipping_banner;
        }

        this.pricingTarget.outerHTML = pricing;
      })
      .catch(error => console.log(error));
  }
}
