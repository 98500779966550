import { DataLayer } from 'core/globals'; // This is a circular import and should be rethought

class RelatedTag {
  static configureEventTracking(relatedTagElements) {
    const [
      { dataset: { gtmWidgetImpressionId: widgetImpressionId } = {} } = {}
    ] = relatedTagElements || [];
    const widgetOptions = [];

    for (let i = 0; i < relatedTagElements.length; i += 1) {
      const relatedTagElement = relatedTagElements[i];

      // Build widgetOptions
      const {
        gtmResult: relatedTagTerm,
        gtmPosition: position,
        gtmBucket: widgetVariation,
      } = relatedTagElement.dataset;

      widgetOptions.push({
        position: parseInt(position, 10),
        relatedTagTerm,
        widgetVariation,
      });

      // Add listener to push interaction event for all related tag elements
      let listenerElement = relatedTagElement;
      let event = 'click';

      listenerElement.addEventListener(event, () => {
        DataLayer.push({
          event: 'relatedTagInteraction',
          relatedTagInteraction: {
            widgetImpressionId,
            widgetInteraction: {
              relatedTagTerm,
              widgetVariation,
              position: parseInt(position, 10),
            }
          }
        });
      });
    }

    // Push impression event
    DataLayer.push({
      event: 'relatedTagImpression',
      relatedTagImpression: {
        widgetImpressionId,
        widgetOptions,
      }
    });
  }

  constructor({ relatedTagClass = 'gtmRelatedTagLink' } = {}) {
    const relatedTagElements = document.getElementsByClassName(relatedTagClass);
    if (relatedTagElements.length) {
      RelatedTag.configureEventTracking(relatedTagElements);
    }
  }
}

export default RelatedTag;
