import ApplicationController from '../application_controller'
import Fetchers from "core/fetchers";

export default class CartController extends ApplicationController {
  static targets = ["cartContent", "cartItem", "cartQuantity", "paypalBtn"];
  static values = {
    quantity: { type: Number, default: 0 },
  }

  connect() {
    this.initializePaypal();
  }

  fetch(path) {
    return Fetchers.fetchJSON(path)
      .then(Fetchers.parseResponse)
      .then((res) => {
        if (window.location.pathname === "/cart") {
          window.location.reload();
        } else {
          const htmlString = res.data.html;
          let tempDiv = document.createElement('div');
          tempDiv.innerHTML = htmlString;
          const trayContent = tempDiv.querySelector('.drawer__wrapper');
          this.cartContentTarget.querySelector('.drawer__wrapper').innerHTML = trayContent.innerHTML;
          this.quantityValue = this.cartQuantityTarget.dataset.cartQuantityTotal;
          this.updateBadgeNotification();
          if (this.cartItemTargets.length > 0) {
            this.initializePaypal();
          }
        }
      });
  }

  initializePaypal() {
    if (this.hasPaypalBtnTarget) {
      TeePublic.Cart.CheckoutPaypal.init("responsive", `#${this.paypalBtnTarget.id}`);
    }
  }

  updateBadgeNotification() {
    const badges = document.querySelectorAll('.jsNotificationBadge');

    badges.forEach((badge) => {
      const badgeNotification = badge.notification;
      badgeNotification.countValue = this.quantityValue;
      badgeNotification.updateCount();
    })
  }

  removeItem(e) {
    e.preventDefault();
    let item = e.currentTarget.closest('.jsHeadCartItem')
    let path = e.currentTarget.dataset.path
    item.remove();
    this.fetch(path);
  }
}
