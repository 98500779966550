class Tooltip {
  constructor(
    el,
    text,
    ) {
    this.el = el;
    this.text = text;
  }

  init() {
    let tooltip = document.createElement('div');
    tooltip.classList.add('tool-tiptext');
    tooltip.textContent = this.text;

    this.el.classList.add('tool-tip');
    this.el.appendChild(tooltip)
  }
}

export default Tooltip;
