class CsrfToken {
  constructor() {
    this.token = Cookies.get('csrf_token');
    this.document = document;
  }

  init() {
    this.updateOrCreateMetaTag('csrf-param', 'authenticity_token');
    this.updateOrCreateMetaTag('csrf-token', this.token);
    this.authorizeForms();
  }

  updateOrCreateMetaTag(name, content) {
    let tag = this.document.querySelector(`meta[name="${name}"]`);
    if (tag) {
      tag.setAttribute('content', content);
    } else {
      tag = this.document.createElement('meta')
      tag.name = name
      tag.content = content
      this.document.head.appendChild(tag);
    }
    return tag
  }

  authorizeForms() {
    const tokenInputs = this.document.querySelectorAll('input[name=authenticity_token]')
    tokenInputs.forEach(() => {
      const token = this.document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      this.value = token;
    })
  }
}

export default CsrfToken;
