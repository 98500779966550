import ApplicationController from '../application_controller';
import ValidationMessageGenerator from './validity/message_generator';
import Fetchers from 'core/fetchers';

const shippingFieldNamesToDataset = {
  ship_firstname: 'fieldFirstName',
  ship_lastname: 'fieldLastName',
  ship_address1: 'fieldLine1',
  ship_address2: 'fieldLine2',
  ship_country_id: 'fieldCountryId',
  ship_zip: 'fieldPostalCode',
  ship_city: 'fieldCity',
  ship_state_code: 'fieldRegion',
};

export default class extends ApplicationController {
  static targets = [
    'billFirstName', 'billLastName', 'billCity', 'billCountry', 'billState',
    'billStateWrapper', 'billZipCode', 'billZipCodeWrapper',
    'collapsedFormElement', 'expandedFormElement', 'copyShippingInfo', 'form',
    'paymentOption', 'placeOrderButton', 'savedAddress', 'sidebarPricing',
    'shipCity', 'shipCityWrapper', 'shipCountry', 'shipCountryWrapper',
    'shipState', 'shipStateWrapper', 'shippingMethod', 'shipZipCode',
    'shipZipCodeWrapper', 'updating', 'shipAddress1', 'shipAddress2',
    'shipFirstName', 'shipLastName'
  ];
  static values = { url: String, location: String };
  static classes = ['hidden', 'updating'];
  static outlets = ['checkout--cart', 'checkout--validity--base', 'inputs--radio'];

  connect() {
    this.validationMessageGenerator = new ValidationMessageGenerator();
    this.initializeCustomAddress();
    this.copyBillingInfoFromShipping();
  }

  update(event) {
    const toRefresh = this.toRefresh(event);
    if (!toRefresh.length) return;

    this.openLoadingOverlay()
    this.fetchData(this.urlValue, (data) => {
      const shouldUpdate = (dataKeys) => this.shouldUpdate(dataKeys, toRefresh);
      if (shouldUpdate(['sidebar'])) this.updateSidebar(data);
      if (shouldUpdate(['bill_zip_code_field'])) this.updateBillZipCode(data);
      if (shouldUpdate(['ship_city_field'])) this.updateShipCity(data);
      if (shouldUpdate(['ship_state_field'])) this.updateShipState(data);
      if (shouldUpdate(['ship_zip_code_field'])) this.updateShipZipCode(data);
      if (shouldUpdate(['ship_country_field'])) this.updateShipCountry(data);
      if (shouldUpdate(['shipping_method_section'])) this.updateShippingMethod(data);
      this.closeLoadingOverlay();
      this.writeValidationMessages();
    }, toRefresh, 'PUT');
  }

  writeValidationMessages() {
    this.checkoutValidityBaseOutlets.forEach(outlet => outlet.handleKeyup(false));
  }

  updateFromShipZip(event) {
    if (this.shouldUpdateFromShipZip(event)) {
      this.shipCityTarget.value = '';
      this.shipStateTarget.value = '';
      this.update(event);
    }
  }

  shouldUpdateFromShipZip(event) {
    const zipCode = event.target.value;
    return zipCode.length >= 5
      && this.shipsToUsa()
      && this.validationMessageGenerator.isValidUSZipCode(zipCode);
  }

  fetchData(url, callback, toRefresh, method = 'GET', body = {}) {
    Fetchers.fetchJSON(
      url,
      method = method,
      body = JSON.stringify({
        ...this.serializeFormData(),
        ...(toRefresh.includes('sidebar') && { cart: { collapsed: this.checkoutCartOutlet.collapsed() } }),
        ...(toRefresh.includes('ship_state_field') && { shipStateTag: this.shipStateTarget.tagName }),
        toRefresh,
      })
    )
      .then((response) => response.json())
      .then((data) => callback(data))
      .catch((error) => {
        this.handleError(error);
        this.checkoutCheckoutOutlet.element.classList.remove(this.updatingClass);
      })
  }

  sanitizeAddress2() {
    // AVALARA DOES NOT LIKE AN ADDRESS2 THAT IS JUST NUMBERS OR NUMBERS AND
    // A SINGLE LETTER. ADDING A POUND SIGN LETS US AVOID THIS ISSUE.
    const regex = /^[a-zA-Z]?([0-9])*[a-zA-Z]?$/;
    const address2WithoutSpaces = this.shipAddress2Target.value.replace(/\s/g, '');

    if (address2WithoutSpaces === '') { return; }

    if (address2WithoutSpaces.match(regex)) {
      this.shipAddress2Target.value = `#${address2WithoutSpaces}`
    }
  }

  initializeCustomAddress() {
    this.customAddress = {
      shipFirstName: '', shipLastName: '', shipAddress1: '',
      shipAddress2: '', shipCity: '', shipZipCode: '', shipState: ''
    };
  }

  copyBillingInfoFromShipping() {
    this.billFirstNameTarget.value = this.shipFirstNameTarget.value;
    this.billLastNameTarget.value = this.shipLastNameTarget.value;
    this.billCountryTarget.value = this.shipCountryTarget.value;
    if (this.hasBillZipCodeTarget) this.billZipCodeTarget.value = this.shipZipCodeTarget.value;
  }

  clearBillingInfo() {
    this.billFirstNameTarget.value = '';
    this.billLastNameTarget.value = '';
    this.billCountryTarget.value = '';
    if (this.hasBillZipCodeTarget) this.billZipCodeTarget.value = '';
  }

  openLoadingOverlay() {
    this.updatingTarget.classList.add(this.updatingClass);
  }

  closeLoadingOverlay() {
    this.updatingTarget.classList.remove(this.updatingClass);
  }

  serializeFormData() {
    const formData = {};
    this.formTarget.querySelectorAll('input,select').forEach((field) => {
      if (field.type === 'radio' && !field.checked) return;

      const nestedFieldNames = field.name.match(/(\w+)\[(\w+)\]/);
      if (nestedFieldNames) {
        const parentName = nestedFieldNames[1];
        const childName = nestedFieldNames[2];
        if (!formData[parentName]) formData[parentName] = {};
        formData[parentName][childName] = this.fieldValue(childName, field);
      } else {
        formData[field.name] = field.value;
      }
    });
    return formData;
  }

  fieldValue(childName, field) {
    if (this.isShippingField(childName) && this.hasSavedAddressSelected()) {
      return this.savedAddressTarget.dataset[shippingFieldNamesToDataset[childName]];
    }

    return field.value;
  }

  isShippingField(fieldName) {
    return Object.keys(shippingFieldNamesToDataset).includes(fieldName);
  }

  billsToUsa() {
    return this.billCountryName() === 'United States';
  }

  shipsToUsa() {
    return this.shipCountryName() === 'United States';
  }

  billCountryName() {
    return this.selectedOptionName(this.billCountryTarget);
  }

  shipCountryName() {
    if (this.hasSavedAddressSelected()) return this.savedAddressTarget.dataset.countryName;

    return this.selectedOptionName(this.shipCountryTarget);
  }

  shipCountryId() {
    return this.shipCountryTarget.value;
  }

  shipCountryData() {
    const data = this.shipCountryTarget.dataset;

    return {
      requiresState: data.requiresState,
      predefinedStates: data.predefinedStates,
      stateLabel: data.stateLabel,
      isoCode: data.isoCode,
      zipLabel: data.zipLabel
    };
  }

  addressVerifiable() {
    return this.shipCountryTarget.dataset.addressVerifiable === 'true';
  }

  billingSameAsShipping() {
    return this.checkedRadio(this.copyShippingInfoTargets).value === '1';
  }

  paymentType() {
    return this.checkedRadio(this.paymentOptionTargets)?.value;
  }

  checkedRadio(radios) {
    return radios.find(function (radio) { return radio.checked; });
  }

  hasSavedAddressSelected() {
    if (this.hasSavedAddressTarget) return this.savedAddressTarget.checked;
  }

  hasVerifiedSavedAddressSelected() {
    return this.hasSavedAddressSelected() && this.savedAddressTarget.dataset.verified === 'true'
  }

  verifiedSavedAddress() {
    if (!this.hasVerifiedSavedAddressSelected()) { return '' }

    return this.savedAddressTarget.dataset.savedAddress;
  }

  selectedOptionName(target) {
    for (let option of target.options) {
      if (option.value === target.value) {
        return option.innerText;
      }
    }
  }

  toRefresh(event) {
    if (!event.target.value) return [];

    let toRefresh = ['sidebar'];
    switch (event.target) {
      case this.shipZipCodeTarget:
        if (this.shipsToUsa()) {
          toRefresh = [
            ...toRefresh,
            'ship_city_field',
            'ship_state_field',
            'shipping_method_section'
          ];
        } else {
          toRefresh = [];
        }
        break;
      case this.shipCountryTarget:
        toRefresh = [
          ...toRefresh,
          'ship_zip_code_field',
          'ship_state_field',
          'ship_country_field',
          'shipping_method_section',
        ];
        break;
      case this.billCountryTarget:
        toRefresh = ['bill_zip_code_field'];
        break;
    }
    return toRefresh;
  }

  updateSidebar(data) {
    const { cart, pricing } = data.sidebar;
    this.checkoutCartOutlet.element.outerHTML = cart;
    this.sidebarPricingTarget.outerHTML = pricing;
  }

  updateShipCity(data) {
    this.shipCityWrapperTarget.outerHTML = data.ship_city_field;
    this.shipCityWrapperTarget.classList.add(this.checkoutValidityBaseOutlet.successClass);
  }

  updateShipState(data) {
    this.shipStateWrapperTarget.outerHTML = data.ship_state_field;
  }

  updateShipZipCode(data) {
    this.shipZipCodeWrapperTarget.outerHTML = data.ship_zip_code_field;
  }

  updateBillZipCode(data) {
    this.billZipCodeWrapperTarget.outerHTML = data.bill_zip_code_field;
  }

  updateShipCountry(data) {
    this.shipCountryWrapperTarget.outerHTML = data.ship_country_field;
  }

  updateShippingMethod(data) {
    this.shippingMethodTarget.outerHTML = data.shipping_method_section;
  }

  changeBillZipVisibility(event) {
    if (!this.hasBillZipCodeWrapperTarget) return;

    this.update(event);

    if (this.billsToUsa()) {
      this.billZipCodeWrapperTarget.classList.remove(this.hiddenClass);
      return this.writeValidationMessages();
    }

    this.billZipCodeWrapperTarget.classList.add(this.hiddenClass);
    return this.writeValidationMessages();
  }

  shouldUpdate(dataKeys, toRefresh) {
    return dataKeys.every((dataKey) => toRefresh.includes(dataKey));
  }

  triggerShipCountryChange() {
    // Done to trigger an update of the sidebar and oother fields
    this.shipCountryTarget.dispatchEvent(new Event('change'))
  }

  loadSavedAddress() {
    this.storeCustomAddress()
    this.shipFirstNameTarget.value = this.savedAddressTarget.dataset.fieldFirstName;
    this.shipLastNameTarget.value = this.savedAddressTarget.dataset.fieldLastName;
    this.shipAddress1Target.value = this.savedAddressTarget.dataset.fieldLine1;
    this.shipAddress2Target.value = this.savedAddressTarget.dataset.fieldLine2;
    this.shipCityTarget.value = this.savedAddressTarget.dataset.fieldCity;
    this.shipStateTarget.value = this.savedAddressTarget.dataset.fieldRegion;
    this.shipZipCodeTarget.value = this.savedAddressTarget.dataset.fieldPostalCode;
    this.shipCountryTarget.value = this.savedAddressTarget.dataset.fieldCountryId;
    this.handleSharedAddressFieldChange();
    // Will run into issues where ship state cannot be entered when the input changes from a select to a text field
    // I don't think it's worth dealing with when this feature is barely worth dealing with.
    this.triggerShipCountryChange();
  }

  storeCustomAddress() {
    this.customAddress = {
      shipFirstName: this.shipFirstNameTarget.value,
      shipLastName: this.shipLastNameTarget.value,
      shipAddress1: this.shipAddress1Target.value,
      shipAddress2: this.shipAddress2Target.value,
      shipCity: this.shipCityTarget.value,
      shipState: this.shipStateTarget.value,
      shipZipCode: this.shipZipCodeTarget.value,
      shipCountry: this.shipCountryTarget.value
    }
  }

  loadCustomAddress() {
    this.shipFirstNameTarget.value = this.customAddress.shipFirstName;
    this.shipLastNameTarget.value = this.customAddress.shipLastName;
    this.shipAddress1Target.value = this.customAddress.shipAddress1;
    this.shipAddress2Target.value = this.customAddress.shipAddress2;
    this.shipCityTarget.value = this.customAddress.shipCity;
    this.shipStateTarget.value = this.customAddress.shipState;
    this.shipZipCodeTarget.value = this.customAddress.shipZipCode;
    if (this.customAddress.shipCountry) {
      this.shipCountryTarget.value = this.customAddress.shipCountry;
    }
    this.handleSharedAddressFieldChange();
    this.triggerShipCountryChange();
  }

  updateSameAsShippingRadioText() {
    const line1 = `${this.shipFirstNameTarget.value} ${this.shipLastNameTarget.value}`;
    let line2 = this.shipAddress1Target.value;
    if (this.shipAddress2Target.value.length > 0) { line2 += `, ${this.shipAddress2Target.value}` }
    const line3Data = [
      this.shipCityTarget.value,
      this.shipStateTarget.value.length > 0 ? `, ${this.shipStateTarget.value}` : null,
      ` ${this.shipZipCodeTarget.value}`,
      `, ${this.shipCountryData().isoCode}`
    ]
    this.inputsRadioOutlet.setDescriptions([line1, line2, line3Data.filter(n => n).join('')])
  }

  toggleFormElement() {
    this.collapsedFormElementTarget.classList.add(this.hiddenClass);
    this.expandedFormElementTarget.classList.remove(this.hiddenClass);
  }

  handleSharedAddressFieldChange() {
    if (this.billingSameAsShipping()) this.copyBillingInfoFromShipping();
  }

  setCheckoutType() {
    const hiddenCheckoutTypeField = document.createElement("input");
    hiddenCheckoutTypeField.type = 'hidden';
    hiddenCheckoutTypeField.name = 'checkout_type';
    hiddenCheckoutTypeField.value = 'standard';

    this.formTarget.appendChild(hiddenCheckoutTypeField);
  }
}
