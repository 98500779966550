const getToken = () => document.getElementsByName('csrf-token')[0].getAttribute('content');

const buildHeaders = () => ({
  'X-CSRF-Token': getToken(),
  'X-Requested-With': 'XMLHttpRequest',
});

const fetchGeneric = (path, method = 'POST', body = '') => (fetch(path, {
  method: 'POST',
  headers: buildHeaders(),
  credentials: 'same-origin',
  body,
}));

export default fetchGeneric;
