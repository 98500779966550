class ScrollHelper {
  constructor() {
    this.scrolling = false;
    this.currentScroll = 0;
    this.prevScroll = 0;
    this.direction = null;
    this.prevDirection = null;
    this.callback = null;
    this.init();
  }

  init() {
    window.addEventListener('scroll', () => {
      this.scrolling = true;
      this.checkScroll();
    }, { passive: true });
  }

  checkScroll() {
    setInterval(() => {
      if (this.scrolling) {
        this.scrolling = false;
        this.updateScrollData();
        if (this.callback) this.onScroll();
      }
    }, 300);
  }

  updateScrollData() {
    this.prevScroll = this.currentScroll;
    this.prevDirection = this.direction;
    this.currentScroll = window.scrollY;
    this.direction = this.currentScroll > this.prevScroll ? 'down' : 'up';
  }

  scrollData() {
    return {
      prevScroll: this.prevScroll,
      prevDirection: this.prevDirection,
      currentScroll: this.currentScroll,
      direction: this.direction,
    }
  }

  setCallback(callback) {
    this.callback = callback;
  }

  onScroll() {
    this.callback();
  }
}

export default ScrollHelper;
