import ApplicationController from '../../application_controller';
import MessageGenerator from './message_generator';

export default class extends ApplicationController {
  static classes = [ 'error', 'success' ];
  static outlets = [ 'checkout--checkout-2024' ];
  static targets = [ 'error', 'field' ];
  static values = { messageGeneratorFunctionName: String, inputName: String };

  connect() {
    this.messageGenerator = new MessageGenerator();
    this.fieldTarget.required = false;

    if (this.hidden() || !this.fieldTarget.value) return;

    this.updateValidationMessage();
    if (this.fieldTarget.validationMessage) return this.displayValidationMessage();

    this.element.classList.add(this.successClass);
  }

  parentFieldset() {
    const fieldset = this.element.closest('fieldset')
    if (fieldset) { return fieldset.name }
  }

  updateValidity() {
    this.handleEvent(this.updateDisplayedValidationMessage.bind(this));
  }

  handleKeyup(showSuccessCheckMark = true) {
    this.handleEvent(() => {
      this.validationMessageDisplayed() && this.updateDisplayedValidationMessage()
    }, showSuccessCheckMark);
  }

  handleEvent(validationMessagePresenceCallback, showSuccessCheckMark = true) {
    this.updateValidationMessage();
    if (this.fieldTarget.validationMessage) return validationMessagePresenceCallback();

    if (showSuccessCheckMark === true || this.validationMessageDisplayed()) this.clearDisplayedValidationMessage();
  }

  updateValidationMessage() {
    this.fieldTarget.setCustomValidity(this.validationMessage());
  }

  updateDisplayedValidationMessage() {
    this.clearDisplayedValidationMessage();
    this.fieldTarget.validationMessage && this.displayValidationMessage();
  }

  displayValidationMessage() {
    this.errorTarget.innerText = this.fieldTarget.validationMessage;
    this.element.classList.add(this.errorClass);
    this.element.classList.remove(this.successClass);
  }

  clearDisplayedValidationMessage() {
    this.errorTarget.innerText = '';
    this.element.classList.remove(this.errorClass);
    this.element.classList.add(this.successClass);
  }

  valid() {
    return this.fieldTarget.checkValidity();
  }

  hidden() {
    return !this.fieldTarget.offsetParent;
  }

  validationMessageDisplayed() {
    return this.element.classList.contains(this.errorClass) || this.element.classList.contains(this.successClass);
  }

  validationMessage() {
    if (this.element.classList.contains('hide')) return '';

    const restrictionMessage = this.messageGenerator
      .restrictedFormEntryMessage(this.fieldTarget.value, this.inputNameValue);
    if (restrictionMessage) return restrictionMessage;
    if (!this.hasMessageGeneratorFunctionNameValue) return '';

    return this.messageGenerator[this.messageGeneratorFunctionNameValue](
      this.fieldTarget.value,
      {
        name: this.inputNameValue,
        type: this.fieldTarget.type,
        ...(this.hasCheckoutCheckout2024Outlet && {
          billCountryName: this.checkoutCheckout2024Outlet.billCountryName(),
          billingSameAsShipping: this.checkoutCheckout2024Outlet.billingSameAsShipping(),
          shipCountryName: this.checkoutCheckout2024Outlet.shipCountryName(),
          shipCountryId: parseInt(this.checkoutCheckout2024Outlet.shipCountryId()),
          paymentOption: this.checkoutCheckout2024Outlet.paymentType(),
          ...this.checkoutCheckout2024Outlet.shipCountryData()
        }),
      },
    );
  }
}
