import RudderstackController from './rudderstack_controller'

export default class ProductClickedController extends RudderstackController {
  static values = {
    location: String,
  }

  initialize() {
    this.currentTarget = null;
  }

  connect() {
    this.containerData = this.element.dataset;
  }

  track(event) {
    this.currentTarget = event.currentTarget;

    RudderstackController.track('Product Clicked', this.properties());
  }

  properties() {
    return {
      ...this.defaultProperties(),
      ...this.productProperties(),
      ...this.gtmProperties(),
      ...this.searchProperties(),
    }
  }

  defaultProperties() {
    return {
      request_controller: window.TeePublic.requestController,
      request_action: window.TeePublic.requestAction,
      location: this.locationValue,
    }
  }

  productProperties() {
    const artistName = this.containerData.gtmDesignerName
      || this.containerData.artistName || this.currentTarget.dataset.artistName;

    return {
      artist_name: artistName,
      canvas_id: this.containerData.canvasId || this.currentTarget.dataset.canvasId,
      currency_code: this.containerData.currencyCode,
      design_id: this.containerData.designId || this.currentTarget.dataset.designId,
      designer_id: this.containerData.designerId || this.currentTarget.dataset.designerId,
      image_url: this.nestedImageUrl(),
      price: this.containerData.price,
      price_usd: this.containerData.priceUsd,
      product_id: this.containerData.productId || this.currentTarget.dataset.productId,
      product_image_type: this.currentTarget.dataset.imageType,
      url: this.containerData.url || this.targetUrl(),
      variant: this.containerData.variant,
    }
  }

  gtmProperties() {
    const designTitle = this.containerData.gtmDesignTitle
    || this.currentTarget.dataset.gtmDesignTitle;

    return {
      marketing_sku: this.containerData.gtmMarketingSku,
      name: designTitle,
      on_sale: this.containerData.gtmOnSale,
      position: this.containerData.gtmTilePosition,
      price: this.containerData.gtmPrice,
    }
  }

  searchProperties() {
    // CON-2440 BEGIN: Move search-related properties on product click events out of the fragment cache
    // because it varies for each individual search.
    return window.TeePublic.searchProductClick
    // CON-2440 END
  }

  targetUrl() {
    return this.currentTarget.href;
  }

  nestedImageUrl() {
    return this.currentTarget.querySelector('img')?.src;
  }
}
