class Loader {
  constructor(
    loaderEl
  ) {
    this.loaderEl = loaderEl;
  }

  toggle() {
    this.loaderEl.classList.remove('hidden');
    document.body.style.overflow = 'hidden';
  }
}

export default Loader;
