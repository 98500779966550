import { appendArtistSearchOption } from '../artist_search';

const ResultsList = (searchElement, resultSelectors) => ({
  render: true,
  className: resultSelectors.resultsList,
  idName: resultSelectors.resultsList,
  destination: () => searchElement,
  element: 'ul',
  position: 'afterend',
  noResults,
  maxResults: searchElement.dataset.maxresults,
});

const resultItemContent = ({ match, value: { type } = {} }, source) => {
  source.innerHTML = match;
  source.classList.add(type);
};

const resultItem = {
  content: resultItemContent,
  element: 'li',
  idName: 'autoComplete_result',
  highlight: {
    render: true,
    className: "autoComplete_highlighted",
  }
};

const noResults = function(list, query) {
  const sanitizedQuery = query.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  const newValue = `Show results for <strong class='query-term'>${sanitizedQuery}</strong>`;
  const noResultsElement = document.createElement('li');
  noResultsElement.setAttribute('class', 'autoComplete_result empty-results');
  noResultsElement.innerHTML = newValue;
  list.appendChild(noResultsElement);
  appendArtistSearchOption(list, query);
};

export {
  resultItem,
};

export default ResultsList;
