import Tips from './tips';
import Utilities from './utilities';

const Updaters = {};

/*
 * --------------------------------------------------
 *  Begin private methods
 */


function swapImages(gallery, imageSet) {
  gallery.swap_images({ images: imageSet });
}

function updateDescription(container, productId) {
  const { descriptions } = TeePublic.ProductOptions.CanvasOptions;
  const [description] = descriptions.find(value => value[1].includes(productId));
  document.querySelector(container).innerHTML = description;
}

function calculatePrices(product, products) {
  const {
    retail_price, sale_price, retail_price_formatted, sale_price_formatted,
  } = Utilities.productPrices(product, products);

  const taxRate = parseFloat(TeePublic.tax_rate || 1);
  const salePrice = parseFloat(sale_price).toFixed(2);
  const retailPrice = parseFloat(retail_price).toFixed(2);

  const priceDifference = retailPrice - salePrice;
  const salePercent = (priceDifference / retailPrice) * 100;

  // MULTIPLY TAX IF THERE ARE LOCALE SETTTINGS THAT REQUIRE TAX TO BE BAKED INTO THE PRICE
  const retailPriceFormatted = retail_price_formatted
    .replace(retailPrice, (retail_price * taxRate).toFixed(2));

  const salePriceFormatted = sale_price_formatted
    .replace(salePrice, (sale_price * taxRate).toFixed(2));

  return {
    priceDifference,
    salePercent,
    retailPriceFormatted,
    salePriceFormatted,
  };
}

function updatePrices(product, products) {
  const {
    priceDifference, salePercent, retailPriceFormatted, salePriceFormatted,
  } = calculatePrices(product, products);

  const regularPriceElement = document.querySelector('.jsProductRegularPrice');
  const regularPriceElementDiscounted = document.querySelector('.jsProductRegularPriceDiscounted');
  const salePriceElement = document.querySelector('.jsProductSalePrice');
  const savingsPriceElement = document.querySelector('.jsProductSalePriceSavings');
  const savingsCountdownElement = document.querySelector('.jsProductSaleSavings');

  if (regularPriceElement) regularPriceElement.innerHTML = retailPriceFormatted.replace('.00', '');
  if (regularPriceElementDiscounted) regularPriceElementDiscounted.innerHTML = `Reg ${retailPriceFormatted.replace('.00', '')}`;
  if (salePriceElement) salePriceElement.innerHTML = salePriceFormatted.replace('.00', '');

  if (priceDifference <= 0) return;

  if (savingsCountdownElement) savingsCountdownElement.innerHTML = ` ${Math.floor(salePercent)}% `;
  if (savingsPriceElement) savingsPriceElement.innerHTML = `${Math.floor(salePercent)}% OFF!`;
}

/*
  * --------------------------------------------------
  *  Begin public methods
  */

Updaters.updateColorText = (container, selector, colors) => {
  const currentColor = document.querySelector(`${container} .jsCartConfigColors input:checked`)?.value;
  const newColor = colors[currentColor];
  if (!newColor) return;

  document.querySelector(`${container} ${selector}`).innerHTML = newColor.display_name;
};

Updaters.updateGallery = (gallery, productId) => {
  const images = Utilities.getDesignImages();

  if (!images) {
    window.TeePublic.ProductImageSwapQueue = { productId, gallery };
    return;
  }

  window.TeePublic.ProductImageSwapQueue = null;

  const selectedStyle = document.querySelector("select[name='oli[new][style_id]']");
  const selectedStyleId = selectedStyle ? selectedStyle.value : null;
  let imageSet = images.find(image => image.product_ids.includes(productId));
  imageSet = imageSet ? imageSet.images : null;

  if (!imageSet) {
    console.error(`No images found for product id: ${productId}`);
    return;
  }

  // Filtering out the images where lifestyle is set so we do not show
  // model shots when the gallery is updated on t-shirts
  if (selectedStyleId === '79') {
    imageSet = imageSet.filter(image => image.type !== 'lifestyle');
  }

  swapImages(gallery, imageSet);
};

Updaters.updatePdpProductData = (gallery, productId) => {
  const { ProductOptions } = TeePublic;
  const { hierarchy } = ProductOptions.CanvasOptions;
  const { colors, tooltips } = TeePublic.ProductOptions.CanvasOptions;
  const sanitizedProducts = Utilities.sanitizeProducts(ProductOptions);

  const selections = Utilities.collectSelections('form.jsConfigOptions', ProductOptions, true);
  const product = productId
    ? Utilities.findProductById(sanitizedProducts, productId)
    : Utilities.findProductFromSelections(sanitizedProducts, selections, ProductOptions);

  Updaters.updateGallery(gallery, product.id);
  Updaters.updateColorText('.jsConfigOptions', '.jsConfigColorName', colors);
  updateDescription('.jsGarmentDescription2', product.id);
  updatePrices(product, sanitizedProducts);

  Tips.updateProductTip(product, tooltips);
  Tips.generateSeasonalColorTip(product, ProductOptions, sanitizedProducts, selections);

  TeePublic.ProductVendorHelper.init();
  const selectionsFullText = Utilities.collectFullTextSelections('form.jsConfigOptions', hierarchy, true);
  TeePublic.ProductVendorHelper.updateDataLayerProductView(selections, selectionsFullText);
}

export default Updaters;
