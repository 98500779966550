import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static values = { valid: Boolean };
  static targets = ['field', 'collapsedField', 'fieldset', 'collapsedFieldset'];
  static classes = ['hidden'];

  connect() {
    this.updateValues();
    this.expand();
  }

  edit(event) {
    event.preventDefault();
    this.expand();
  }

  updateValidity() {
    this.validValue = this.valid();
    this.updateValues();
  }

  updateValues() {
    switch (this.firstFieldName()) {
      case 'checkout[bill_email]': this.updateContactInfoValues(); break;
      case 'checkout[ship_firstname]': this.updateShippingAddressValues(); break;
      case 'checkout[shipping_method_id]': this.updateShippingMethodValues(); break;
      case 'checkout[user_address]': this.updateShippingAddressValues(); break;
    }
  }

  firstFieldName() {
    return this.fieldTarget.name
  }

  updateContactInfoValues() {
    this.collapsedFieldTargets[0].innerHTML = this.fieldTargetsFinder('checkout[bill_email]').value;
    this.collapsedFieldTargets[1].innerHTML = this.phoneFormat();
  }

  updateShippingAddressValues() {
    this.collapsedFieldTargets[0].innerHTML = this.fullName();
    this.collapsedFieldTargets[1].innerHTML = this.streetAddress();
    this.collapsedFieldTargets[2].innerHTML = this.region();
  }

  updateShippingMethodValues() {
    this.collapsedFieldTargets[0].innerHTML = `${this.shippingOptionName()} ${this.bulletPoint()} ${this.shippingMethodPrice()}`;
    if (this.businessDays() !== undefined) {
      this.collapsedFieldTargets[1].innerHTML = this.businessDays();
    }
    if (this.christmasGuarantee() !== undefined) {
      this.collapsedFieldTargets[2].innerHTML = this.christmasGuarantee();
    }
    this.giftOptionVisibility();
  }

  updateSavedAddressValues() {
    // Deliver to a different address selected and regular shipping address form filled out
    if (!this.fieldTarget.checked) return this.updateShippingAddressValues();

    const dataset = this.fieldTarget.dataset;

    this.collapsedFieldTargets[0].innerHTML = dataset.fullName;
    this.collapsedFieldTargets[1].innerHTML = dataset.fullAddress;
    this.collapsedFieldTargets[2].innerHTML = dataset.region;
  }

  giftOptionVisibility() {
    if (this.fieldTargetsFinder('checkout[contains_gift]').checked) {
      return this.collapsedFieldTargets[3].classList.remove(this.hiddenClass);
    } else {
      return this.collapsedFieldTargets[3].classList.add(this.hiddenClass);
    }
  }

  collapse() {
    this.updateValues()
    if (this.valid()) this.updateVisibility(this.fieldsetTarget, this.collapsedFieldsetTarget);
  }

  expand() {
    this.updateVisibility(this.collapsedFieldsetTarget, this.fieldsetTarget);
  }

  updateVisibility(targetToHide, targetToShow) {
    targetToHide.classList.add(this.hiddenClass)
    targetToShow.classList.remove(this.hiddenClass)
  }

  fieldTargetsFinder(elementName) {
    return this.fieldTargets.find(element => element.name === elementName);
  }

  shippingOptionName() {
    return this.selectedShippingMethod().querySelector('.c-radio-name').innerHTML;
  }

  shippingMethodPrice() {
    return this.selectedShippingMethod().querySelector('.c-radio-aside').innerHTML;
  }

  businessDays() {
    const label = this.selectedShippingMethod().querySelector('.c-radio-description p');

    if (label) return label.innerHTML;
  }

  christmasGuarantee() {
    const badgeLabel = this.selectedShippingMethod().querySelector('.tp-label')

    if (badgeLabel) return badgeLabel.innerHTML;
  }

  phoneFormat() {
    const rawPhoneNumber = this.fieldTargetsFinder('checkout[bill_phone]').value;
    const cleanedPhoneNumber = ('' + rawPhoneNumber).replace(/\D/g, '');
    const match = cleanedPhoneNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return null;
  }

  fullName() {
    const firstName = this.fieldTargetsFinder('checkout[ship_firstname]').value;
    const lastName = this.fieldTargetsFinder('checkout[ship_lastname]').value;

    return `${firstName} ${lastName}`;
  }

  streetAddress() {
    const street1 = this.fieldTargetsFinder('checkout[ship_address1]').value;
    const street2 = this.fieldTargetsFinder('checkout[ship_address2]').value;

    return `${street1} ${street2}`;
  }

  region() {
    const city = this.fieldTargetsFinder('checkout[ship_city]').value;
    const zip = this.fieldTargetsFinder('checkout[ship_zip]').value;
    const state = this.fieldTargetsFinder('checkout[ship_state_code]').value;

    return `${city}, ${state} ${zip} ${this.country()}`;
  }

  country() {
    const country = this.fieldTargetsFinder('checkout[ship_country_id]');

    return country.options[country.selectedIndex].text;
  }

  selectedShippingMethod() {
    return this.fieldTargets.find((radioButton) => radioButton.checked).nextElementSibling;
  }

  bulletPoint() {
    return "\u{2022}";
  }

  valid() {
    return this.fieldTargets.every((field) => field.checkValidity());
  }
}
