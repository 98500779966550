
const DataConfig = (searchElement) => ({
  src: dataSrc(searchElement),
  key: ['suggestion'],
  cache: false,
});

const dataSrc = (searchElement) => async () => {
  const searchUrl = searchElement.dataset.searchurl;
  const maxResults = searchElement.dataset.maxresults;

  const { value: query } = searchElement;
  // Return trending results ONLY if the query is empty string
  if (query.length === 0) {
    const trendingSearchResults = JSON.parse(searchElement.dataset.trendingsearchresults);
    // Map trending structure to autocomplete structure
    return trendingSearchResults.map(({ result, score }) => ({
      suggestion: result,
      weight: score,
      type: 'trending',
    }));
  }

  const escapedQuery = encodeURIComponent(query)
  const source = await fetch(`${searchUrl}?prefix=${escapedQuery}&limit=${maxResults}`, {
    headers: {
      'Accept': 'application/json',
    },
  });

  const data = await source.json();

  return data.suggestions;
};

export default DataConfig;
