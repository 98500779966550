import ApplicationController from './application_controller'

export default class StickyHeaderController extends ApplicationController {
  static outlets = ['containers--banner']
  static targets = ['searchRow']
  static values = {
    renderingRailsCtrl: String
  }

  NON_STICKY_RAILS_CTRLS = ['coupons', 'dashboard', 'designs', 'orders', 'partner_orders', 'reports', 'users']

  connect() {
    this.headerPosition = 0;
    this.init();
  }

  init() {
    this.setHeaderTop();
    this.setStickyClasses();
    this.initScrollHelper();
  }

  containersBannerOutletConnected() {
    this.init();
  }

  containersBannerOutletDisconnected() {
    this.init();
  }


  renderingRailsCtrlValueChanged() {
    this.init();
  }

  desktopView() {
    return window.innerWidth > 1024;
  }

  neverSticky() {
    return this.NON_STICKY_RAILS_CTRLS.includes(this.renderingRailsCtrlValue.toString()) || this.desktopView();
  }

  setHeaderTop() {
    this.headerPosition = this.setHeaderPosition();
    this.element.style.top = `${this.headerPosition}px`;
  }

  setHeaderPosition() {
    let headerPosition = 0;
    if (this.hasContainersBannerOutlet) {
      this.containersBannerOutletElements.forEach((banner) => {
        if (banner.classList.contains('vc-banner-sale-countdown') || banner.classList.contains('vc-banner-affiliate')) {
          headerPosition = banner.getBoundingClientRect().height;
        }
      });
    }
    return headerPosition;
  }

  setStickyClasses() {
    const stickyClass = this.neverSticky() ? 'no-sticky' : 'bottom-sticky'
    this.element.classList.add(`sticky-header--${stickyClass}`);
  }

  initScrollHelper() {
    const controllers = this.element.getAttribute('data-controller') || '';
    if (!this.neverSticky() && !controllers.includes('hide-on-scroll')) {
      this.element.setAttribute('data-controller', `${controllers} hide-on-scroll`.trim());
    }
    this.element.setAttribute('data-hide-on-scroll-element-position-value', this.headerPosition);
    this.element.setAttribute('data-hide-on-scroll-hiding-class-value', 'm-header--hide-top');
  }

  showHeader() {
    // placeholder for callback invoked by hide-on-scroll controller
    return null;
  }

  blurSearchField() {
    if (this.searchRowTarget.contains(document.activeElement)) {
      document.activeElement.blur();
    }
  }
}
