import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

const application = Application.start(document.documentElement)
const context = require.context('.', true, /_controller\.js$/)
application.load(definitionsFromContext(context))

const defaultErrorHandler = application.handleError.bind(application);
const bugsnagErrorHandler = (error, message, errorContext) => {
  defaultErrorHandler(error, message, context);
  const { bugsnagClient } = window;
  if (!bugsnagClient) { return; }

  bugsnagClient.notify(error, (event) => {
    event.addMetadata('message', message);
    event.addMetadata('context', errorContext);
  });
};

application.handleError = bugsnagErrorHandler;

export default application;
