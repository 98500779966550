import AutoComplete from '@tarekraafat/autocomplete.js';
import { EVENT_TYPES } from 'shared';
import AutocompleteConfig from './config';
import AutocompleteSelectors from './selectors';
// import Konami from './konami';
import toggleAnimatedPlaceholder from './animated_placeholder';
import { appendArtistSearchOption } from './artist_search';
import { keyboardNavigationHandler, mouseEventHandler, togglePlaceholder } from './navigation_helpers';

const allAutocompletes = AutocompleteSelectors.map(({ searchSelector, resultSelectors }) => {
  let autoCompleteHandler;

  try {
    // Only configure autocomplete for a search box if it is present on the current page.
    const searchInputElement = document.querySelector(`#${searchSelector}`);
    if(!searchInputElement) return;

    const defaultConfig = AutocompleteConfig(searchSelector, resultSelectors);

    const isAnimated = document.querySelector(defaultConfig.selector).dataset.animated;
    defaultConfig.placeHolder = isAnimated ? '' : 'Search all designs';

    const searchFormElement = searchInputElement.closest('form');
    const searchDataHiddenField = searchFormElement.querySelector('.jsAutoCompleteData');
    const clearSearchButton = searchFormElement.querySelector('.jsClearHeaderSearch');

    autoCompleteHandler = new AutoComplete(defaultConfig);

    // Add listener for Konami Code !!!
    // new Konami(searchInputElement).init();

    ['focus', 'blur', 'input'].forEach(function (eventType) {
      searchInputElement.addEventListener(eventType, function () {
        const resultsListElement = document.getElementById(autoCompleteHandler.resultsList.idName);

        togglePlaceholder(this.value);

        if (!resultsListElement) return;

        if (eventType === 'blur') {
          resultsListElement.style.display = 'none';
        } else {
          resultsListElement.style.display = 'block';
        }
      });
    });

    searchInputElement.addEventListener('open', function(event) {
      const resultsList = event.target.nextElementSibling;
      const dataFeedback = event.detail;
      const inputValue = dataFeedback.query;

      // Prepend 'trending' label if we have any trending results
      const trendingResultsElements = resultsList.getElementsByClassName('trending');
      const trendingLabelELements = resultsList.getElementsByClassName('autoComplete_label');

      if (trendingResultsElements.length > 0 && trendingLabelELements.length === 0) {
        const trendingLabelElement = document.createElement('li');
        trendingLabelElement.classList.add('autoComplete_label');
        resultsList.prepend(trendingLabelElement);
      }

      appendArtistSearchOption(resultsList, inputValue);
      mouseEventHandler(event, autoCompleteHandler);
    })

    searchInputElement.addEventListener('navigate', event => {
      const resultsList = event.target.nextElementSibling;
      const dataFeedback = event.detail;
      const inputValue = dataFeedback.query;

      // Append artist search option if shopper has navigated thruough the list before
      // the "open" event has triggered.
      appendArtistSearchOption(resultsList, inputValue);

      keyboardNavigationHandler(event, autoCompleteHandler);
      togglePlaceholder(event.target.value);
    })

    // Additional event listener to handle when user submit without interact with AutoComplete suggestions
    searchInputElement.addEventListener('keydown', event => {
      if (event.keyCode === 13) {
        // Prevent AutoComplete.js to throw an error on submit non-autocomplete term
        if (event.target.getAttribute('aria-activedescendant') === null) {
          event.stopImmediatePropagation();
        }

        if (event.target.value !== '') searchFormElement.submit();
      }
    })

    searchFormElement.addEventListener(EVENT_TYPES.SUBMIT, function (e) {
      e.preventDefault();
      toggleAnimatedPlaceholder('hide');

      let usedAutocomplete = searchInputElement.dataset.submitted === 'true',
        feedback,
        selection;
      if (searchInputElement.dataset.feedback !== undefined) {
        feedback = JSON.parse(searchInputElement.dataset.feedback);
        selection = feedback.selection;
      }

      // attaches search data to hidden form element
      searchDataHiddenField.value = JSON.stringify({usedAutocomplete, feedback, selection, searchInputId: searchInputElement.id, searchInputValue: searchInputElement.value});

      searchFormElement.submit();
    });

    // CLEAR SEARCH BUTTON
    clearSearchButton.addEventListener('click', function (e) {
      e.preventDefault();
      toggleAnimatedPlaceholder('show');
      searchInputElement.value = '';
      searchInputElement.focus();
    });

  } catch (e) { }

  return autoCompleteHandler;
});

export default allAutocompletes;
