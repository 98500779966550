class FollowButton {
  constructor(parentContainer, followPath) {
    this.button = parentContainer.querySelector('.jsFollowButton');
    this.csrfToken = document.getElementsByName('csrf-token')[0].getAttribute('content');
    this.followersCount = document.querySelector('.jsFollowersCount');
    this.followPath = followPath;
    this.toggle = this.button.dataset.toggle;
    this.id = this.button.dataset.id;
  }

  initButton() {
    this.button.addEventListener('click', (e) => {
      this.buttonClick(e, this.toggle, this.id);
    });
  }

  buttonClick(e, toggle, id) {
    fetch(this.followPath, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({ id, toggle }),
    })
      .then(res => res.json())
      .then((response) => {
        const { buttonText, newToggle, followersCount } = response;
        this.toggle = newToggle;
        this.button.dataset.toggle = this.toggle;
        this.button.innerHTML = buttonText;
        this.button.classList.toggle('btn--segmented');
        this.followersCount.innerHTML = `Followers: ${followersCount}`;
      })
  }
}

export default FollowButton;
