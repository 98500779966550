function currentTime() {
  const today = new Date();
  const utc = new Date(today.getTime() + today.getTimezoneOffset() * 60000);
  return utc.getTime() / 1000;
}

function padZeroes(int) {
  if (int < 10) {
    return `0${int}`;
  }
  return int;
}

function saleTimeRemaining(time, date) {
  const arr = [];
  let difference = Math.round(time - date);
  if (difference <= 0) return '';
  const days = Math.floor(difference / (60 * 60 * 24));
  if (days > 0) {
    arr.push(`for ${days} day`);
    arr.push(days > 1 ? 's ' : ' ');
    difference -= (days * 60 * 60 * 24);
  }
  const hours = Math.floor(difference / (60 * 60));
  if (hours > 0) {
    arr.push(`${padZeroes(hours)}:`);
    difference -= (hours * 60 * 60);
  }
  const minutes = Math.floor(difference / 60);
  if (minutes > 0) {
    arr.push(`${padZeroes(minutes)}:`);
    difference -= (minutes * 60);
  }
  const seconds = Math.floor(difference);
  if (seconds >= 0) {
    arr.push(padZeroes(seconds));
  }
  return arr.join('');
}

function updateSaleTimes() {
  const saleCountDowns = document.querySelectorAll('.sale-count-down span');

  function adjustTimes() {
    const now = currentTime();
    for (let i = 0; i < saleCountDowns.length; i += 1) {
      const saleEndTime = saleCountDowns[i].dataset.endTime;
      if (saleEndTime) {
        const saleEnd = new Date(saleEndTime).getTime() / 1000;
        const str = saleTimeRemaining(saleEnd, now);
        saleCountDowns[i].textContent = str;
      }
    }
  }

  if (saleCountDowns.length) {
    setInterval(() => adjustTimes(), 1000);
  }
}

function initUpdateSaleTimes() {
  document.addEventListener('DOMContentLoaded', updateSaleTimes);
}

export default initUpdateSaleTimes;
