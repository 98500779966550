import Glide from '@glidejs/glide'

const Sizer = {
  config: {
    sizerSliderClass: 'jsSizerSlider',
    thumbClass: 'jsSizerSliderCtrl',
    sizerConfigClass: 'jsSizerConfigs',
    sizerViewSliderClass: 'jsViewSizerSlider',
    sizerShowConfigClass: 'jsShowSizerConfigs',
  },

  slider: null,

  init() {
    const $sizerConfig = $(`.${this.config.sizerConfigClass}`);

    if (this.getSizerCookie($sizerConfig)) this.hideConfig($sizerConfig);
    this.initConfig();
  },

  initConfig() {
    const $sizerConfig = $(`.${this.config.sizerConfigClass}`);
    this.setConfigButtonStates($sizerConfig);
    this.updateWeightLabel();

    $sizerConfig.on('click', `.${this.config.sizerViewSliderClass}`, () => {
      this.hideConfig($sizerConfig);
      this.setSizerCookie($sizerConfig);
    });

    $sizerConfig.on('click', '.jsSizerConfigBtns .btn', (event) => {
      const $this = $(event.target);
      const config = $this.data('config');

      $this.addClass('on').siblings().removeClass('on');
      this.updateWeightLabel(config);

      if (!this.getSizerCookie($sizerConfig)) return;
      this.updateSizerCookie($sizerConfig, this.getButtonConfigData($this));
    });

    $sizerConfig.on('click', `.${this.config.sizerShowConfigClass}`, () => {
      this.showConfig($sizerConfig);
    });
  },

  destroySlider() {
    if (!this.slider) return;
    this.slider.destroy();
  },

  initSlider() {
    const startIndex = this.updateSlider() + 1 || 2;
    const glideConfig = {
      type: 'slider',
      autoplay: false,
      rewind: false,
      startAt: startIndex,
      peek: 100,
      swipeThreshold: 1,
      dragThreshold: 1,
      animationDuration: 200,
      animationTimingFunc: 'ease-in-out',
    }
    const gliderElement = document.querySelector(`.${this.config.sizerSliderClass}`);
    const glider = new Glide(gliderElement, glideConfig);
    this.slider = glider;
    glider.on('mount.after', this.updateArrowText.bind(this));
    glider.on('move.after', this.updateArrowText.bind(this));
    glider.mount();
  },

  getButtonConfig($btn) {
    return $btn.data('config').split('-');
  },

  getButtonConfigData($btn) {
    const data = {};
    const btnData = this.getButtonConfig($btn);
    data[btnData[0]] = btnData[1];
    return data;
  },

  getCanvas($config) {
    return $config.data('canvas');
  },

  getConfigBtns($config) {
    return $config.find('.jsSizerConfigBtns .btn');
  },

  getSizerSettings() {
    const $sizerConfig = $(`.${this.config.sizerConfigClass}`);
    const settings = this.getSizerCookie($sizerConfig);
    return settings ? JSON.parse(settings) : this.getSizerUiState($sizerConfig);
  },

  getSizerUiState($config) {
    const settings = {};
    const $btns = this.getConfigBtns($config);

    settings.canvas = this.getCanvas($config);
    $btns.each((i, btn) => {
      const $btn = $(btn);
      let configData;

      if ($btn.hasClass('on')) {
        configData = this.getButtonConfigData($btn);
        $.extend(true, settings, configData);
      }
    });
    return settings;
  },

  getSizerCookie($config) {
    return Cookies.get(`sizerConfig${this.getCanvas($config)}`);
  },

  hideConfig($config) {
    $config.removeClass('on');
    $(`.${this.config.sizerSliderClass}`).addClass('on');
    this.initSlider();
  },

  setConfigButtonStates($config) {
    const $btns = this.getConfigBtns($config);
    const settings = this.getSizerSettings();
    const btnsToMakeActive = [];

    if (!settings) return;

    Object.keys(settings).forEach((key) => {
      btnsToMakeActive.push(key + '-' + settings[key]);
    });

    btnsToMakeActive.forEach((v) => {
      const $btn = $btns.filter(`[data-config="${v}"]`);

      if (!$btn.length) return;
      $btn.addClass('on').siblings().removeClass('on');
    });
  },

  setSizerCookie($config, settings) {
    const newSettings = settings || this.getSizerUiState($config);
    Cookies.set(`sizerConfig${this.getCanvas($config)}`, JSON.stringify(newSettings));
  },

  showConfig($config) {
    document.querySelector(`.${this.config.sizerShowConfigClass}`).classList.remove('on');
    document.querySelector(`.${this.config.sizerSliderClass}`).classList.remove('on');
    $config.addClass('on');
  },

  updateArrowText() {
    const activeIndex = this.slider.index;
    const slideElements = document.querySelectorAll('.jsSizerSlider li');
    const prevSlide = slideElements[activeIndex - 1];
    const nextSlide = slideElements[activeIndex + 1];

    if (prevSlide) document.querySelector('.jsSizerPrevText').innerHTML = prevSlide.dataset.label;
    if (nextSlide) document.querySelector('.jsSizerNextText').innerHTML = nextSlide.dataset.label;
  },

  updateSizerCookie($config, data) {
    const settings = JSON.parse(this.getSizerCookie($config)) || {};
    const newSettings = $.extend(true, settings, data);
    this.setSizerCookie($config, newSettings);
  },

  updateSlider() {
    const settings = this.getSizerSettings();
    const models = TeePublic.ProductPage.Sizer.modelData;
    const currentModel = models.find((model) => {
      const heightsMatch = model.height === settings.height;
      const weightsMatch = model.weight === settings.weight;
      const genderMatch = model.gender === settings.gender;
      return heightsMatch && weightsMatch && genderMatch;
    });

    if (!currentModel) return;
    this.updateSliderImages(currentModel.images);
    this.updateSliderModelInfo(currentModel.model);
    return currentModel.default;
  },

  updateSliderImages(images) {
    this.destroySlider();
    const slideWrapElement = document.querySelector('.jsSizerWrap');
    const slideTrackElement = document.createElement('div');
    const slidesElement = document.createElement('ul');

    slideWrapElement.innerHTML = '';
    slideTrackElement.className = 'glide__track';
    slideTrackElement.dataset.glideEl = 'track';
    slidesElement.className = 'glide__slides';

    images.forEach((image) => {
      const trackTag = document.createElement('li');
      const imageTag = document.createElement('img');
      imageTag.src = image.url;
      trackTag.className = 'glide__slide';
      trackTag.dataset.label = image.size;
      trackTag.appendChild(imageTag);
      slidesElement.appendChild(trackTag);
    });

    slideTrackElement.appendChild(slidesElement);
    slideWrapElement.appendChild(slideTrackElement);
  },

  updateSliderModelInfo(model) {
    const modelNameElement = document.querySelector('.jsSizerModelName');
    const modelHeightElement = document.querySelector('.jsSizerModelHeight');
    const modelWeightElement = document.querySelector('.jsSizerModelWeight');

    modelNameElement.innerHTML = `${model.name}:`;
    modelHeightElement.innerHTML = `${model.height.feet}' ${model.height.inches} "`;
    modelWeightElement.innerHTML = `${model.weight.lbs} lbs`;
  },

  updateWeightLabel(config) {
    if (config && config.indexOf('gender-') < 0) return;
    const weightLabelElemenet = document.querySelector('.jsSizerBtnWeight');
    config = $('.jsSizerConfigGenders .on')
      .data('config').replace('gender-', '');
    weightLabelElemenet.innerHTML = (config === 'male' ? 'Heavy' : 'Curvy');
  },
}

export default Sizer;
