import ApplicationController from '../application_controller';

class Gifting extends ApplicationController {
  static targets = ['canvas', 'category', 'loader', 'tiles', 'cta']

  static values = {
    tilesUrl: String,
    category: String,
    canvas: String,
    canvasPlural: String,
  }

  connect() {
    this.loadCore();
  }

  changeCanvas() {
    this.showLoader();
    this.setCanvasPlural();
    this.updateCTAButton();
    this.loadTiles();
    // this.trackCanvasChange(event);
  }

  changeCategory(event) {
    event.preventDefault();
    const newCategoryValue = event.currentTarget.dataset.categoryValue;
    this.categoryValue = newCategoryValue;

    this.showLoader();
    this.updateCTAButton();
    this.setCurrentCategory(event);
    this.loadTiles();
    this.trackCategoryChange(event);
  }

  setCurrentCategory() {
    const activeClass = 'tp-gifting__category-link--active';
    const tag = this.categoryValue;

    this.categoryTargets.forEach((categoryTarget) => {
      if (categoryTarget.dataset.categoryValue === tag) {
        categoryTarget.classList.add(activeClass);
      } else {
        categoryTarget.classList.remove(activeClass);
      }
    });
  }

  setCanvasPlural() {
    const selectElement = this.canvasTarget;
    const selectedOption = selectElement.options[selectElement.selectedIndex]
    const newCanvasValue = selectedOption.value;
    const newCanvasPluralValue = selectedOption.dataset.plural;

    this.canvasValue = newCanvasValue;
    this.canvasPluralValue = newCanvasPluralValue;
  }

  loadCore() {
    const url = '/gifting-module/show';
    const urlWithParams = `${url}?canvas=${this.canvasValue}`;

    fetch(urlWithParams)
      .then(response => response.text())
      .then((data) => {
        this.element.innerHTML = data;
        this.loadTiles();
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  loadTiles() {
    this.showLoader();
    const url = this.tilesTarget.getAttribute('data-gifting--gifting-tiles-url-value');
    const tag = this.categoryValue;
    const canvas = this.canvasValue;
    const urlWithParams = `${url}?tag=${tag}&canvas=${canvas}`;

    fetch(urlWithParams)
      .then(response => response.text())
      .then((data) => {
        this.hideLoader();
        this.tilesTarget.innerHTML = data;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  hideLoader() {
    this.loaderTarget.classList.add('hidden');
  }

  showLoader() {
    this.tilesTarget.innerHTML = '';
    this.loaderTarget.classList.remove('hidden');
  }

  updateCTAButton() {
    const tag = this.categoryValue;
    const canvasPlural = this.canvasPluralValue;
    const canvasSlug = this.canvasValue;
    const cta = this.ctaTarget;

    // Remove the canvas and tag from the end of the URL
    let ctaUrl = cta.getAttribute('href').split('/');
    ctaUrl.pop();
    ctaUrl.pop();
    ctaUrl = ctaUrl.join('/')

    // Add the current canvas and tag to the end of the URL
    const newUrl = `${ctaUrl}/${canvasSlug}/${tag}`;
    cta.setAttribute('href', newUrl);

    // Update the CTA text
    cta.innerHTML = `View More ${canvasPlural}`;
  }

  trackCategoryChange(event) {
    if (window.rudderanalytics) {
      window.rudderanalytics.track(
        'Design Category Clicked',
        {
          category_label: event.currentTarget.dataset.titleValue,
          location: 'Gifting Module',
          category_tag: event.currentTarget.dataset.categoryValue
        }
      );
    }
  }
}

export default Gifting;
