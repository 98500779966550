
/*
 * Utility methods
 * These should all return values and none should directly modify the DOM
*/

const Utilities = {};

/*
 * --------------------------------------------------
 *  Begin private methods
*/

function attributeForType(attributes, attrType) {
  return attributes[attrType];
}

function attributeFromId(attrs, attrType, attrId) {
  return attributeForType(attrs, attrType)[attrId];
}

function cheapestProduct(products) {
  const { productData } = products;
  let lowestPriceProduct = productData[0];
  let lowestPrice = parseFloat(Utilities.productPrices(lowestPriceProduct, products).retail_price);

  productData.forEach((product) => {
    const price = parseFloat(Utilities.productPrices(product, products).retail_price);
    if (lowestPrice > price) {
      lowestPriceProduct = product;
      lowestPrice = price;
    }
  });

  return lowestPriceProduct;
}

function feedSkuToHash(sku) {
  const upperSku = sku.toUpperCase();
  const alphaParts = upperSku.match(/[A-Z]+/g) || [];
  const numericParts = upperSku.match(/\d+/g) || [];

  const skuHash = {};
  alphaParts.forEach((part, index) => {
    skuHash[part] = numericParts[index];
  });

  return skuHash;
}

function getFragmentProductId() {
  const fragments = window.location.hash.split('#').filter(n => n);
  const skuPattern = /(^|A-Z)\d+P/i;
  const feedSku = fragments.find(fragment => skuPattern.test(fragment));

  if (!feedSku) return null;

  const { P } = feedSkuToHash(feedSku);
  return Number(P);
}

function jsUcfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function matchesSoftSelections(currentProductAttrs, softSelected) {
  return softSelected.every((softSelectedRecord) => {
    const { id, type } = softSelectedRecord;
    return currentProductAttrs[type] === id;
  });
}

function optionsForSelector(products, currentSelector, softSelected) {
  var selectorOptions = {};
  const { slug } = currentSelector;
  const { productData, meta } = products;
  const { attrs } = meta;

  productData.forEach((product) => {
    const productAttrs = Utilities.productAttributes(product, products);
    const match = matchesSoftSelections(productAttrs, softSelected);
    const productAttrId = attributeForType(productAttrs, slug);
    let productAttr = attributeFromId(attrs, slug, productAttrId);

    // Handling for attributes that are non-unique
    if (!productAttr) {
      productAttr = attributeFromId(attrs, slug, `${productAttrId}_${product.id}`);
    }

    if (match) selectorOptions[productAttr.id] = productAttr;
  });

  return selectorOptions;
}

function productAttributeIDs(product) {
  return product[1];
}

function productMetaMappings(products) {
  const {
    prices_keys, prices, attrs, attrIdsOrder,
  } = products;

  return {
    prices_keys,
    prices,
    attrIdsOrder,
    attrs,
  }
}

function productsWithMetaMappings(products, meta) {
  return {
    productData: products,
    meta,
  }
}

function sortOptions(selectorOptions) {
  return Object.values(selectorOptions).sort((a, b) => a.sort - b.sort);
}

/*
  * --------------------------------------------------
  *  Begin public methods
  */

Utilities.collectFullTextSelections = (container, hierarchy, ignoreBlankFields) => {
  const formattedData = {};

  hierarchy.forEach((optionGroup) => {
    const style = optionGroup.product_page_input_style;
    const { slug } = optionGroup;
    let value;

    if (style === 'dropdown') {
      value = document.querySelector(`${container} .${slug} select option:checked`).textContent.trim();
    } else if (style === 'swatch') {
      const checkedInput = document.querySelector(`${container} .m-cart-config__color-wrap2 input:checked`);
      value = document.querySelector(`label[for='${checkedInput.id}']`).title;
    }

    if (!ignoreBlankFields || value) formattedData[jsUcfirst(slug)] = jsUcfirst(value);
  });

  return formattedData;
};

Utilities.collectSelections = (container, productOptions, ignoreBlankFields) => {
  const formattedData = {};

  const { hierarchy } = productOptions.CanvasOptions;

  $.each(hierarchy, (index, optionGroup) => {
    const style = optionGroup.product_page_input_style;
    const { slug } = optionGroup;
    let value;

    if (style === 'radio') {
      value = document.querySelector(`${container} .${slug}.jsCartConfigRadio input:checked`)?.value;
    } else if (style === 'swatch') {
      value = document.querySelector(`${container} .m-cart-config__color-wrap2 input:checked`)?.value;
    } else {
      value = document.querySelector(`${container} .${slug} select`)?.value;
    }

    if (!ignoreBlankFields || value) formattedData[slug] = value;
  });

  return formattedData;
};

Utilities.collectSelectorOptions = (products, hierarchy, preselected) => {
  const selectors = [];
  const softSelected = [];

  hierarchy.forEach((currentSelector) => {
    const selectorOptions = optionsForSelector(
      products,
      currentSelector,
      softSelected,
    );
    selectors.push(sortOptions(selectorOptions));

    const { slug } = currentSelector;
    const preselectedValue = preselected[slug];
    const matchingPreselectedOption = preselectedValue && selectorOptions[preselectedValue];
    const toAdd = matchingPreselectedOption || selectors[selectors.length - 1][0];

    toAdd.type = slug;
    softSelected.push(toAdd);
  });

  return selectors;
}

Utilities.findProductFromSelections = (products, selections, productOptions) => {
  const foundProducts = Utilities.findProductsFromSelections(products, selections, productOptions);
  return cheapestProduct(foundProducts);
}

Utilities.findProductById = (products, productId) => {
  // TODO: Products should always be an object becuase it's faster to lookup
  // And accepting two data types is code smell;
  const { productData } = products;
  if (Array.isArray(productData)) return productData.find(p => p.id === productId);
  return productData[productId.id];
}

Utilities.findProductsFromSelections = (products, selections, productOptions) => {
  const activeProductIds = productOptions.DesignOptions.active_product_ids
  const selectionKeys = Object.keys(selections).filter(key => !!selections[key]);

  const filteredProducts = products.productData?.filter((product) => {
    const attributes = Utilities.productAttributes(product, products);
    const matchesSelectOptions = selectionKeys.every((selectionKey) => {
      const attrId = attributes[selectionKey];
      const matchingAttributeId = Number(attrId) === Number(selections[selectionKey]);

      // For attributes with non-unique IDs, a check is also needed on the attribute's productId
      const attrProductId = attributes[`${selectionKey}ProductId`];
      const activeProductId = activeProductIds.includes(attrProductId);

      return attrProductId ? activeProductId && matchingAttributeId : matchingAttributeId;
    });

    return matchesSelectOptions;
  });

  return productsWithMetaMappings(filteredProducts, products.meta);
}

Utilities.getDesignImages = () => {
  const { images } = TeePublic.ProductOptions.DesignOptions;
  const { ProductImages } = TeePublic;
  return ProductImages || images;
}

Utilities.getFragmentIdProduct = (products) => {
  const fragmentId = getFragmentProductId();
  if (!fragmentId) return null;

  const product = Utilities.findProductById(products, fragmentId);
  if (!product) {
    console.error('CaughtError', `Could not find product with FragmentID ${fragmentId}`);
    return null;
  }

  return product;
}


Utilities.getOptionsFromId = (productId, products) => {
  if (!productId) return null;

  const options = {};
  try {
    const product = Utilities.findProductById(products, productId);
    const attributes = Utilities.productAttributes(product, products);
    Object.keys(attributes).forEach((attrKey) => {
      options[attrKey] = attributes[attrKey].toString();
    });
  } catch (e) {
    console.error('CaughtError', e.message);
    return null;
  }

  return options;
}

/*
  Marking this method for deletion – I think we could remove it and reduce complexity.
  Unclear why we would not render an option.
  Presumably because the options would have already updated when clicked.
  But updating this to always render fixed a bug on non PDP pages with color swatches not updating.
*/
Utilities.optionsToRender = (event, canvasOpts) => {
  const renderOptions = new Map();
  const canvases = canvasOpts || [];
  canvases.forEach((opt) => {
    if (event) {
      // renderOptions.set(opt.slug, event.currentTarget.id.includes(opt.slug));
      renderOptions.set(opt.slug, false);
    } else {
      renderOptions.set(opt.slug, false);
    }
  });
  return renderOptions;
};

Utilities.productAttributes = (product, products) => {
  const { attrIdsOrder } = products.meta;
  const populatedAttrs = attrIdsOrder.reduce((acc, attrType, index) => {
    const attrId = productAttributeIDs(product)[index];
    const isUniqueAttrId = String(attrId).indexOf('_') > 0;
    if (isUniqueAttrId) {
      const [id, productId] = attrId.split('_');
      acc[attrType] = Number(id);
      acc[`${attrType}ProductId`] = Number(productId);
    } else {
      acc[attrType] = productAttributeIDs(product)[index];
    }
    return acc;
  }, {});
  return populatedAttrs;
}

Utilities.productPrices = (product, products) => {
  const { meta } = products;
  const { prices, prices_keys } = meta || productMetaMappings(products);
  const priceKey = prices_keys[product[0]];
  return prices[priceKey];
}

Utilities.removeOosProducts = (products, oosProductIds) => {
  const filtered = products.productData?.filter(p => !oosProductIds.includes(p.id));
  return productsWithMetaMappings(filtered, products.meta);
}

Utilities.sanitizeProducts = (productOptions, cache) => {
  const cached = window.TeePublic?.ProductOptions?.sanitizedProducts;
  if (cached) return cached;

  const { products } = productOptions.CanvasOptions;
  const availableProductIds = productOptions.DesignOptions.active_product_ids;

  // TODO: Look ups would be more efficient if products were kept as an object
  const sanitizedProducts = availableProductIds.map((productId) => {
    const productObj = products[productId];
    productObj.id = productId;
    return productObj;
  });

  const mappedProducts = productsWithMetaMappings(sanitizedProducts, productMetaMappings(products));
  if (cache && window.TeePublic.ProductOptions) {
    window.TeePublic.ProductOptions.sanitizedProducts = mappedProducts;
  }

  return mappedProducts;
};

Utilities.validateSelections = (container, hierarchy, failSilently = false) => {
  const errors = [];

  $.each(hierarchy, (index, optionGroup) => {
    const style = optionGroup.product_page_input_style;
    const { slug, name } = optionGroup;
    if (style === 'radio') {
      const selectedElement = document.querySelector(`${container} .radio-selector__radios.${slug} label input:checked`);
      if (!selectedElement?.value) errors.push(name);
    } else if (style === 'swatch') {
      const selectedElement = document.querySelector(`${container} .m-cart-config__color-wrap2 input:checked`);
      if (!selectedElement?.value) errors.push(name);
    } else {
      const { value } = document.querySelector(`${container} .${slug} select`);
      if (!value || value === '') errors.push(name);
    }
  });

  if (errors.length === 0) return true;

  if (!failSilently) alert(`You must select a ${errors[0]}`);
  return false;
}

// New optimized validation that takes already computed selections as an argument
Utilities.validateAddToCart = (selections, hierarchy, failSilently = false) => {
  const errors = hierarchy.reduce((acc, optionGroup) => {
    const { slug, name } = optionGroup;
    const value = selections[slug];

    if (!value) acc.push(name);
    return acc;
  }, []);

  if (errors.length === 0) return true;

  if (!failSilently) alert(`You must select a ${errors[0]}`);
  return false;
}

export default Utilities;
