import Components from 'core/components';
import Search from 'shared/search';

const { dataLayer: DataLayer, TeePublic } = window;
const { Components: ExistingComponents, Search: ExistingSearch } = TeePublic;

TeePublic.Components = {
  ...ExistingComponents,
  ...Components,
};

TeePublic.Search = {
  ...ExistingSearch,
  ...Search,
};

export { DataLayer, TeePublic };
