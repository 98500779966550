import { EMAIL_VALIDATION_REGEX } from 'shared';

class EmailSubscribeForm {
  constructor(form) {
    this.form = form;
    this.emailField = this.form.querySelector('.jsSubscribeEmail');
    this.emailField.removeAttribute('pattern');
    this.errors = this.form.querySelector('.jsSubscribeErrors');
  }

  enhanceHtmlValidation() {
    this.emailField.setAttribute('pattern', EMAIL_VALIDATION_REGEX.source);
    this.setCustomInvalidityResponses();
  }

  setCustomInvalidityResponses() {
    this.emailField.addEventListener('input', (event) => {
      if (event.target.validity.typeMismatch) {
        this.emailField.setCustomValidity('');
      } else if (event.target.validity.patternMismatch) {
        this.emailField.setCustomValidity("Email must have a . and a top-level domain like '.com', '.edu', 'co.uk', etc.");
        this.emailField.reportValidity();
      } else {
        this.emailField.setCustomValidity('');
      }
    });
  }

  showError(show, error) {
    this.errors.innerHTML = `<p>${error}</p>`;

    if (show) this.showErrors();
  }

  showErrors() {
    this.errors.classList.add('on');
  }

  hideErrors() {
    this.errors.classList.remove('on');
  }
}

export default EmailSubscribeForm;
