import ApplicationController from '../application_controller'

export default class BannerController extends ApplicationController {
  static targets = ['closeIcon'];

  autoHide() {
    this.element.classList.add('vc-banner--auto-hide');
  }

  hide() {
    this.closeIconTarget.remove();
    this.element.classList.add('vc-banner--hide');
  }

  remove() {
    this.element.remove();
  }

  removeSaleCountdown() {
    this.updateCookie();
    this.remove();
  }

  show() {
    this.element.classList.remove('vc-banner--auto-hide');
    this.element.classList.remove('vc-banner--hide');
  }

  updateCookie() {
    const now = new Date(), expires = new Date();
    expires.setTime(now.getTime() + (24 * 60 * 60 * 1000));
    document.cookie = `sale_countdown_banner_closed_at=${now.toISOString()};expires=${expires.toUTCString()};path=/`;
  }
}
