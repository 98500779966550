import Utilities from './utilities';

const Tips = {};

/*
 * --------------------------------------------------
 *  Begin private methods
*/

function buildTooltipEl(tooltip) {
  const { header, tip, type } = tooltip;
  const tooltipIcon = TeePublic.icons[`${type}`] || '';
  return `
    <div class="m-design__tip">
      <div class="m-design__tip-icon">${tooltipIcon}</div>
      <div>
        <p class="m-design__tip-header">${header}</p>
        <p class="m-design__tip-text">${tip}</p>
      </div>
    </div>`;
}

function clearTips() {
  document.querySelectorAll('.jsProductTips').forEach((el) => {
    /* eslint-disable-next-line no-param-reassign */
    el.innerHTML = '';
  });
}

/*
  * --------------------------------------------------
  *  Begin public methods
  */

Tips.generateSeasonalColorTip = (product, productOptions, sanitizedProducts, selections) => {
  const { colors } = productOptions.CanvasOptions;
  const seasonalColor = Object.values(colors).find(color => color.seasonal);
  if (!seasonalColor) return;

  const { color, ...selectionsWithoutColor } = selections;
  const matchingProducts = Utilities.findProductsFromSelections(
    sanitizedProducts,
    selectionsWithoutColor,
    productOptions,
  )?.productData?.filter((p) => {
    const productColor = Utilities.productAttributes(p, sanitizedProducts)?.color;
    return productColor?.id === seasonalColor.id
  });

  if (!matchingProducts?.length) return;

  const { id, name, hex } = seasonalColor;
  const colorLinkHtml = `<a class="jsColorQuickSelect bold" href="javascript:void(0);" data-color_id="${id}">View Color</a>`;
  const tipData = {
    type: 'tee_outline',
    header: 'Limited Edition Color:',
    tip: `${name} is available while supplies last!  ${colorLinkHtml}`,
  }
  Tips.showTips([tipData], false);

  document.querySelector('.jsProductTips .m-design__tip:first .teepublicon svg').style.fill = hex;
  document.querySelector('.jsProductTips .m-design__tip:first').classList.add('seasonal_tip');
};

Tips.showTips = (tips, append = true) => {
  const tipsElement = document.querySelector('.jsProductTips');

  tips.forEach((tip) => {
    const tooltip = buildTooltipEl(tip);
    if (append) tipsElement.innerHTML += tooltip;
    else tipsElement.insertAdjacentHTML('afterbegin', tooltip);
  });

  // Sets the element display to the default value
  tipsElement.style.display = '';
}

Tips.updateProductTip = (product, tips) => {
  clearTips();

  const sortedTips = tips.sort((a, b) => {
    const x = a.type.toLowerCase();
    const y = b.type.toLowerCase();
    return x.localeCompare(y);
  });

  const productTips = sortedTips.filter(tip => tip.product_ids.includes(product.id));
  if (productTips.length > 0) Tips.showTips(productTips);
}

export default Tips;
