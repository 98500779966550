import QuantityStepper from './quantity_stepper';
import TrayController from './tray';
import Utilities from './utilities';

const Components = {
  TrayController,
  QuantityStepper,
  Utilities,
};

export default Components;
