import ApplicationController from '../application_controller';
import Fetchers from 'core/fetchers';

export default class extends ApplicationController {
  static classes = [ 'hidden', 'success', 'error' ];
  static outlets = [ 'checkout--checkout-2024', 'cart--order-summary', 'utilities--reveal' ];
  static targets = [ 'collapsed', 'expanded', 'couponCode', 'couponCodeWrapper', 'couponForm', 'success', 'successMessage', 'error', 'submitButton', 'label', 'labelText', 'labelIcon' ];
  static values = { appliedCanvases: String };

  connect() {
    this.toggleCqdMessage();
  }

  toggle() {
    const targetElements = [this.collapsedTarget, this.expandedTarget];
    const [elementToHide, elementToShow] =
      this.collapsed() ? targetElements : targetElements.reverse();
    elementToHide.classList.add(this.hiddenClass);
    elementToShow.classList.remove(this.hiddenClass);
  }

  collapsed() {
    return this.expandedTarget.classList.contains(this.hiddenClass);
  }

  submit() {
    const { value: couponCode } = this.couponCodeTarget;
    if (!couponCode) {
      this.errorTarget.innerText = 'You must enter a coupon code.';
      this.couponCodeWrapperTarget.classList.add(this.errorClass);
      this.submitButtonTarget.blur();
      return;
    }

    Fetchers.fetchJSON(`coupon_codes/validation/${couponCode}`, 'GET', null)
      .then((response) => response.json())
      .then((parsedResponse) => {
        if (parsedResponse.message === 'valid') {
          const { coupon_label_opts } = parsedResponse;
          const { value: couponCode } = this.couponCodeTarget;
          this.couponCodeWrapperTarget.classList.remove(this.errorClass);
          this.couponFormTarget.classList.add(this.hiddenClass);
          this.successMessageTarget.innerText = couponCode;
          this.updateCouponLabel(coupon_label_opts);
          this.successTarget.classList.remove(this.hiddenClass);
          if (this.hasCheckoutCheckout2024Outlet) this.updateSidebar();
          if (this.hasCartOrderSummaryOutlet) this.updateCartOrderSummary();
          this.updateAppliedCanvases(coupon_label_opts.coupon_discount_applied_canvases);
          if (this.hasUtilitiesRevealOutlet) this.toggleCqdMessage();
        } else {
          this.errorTarget.innerText = parsedResponse.message || parsedResponse.error[0];
          this.couponCodeWrapperTarget.classList.add(this.errorClass);
          this.submitButtonTarget.blur();
        }
      })
      .catch((err) => console.log(err));
  }

  clearCoupon() {
    Fetchers.fetchJSON('coupon_codes/deactivation', 'GET', null)
      .then((response) => {
        if (response.status < 400) {
          this.successTarget.classList.add(this.hiddenClass);
          this.couponFormTarget.classList.remove(this.hiddenClass);
          if (this.hasCheckoutCheckout2024Outlet) this.updateSidebar();
          if (this.hasCartOrderSummaryOutlet) this.updateCartOrderSummary();
          this.couponCodeTarget.value = '';
          this.toggle();
          this.utilitiesRevealOutlets.forEach(outlet => outlet.show());
        } else {
          console.log('Couldn\'t remove coupon.');
        }
      })
  }

  updateAppliedCanvases(couponDiscountAppliedCanvases) {
    if (!couponDiscountAppliedCanvases) return;

    this.appliedCanvasesValue = couponDiscountAppliedCanvases.join(', ');
  }

  updateSidebar() {
    this.checkoutCheckout2024Outlet.openLoadingOverlay();
    this.checkoutCheckout2024Outlet.fetchData(
      this.checkoutCheckout2024Outlet.urlValue,
      (data) => {
        this.checkoutCheckout2024Outlet.updateSidebar(data);
        this.checkoutCheckout2024Outlet.closeLoadingOverlay();
      },
      ['sidebar'],
      'PUT',
    );
  }

  updateCartOrderSummary() {
    this.cartOrderSummaryOutlet.updateOrderSummaryComponent();
  }

  updateCouponLabel(couponLabelOpts) {
    const { class_name: className, icon, text } = couponLabelOpts;

    this.labelTarget.classList = `m-order-summary__coupon__pill ${className}`
    this.labelTextTarget.innerText = text;
    this.labelIconTarget.querySelector('img').src = icon;
  }

  toggleCqdMessage() {
    if (this.appliedCanvasesValue.length === 0) return;

    this.utilitiesRevealOutlets.forEach((outlet) => {
      if (this.appliedCanvasesValue.split(', ').includes(outlet.element.dataset.canvas)) {
        outlet.hide();
      } else {
        outlet.show();
      }
    });
  }
}
