class Tray {
  constructor({
    slidefrom,
    event,
    content,
    tray = document.querySelector('.jsTray'),
    underlay = tray.querySelector('.jsTrayUnderlay'),
    container = tray.querySelector('.jsTrayContent'),
  } = {}) {
    this.slidefrom = slidefrom
    this.tray = tray
    this.underlay = underlay
    this.event = event
    this.content = content
    this.container = container
    this.scrollPosition = 0

    this.deactivate = this.deactivate.bind(this)
  }

  mount() {
    this.underlay.addEventListener(this.event, this.deactivate, false);
  }

  unmount() {
    this.underlay.removeEventListener(this.event, this.deactivate, true);
  }

  renderContent() {
    this.container.querySelector(`${this.content}`).classList.add('render')
    this.tray.classList.add('active')
  }

  removeContent() {
    this.tray.classList.remove(`${this.slidefrom}`)
    this.container.querySelector(`${this.content}`).classList.remove('render')
  }

  activate() {
    this.tray.classList.add(`${this.slidefrom}`);
    this.mount();

    document.body.classList.add('no-scroll');

    setTimeout(() => this.renderContent(), 150)
  }

  deactivate(e) {
    e.preventDefault();
    this.unmount();

    this.tray.classList.remove('active');
    document.body.classList.remove('no-scroll');

    setTimeout(() => this.removeContent(), 150);
  }
}

export default Tray;
